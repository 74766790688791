/**
 * Styles extracted from: packages/remirror__theme/src/extension-tables-theme.ts
 */
.report-editor,
.text-editor,
.text-analysis-viewer-editor,
.analyze-editor,
.project-overview-editor {
  .remirror-editor.ProseMirror {
    /* Give selected cells a blue overlay */
    /* We don't need this anymore -- 2021-04-03 ocavue */
  }

  .remirror-editor.ProseMirror .tableWrapper {
    overflow-x: auto;
  }

  .remirror-editor.ProseMirror table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    overflow: hidden;
  }

  .remirror-editor.ProseMirror td,
  .remirror-editor.ProseMirror th {
    vertical-align: top;
    box-sizing: border-box;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: var(--rmr-color-table-default-border);
  }

  .remirror-editor.ProseMirror .column-resize-handle {
    position: absolute;
    right: -2px;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: 40;
    background-color: var(--rmr-hue-blue-7);
    pointer-events: none;
  }

  .remirror-editor.ProseMirror.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }

  /*
    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }
    */
  .remirror-editor.ProseMirror th.selectedCell,
  .remirror-editor.ProseMirror td.selectedCell {
    border-style: double;
    border-color: var(--rmr-color-table-selected-border);
    background-color: var(--rmr-color-table-selected-cell);
  }

  .remirror-table-colgroup > col:first-of-type {
    width: 13px;
    overflow: visible;
  }

  .remirror-controllers-toggle {
    visibility: hidden;
  }

  .remirror-table-show-controllers {
    overflow-x: auto;
    .remirror-controllers-toggle {
      visibility: visible;
    }
  }

  .remirror-table-insert-button {
    display: none; //disable insertion of row/col in v1 table
    position: absolute;
    width: 18px;
    height: 18px;
    z-index: 25;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 150ms ease;

    background-color: #dcdcdc;
  }

  .remirror-table-insert-button svg {
    fill: #ffffff;
  }

  .remirror-table-insert-button:hover {
    background-color: #136bda;
  }

  .remirror-table-insert-button:hover svg {
    fill: #ffffff;
  }

  .remirror-table-delete-inner-button {
    border: none;
    padding: 0;
    width: 25px;
    height: 25px;

    position: absolute;
    z-index: 30;
    cursor: pointer;
    border-radius: 4px;
    background-color: #cecece;
    transition: background-color 150ms ease;
  }

  .remirror-table-delete-inner-button:hover {
    background-color: #ff7884;
  }

  .remirror-table-delete-table-inner-button {
    top: calc(var(--remirror-table-delete-button-y) - 9px);
    left: calc(var(--remirror-table-delete-button-x) - 9px);
  }

  .remirror-table-delete-row-column-inner-button {
    top: calc(var(--remirror-table-delete-row-column-button-y) - 9px);
    left: calc(var(--remirror-table-delete-row-column-button-x) - 9px);
  }

  .remirror-table-with-controllers {
    /* Space for marks */
    margin-top: 40px;
    margin-bottom: 40px;

    /* To make controller's 'height: 100%' works, table must set its own height. */
    height: 1px;
  }

  /* To show marks */

  .ProseMirror table.remirror-table-with-controllers {
    overflow: visible;
  }

  .remirror-table-waitting-controllers {
    /* Hide the table before controllers injected */
    display: none;
  }

  /* First row contains one corner controller and multiple column controllers */

  .remirror-table-tbody-with-controllers > tr:nth-of-type(1) {
    height: 12px;
    overflow: visible;
  }

  /* First controller cell is the corner controller */

  .remirror-table-tbody-with-controllers > tr:nth-of-type(1) th:nth-of-type(1) {
    overflow: visible;
    padding: 0;
    cursor: pointer;
    z-index: 0;
    position: relative;
    height: 12px;
    width: 12px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(1)
    div.remirror-table-controller-wrapper {
    overflow: visible;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 12px;
    height: 12px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(1)
    div.remirror-table-controller-trigger-area {
    flex: 1;
    position: relative;
    z-index: 10;
    /* Style for debug. Use linear-gradient as background so that we can differentiate two neighbor areas. */
    /* background: linear-gradient(to left top, rgba(0, 255, 100, 0.2), rgba(200, 100, 255, 0.2)); */
    display: none;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(1)
    div.remirror-table-controller-mark-row-corner {
    bottom: -2px;
    left: -12px;
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    border-style: solid;
    border-color: var(--rmr-color-table-mark);
    border-width: 2px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(1)
    div.remirror-table-controller-mark-column-corner {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    border-style: solid;
    border-color: var(--rmr-color-table-mark);
    border-width: 2px;
    right: -2px;
    top: -12px;
  }

  /* Second and more cells are column controllers */

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(n + 2) {
    overflow: visible;
    padding: 0;
    cursor: pointer;
    z-index: 0;
    position: relative;
    height: 12px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(n + 2)
    div.remirror-table-controller-wrapper {
    overflow: visible;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 12px;
    flex-direction: row;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(n + 2)
    div.remirror-table-controller-trigger-area {
    flex: 1;
    position: relative;
    z-index: 10;
    /* Style for debug. Use linear-gradient as background so that we can differentiate two neighbor areas. */
    /* background: linear-gradient(to left top, rgba(0, 255, 100, 0.2), rgba(200, 100, 255, 0.2)); */
    height: 36px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(n + 2)
    div.remirror-table-controller-mark-row-corner {
    display: none;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(1)
    th:nth-of-type(n + 2)
    div.remirror-table-controller-mark-column-corner {
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    border-style: solid;
    border-color: var(--rmr-color-table-mark);
    border-width: 2px;
    right: -2px;
    top: -12px;
  }

  /* Second and more rows containes row controllers */

  /* First controller cell in each row is a row controller */

  .remirror-table-tbody-with-controllers > tr:nth-of-type(n + 2) th {
    overflow: visible;
    padding: 0;
    cursor: pointer;
    z-index: 0;
    position: relative;
    width: 12px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(n + 2)
    th
    div.remirror-table-controller-wrapper {
    overflow: visible;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    width: 12px;
    flex-direction: column;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(n + 2)
    th
    div.remirror-table-controller-trigger-area {
    flex: 1;
    position: relative;
    z-index: 10;
    /* Style for debug. Use linear-gradient as background so that we can differentiate two neighbor areas. */
    /* background: linear-gradient(to left top, rgba(0, 255, 100, 0.2), rgba(200, 100, 255, 0.2)); */
    width: 36px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(n + 2)
    th
    div.remirror-table-controller-mark-row-corner {
    bottom: -2px;
    left: -12px;
    position: absolute;
    width: 0px;
    height: 0px;
    border-radius: 50%;
    border-style: solid;
    border-color: var(--rmr-color-table-mark);
    border-width: 2px;
  }

  .remirror-table-tbody-with-controllers
    > tr:nth-of-type(n + 2)
    th
    div.remirror-table-controller-mark-column-corner {
    display: none;
  }

  /* Styles for default */

  .remirror-table-tbody-with-controllers th.remirror-table-controller {
    background-color: var(--rmr-color-table-default-controller);
  }

  /* Styles for selected */

  .remirror-table-tbody-with-controllers
    th.selectedCell.remirror-table-controller {
    background-color: var(--rmr-color-table-selected-controller);
  }

  .remirror-table-preselect-all {
  }

  /* Styles for predelete */

  .remirror-table-show-predelete th.selectedCell.remirror-table-controller,
  .remirror-table-show-predelete td.selectedCell {
    border-color: var(--rmr-color-table-predelete-border) !important;
    background-color: var(--rmr-color-table-predelete-cell) !important;
  }

  .remirror-table-show-predelete th.selectedCell.remirror-table-controller {
    background-color: var(--rmr-color-table-predelete-controller) !important;
  }

  .remirror-table-show-predelete.remirror-table-preselect-all
    th.remirror-table-controller,
  .remirror-table-show-predelete.remirror-table-preselect-all td {
    border-color: var(--rmr-color-table-predelete-border) !important;
    background-color: var(--rmr-color-table-predelete-cell) !important;
  }

  .remirror-table-show-predelete.remirror-table-preselect-all
    th.remirror-table-controller {
    background-color: var(--rmr-color-table-predelete-controller) !important;
  }
}
