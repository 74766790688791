.quick-notes-editor {
  height: 100%;
  font-size: var(--fontSize-14);

  &.remirror-theme h1,
  &.remirror-theme h2,
  &.remirror-theme h3,
  &.remirror-theme h4,
  &.remirror-theme h5,
  &.remirror-theme h6 {
    margin: 0px;
  }

  &.remirror-theme {
    h1,
    h1 * {
      line-height: 38px;
      margin-bottom: 8px;
    }

    h2,
    h2 * {
      line-height: 24px;
    }

    h3,
    h3 * {
      line-height: 20px;
    }

    h4,
    h4 * {
      line-height: 18px;
    }
  }

  &.remirror-theme .ProseMirror p,
  &.remirror-theme .ProseMirror ul,
  &.remirror-theme .ProseMirror ol,
  &.remirror-theme .ProseMirror span {
    margin: 0;
  }

  h1 {
    font-size: var(--fontSize-20);
    font-weight: 700;
  }

  h2 {
    font-size: var(--fontSize-18);
    font-weight: 700;
  }

  h3 {
    font-size: var(--fontSize-16);
    font-weight: 700;
  }

  h4 {
    font-size: var(--fontSize-15);
    font-weight: 700;
  }

  h5 {
    font-size: var(--fontSize-13);
    font-weight: 700;
  }

  h6 {
    font-size: var(--fontSize-13);
    font-weight: var(--fontBold);
  }

  .quick-note__heading-placeholder h3,
  .remirror-is-empty {
    user-select: none;
    color: var(--gray-300);
    font-weight: var(--fontBold);
    font-size: var(--fontSize-20);
    line-height: 38px !important;
  }
}
