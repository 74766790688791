.notify-share-modal-cls .modal-header {
  padding: 0px;
}

.notify-share-modal-cls .modal-content > .modal-body {
  padding: 0px;
}

.notify-share-modal-cls p {
  font-weight: var(--fontSemiBold);
  margin-bottom: 15px;
}

.notify-share-modal-cls .modal-header {
  position: absolute;
  right: 18px;
  justify-content: center;
  top: 15px;
}

.notify-share-modal-cls .modal-content {
  padding: 40px;
}

.notify-share-modal-cls .modal-footer {
  justify-content: space-between;
  border: none;
  padding: 0;
  padding-top: 20px;
}
