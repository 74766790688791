/* Styles for search skeleton components. */

/* Files */
.file__skeleton__container {
  box-sizing: border-box;
  margin: auto;
  padding: 16px 28px;
}

/* Notes */
.note__skeleton__container {
  margin-bottom: 16px;
}

.note__skeleton__body {
  width: 100%;
  margin-top: 8px;
}

/* Transcripts */
.transcript__skeleton__container {
  display: flex;
  padding: 20px;
}

.transcript__skeleton__body {
  display: flex;
  flex-direction: column;
  width: calc(100% - 304px);
  margin-right: 24px;
}

.search__video-player.shimmer {
  background-color: var(--white-100);
}
