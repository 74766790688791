/** ===== Labels ===== */
.tag-labels-container__with-margin {
  margin-bottom: 8px;
}

.tag-labels-container:empty {
  margin-bottom: 0px;
}

.tag-form__form-labels-container,
.tag-labels-container {
  flex-wrap: wrap;
}

.tag-labels-container-for-events {
  margin-bottom: 8px;
  margin-left: 1.75rem;
}

.tag-labels-container-for-clippings-events {
  margin-bottom: 8px;
}

.tag-form__new-label-item-button {
  cursor: pointer;
  padding: 0px 4px;
  margin-bottom: 8px;
  border: solid 1px var(--white-100);
  border-radius: 4px;
  background-color: var(--white-100);
  height: 22px;
  line-height: 22px;
}

.tag-form__new-label-item-button:hover {
  background-color: #fafafa;
  border: solid 1px var(--gray-100) !important;
}

.tag-form__new-question-item-button {
  display: inline-block;
  cursor: pointer;
  padding: 0px 10px 0 8px;
  margin-bottom: 4px;
  border: solid 1px var(--white-100);
  border-radius: 4px;
  background-color: var(--white-100);
  height: 28px;
  line-height: 28px;
}

.tag-form__new-question-item-button:hover {
  background-color: #fafafa;
  border: solid 1px var(--gray-100);
}

.tag-form__new-label-item-description {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-13);
  color: var(--gray-400);
  margin-left: 6.8px;
}

.tag-form__new-question-item-description {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-13);
  color: var(--gray-400);
  margin-left: 5.4px;
}

.tag-form__labels-popup.floating-form,
.tag-form__labels-popup.normal-form {
  position: absolute;
  max-width: 600px;
  border-radius: 4px;
  max-height: 300px;
  overflow-y: auto;
  padding: 12px;
  padding-bottom: 2px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.12),
    -2px -2px 4px 0 rgba(0, 0, 0, 0.12);
  background: var(--white-100);
  z-index: 10;
}

.tag-form__labels-popup.floating-form {
  width: calc(100% - 40px);
}

.tag-form__labels-popup.normal-form {
  width: calc(100% - 50px);
  max-width: 560px;
}

.tag-form__labels-list-items {
  display: flex;
  flex-wrap: wrap;
}

.tag-form__labels-list-item {
  display: inline-block;
  max-width: calc(100% - 12px);
  height: 30px;
  border: 1px solid var(--gray-100);
  border-radius: 4px;
  padding: 2px 8px 6px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
}

.tag-form__list-item-text {
  font-size: 12px;
  color: var(--gray-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-form__labels-list-item:hover {
  background-color: #f1f1f1;
}

.tag-form__labels-list-item:hover > .tag-form__list-item-text {
  direction: rtl;
}

.analyze-label-disabled {
  cursor: not-allowed;
}

.tag-form__filtered-labels {
  padding: 2px;
  display: flex;
  flex-wrap: wrap;
  max-height: 126px;
  overflow-y: auto;
}

.tag-form__new-label-name,
.tag-form__make-new-text,
.tag-form__search-labels-input,
.tag-form__deactivated-label-text {
  font-size: 12px;
}

.tag-form__search-labels-input::-webkit-input-placeholder {
  font-size: 12px;
}

.tag-form__search-labels-input::-moz-placeholder {
  font-size: 12px;
}

.tag-form__search-labels-input:-ms-input-placeholder {
  font-size: 12px;
}

.tag-form__search-labels-input::placeholder {
  font-size: 12px;
}

.tag-form__search-labels-close {
  filter: invert(38%) sepia(0%) saturate(1452%) hue-rotate(224deg)
    brightness(98%) contrast(80%);
}

.tag-form__make-new-text {
  color: #fb3569;
}

.label__tag-label-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  height: 22px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  background-color: var(--gray-50);
  border: 1px solid var(--gray-100);
}

.label__tag-label-text {
  padding: 0 6px;
}

.label__tag-label-text em {
  color: var(--pink-500);
  font-style: normal;
  font-weight: var(--fontSemiBold);
  background-color: var(--yellow-highlight);
  font-size: 11px;
}

.label__tag-label-text span {
  color: var(--gray-500);
}

.label__tag-label-text,
.label__tag-label-text span,
.label__tag-label-text mark {
  font-size: var(--fontSize-11);
  font-weight: var(--fontSemiBold);
  line-height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label__tag-label-delete {
  position: absolute;
  background-color: var(--gray-300);
  border-radius: 99999px;
  cursor: pointer;
  height: 12px;
  width: 12px;
  top: -6px;
  right: -6px;
}

.delete-label-icon {
  height: 6px;
  filter: invert(99%) sepia(0%) saturate(1227%) hue-rotate(275deg)
    brightness(100%) contrast(100%);
}

.label-separator {
  margin: 0 6px;
}

.close-popup-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  left: calc(100% - 44px);
  border-radius: 4px;
  height: 24px;
  width: 24px;
}

.close-popup-button:hover {
  opacity: 1 !important;
}

.close-popup-icon {
  max-height: 10px;
}

.deactivated-label {
  background-color: var(--gray-100);
  cursor: no-drop;
}

.tag-form__deactivated-label-text {
  color: var(--gray-300);
}

.search-label-match {
  font-size: 12px;
  color: #fb3569;
}

.label-tooltip-heading {
  margin: 0px;
  font-size: 12px;
  font-weight: var(--fontSemiBold);
  color: var(--white-100);
  line-height: 20px;
  text-align: left;
}

.label-tooltip-description {
  margin: 0px;
  font-size: 12px;
  color: var(--white-100);
  line-height: 20px;
  text-align: left;
}

.label-tooltip {
  padding: calc(12px - 0.25rem) calc(12px - 0.5rem);
}

.close-popup-button:focus-visible,
.label__tag-label-delete:focus-visible,
.tag-form__new-label-item-button:focus-visible,
.tag-form__new-question-item-button:focus-visible,
.tag-form__labels-list-item:focus-visible {
  outline: 2px solid var(--pink-300);
}
