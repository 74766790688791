.notepad-container {
  background-color: #ecf0fb;
  height: 100%;
  overflow: hidden;
}

.spinner-border {
  width: 20px;
  height: 20px;
  margin-left: -2px;
  border-color: #fb3569;
  border-right-color: transparent;
}

.mentioned-user {
  color: var(--blue-800);
  font-weight: var(--fontNormal);
  font-size: var(--fontSize-14);
  line-height: 16px;
  height: 22px;
  background-color: var(--gray-100);
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px;
}

.self-mentioned-user {
  background-color: var(--yellow-300);
  color: var(--pink-500);
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-13);
  line-height: 16px;
  height: 22px;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  padding: 2px;
}

.bot-error-modal {
  max-width: 652px;
  width: 652px;
}

.bot-error-modal-body {
  padding-top: 0;
}

.mentioned-user-chat {
  color: var(--gray-600);
  font-size: var(--fontSize-13);
  font-weight: var(--fontBold);
  line-height: 22px;
}

.highlight-mention-user-chat {
  color: var(--gray-600);
  font-size: var(--fontSize-13);
  font-weight: var(--fontBold);
  line-height: 22px;
  background-color: var(--yellow-300);
  padding: 2px;
  border-radius: 4px;;
}

.current-user-author-chat {
  color: var(--white-100);
  font-size: var(--fontSize-13);
  font-weight: var(--fontBold);
  line-height: 22px;
}

.linkInChat {
  color: inherit;
  cursor: pointer;
}

.linkInChat:hover {
  color: inherit;
  text-decoration: underline;
  cursor: pointer;
}

@keyframes slideInBlurredBottom {
  0% {
    transform: translateY(1000px) scaleY(2.5) scaleX(0.2);
    transform-origin: 50% 100%;
    filter: blur(40px);
    opacity: 0;
  }
  100% {
    transform: translateY(0) scaleY(1) scaleX(1);
    transform-origin: 50% 50%;
    filter: blur(0);
    opacity: 1;
  }
}

.animate-mentioned-user-chat {
  animation: slideInBlurredBottom 0.5s cubic-bezier(0.23, 1, 0.32, 1) both;
}

.remirror-is-empty:first-of-type:before {
  position: absolute;
  color: var(--gray-300);
  pointer-events: none;
  height: 0;
  content: attr(data-placeholder);
  font-style: normal;
}

.remirror-editor-live-notes {
  position: relative;
  min-height: 5rem;
  height: auto;
}

.remirror-editor-live-notes p,
.remirror-editor-live-notes ul,
.remirror-editor-live-notes ol {
  margin-bottom: 0;
}

.ProseMirror.remirror-editor {
  white-space-collapse: preserve;
}

.remirror-placeholder {
  position: absolute;
  top: 8px;
  left: 8px;
  color: var(--gray-300);
  pointer-events: none;
  user-select: none;
  z-index: 1;
}

.live-note-card:only-child {
  border-radius: 0.5rem;
}

.live-note-card:only-child:hover {
  border-radius: 0.5rem;
}

.live-note-card:first-child {
  padding-top: 16px;
}

.live-note-card:first-child:hover {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.live-note-card:last-child {
  padding-bottom: 16px;
}

.live-note-card:last-child:hover {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
