.report-editor,
.text-analysis-viewer-editor,
.analyze-editor,
.project-overview-editor {
  &.remirror-theme .ProseMirror {
    background-color: var(--white-100);
    border: 0;
    box-shadow: 0px 3px 12px rgba(2, 0, 93, 0.1);
    width: 100%;
    max-width: 1120px;
    min-height: 1024px;
    margin: 0 auto;
    padding: 76px 140px 100px 140px;
    border-radius: 0 0 4px 4px;
    overflow: visible !important;
    &:active,
    &:focus {
      box-shadow: 0px 3px 12px rgba(2, 0, 93, 0.1);
    }

    @media screen and (max-width: 1370px) {
      padding-right: 80px;
      padding-left: 80px;
    }
  }

  &.remirror-theme .remirror-editor-wrapper {
    padding-top: 0px;
    margin-bottom: 20px;
    min-height: 100vh;

    //Todo : Shankar remove this h1 ,h2  ,h3 because we already have style below

    // h1 {
    //   font-size: var(--fontSize-36);
    //   color: var(--gray-600);
    //   line-height: 48px;
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-28);
    //     line-height: 28px;
    //   }
    // }

    // h2 {
    //   font-size: var(--fontSize-30);
    //   color: var(--gray-600);
    //   line-height: 40px;
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-22);
    //     line-height: 32px;
    //   }
    // }

    // h3 {
    //   font-size: var(--fontSize-24);
    //   color: var(--gray-600);
    //   line-height: 36px;
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-18);
    //     line-height: 28px;
    //   }
    // }

    // h4 {
    //   font-size: var(--fontSize-20);
    //   line-height: 28px;
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-16);
    //     line-height: 24px;
    //   }
    // }

    ul:not(.szh-menu),
    ol {
      padding-inline-start: 20px;
      font-size: var(--fontSize-15);
      color: var(--gray-500);
      font-weight: var(--fontNormal);
      margin-bottom: 20px;
    }

    ol {
      padding-inline-start: 0px;
      counter-reset: item;
    }

    ol > li {
      display: block;
      position: relative;
      margin-left: 2.5em;
    }

    ol > li:before {
      font-weight: var(--fontSemiBold);
      content: counters(item, '.') '. ';
      counter-increment: item;
      position: absolute;
      right: 100%;
      top: 0;
      white-space: nowrap;
      padding-right: 0.5em;
    }

    ol > li:has(span[data-font-family='monospace'])::before {
      font-family: monospace;
    }

    ol > li:has(span[data-font-family='sans-serif'])::before {
      font-family: sans-serif;
    }

    .remirror-table {
      ol {
        counter-reset: item !important;
      }

      ol li {
        counter-increment: list-item !important;
      }

      ol li::before {
        content: counter(list-item) '. ' !important;
      }
    }

    li:first-child {
      margin-top: 8px;
    }

    li:not(.szh-menu__item) {
      margin-bottom: 6px;
    }

    li p {
      margin-bottom: 0;
      margin-left: 0 !important;
    }

    img[data-custom-image='true'] {
      max-width: 100%;
    }

    /* blockquote style */
    // Todo : shankar already included below
    // blockquote {
    //   border-left: 5px solid var(--gray-600);
    //   padding: 8px 0 8px 24px;
    //   margin: 24px 0;
    //   cursor: default;

    //   * {
    //     cursor: text;
    //   }

    //   &.note-quote {
    //     border-left-color: var(--pink-500);
    //     padding-top: 8px;
    //     padding-bottom: 12px;
    //   }

    //   p.subtitle {
    //     font-size: var(--fontSize-18);
    //     line-height: 28px;
    //     display: block;
    //     @media screen and (max-width: 750px) {
    //       font-size: var(--fontSize-14);
    //     }
    //   }

    //   p.interview-source {
    //     font-weight: var(--fontNormal);
    //     font-size: var(--fontSize-11);
    //     font-style: normal;
    //     color: var(--gray-500);
    //     padding: 0px;
    //     height: unset;
    //     line-height: 15px;
    //     margin-top: 16px;

    //     a {
    //       font-weight: var(--fontNormal);
    //       color: var(--pink-500);
    //       cursor: pointer;
    //     }
    //     span {
    //       line-height: 20px;
    //     }
    //   }

    //   p {
    //     font-size: var(--fontSize-16);
    //     color: var(--gray-600);
    //     font-family: var(--fontNewsreaderItalic);
    //     line-height: 26px;
    //     margin-bottom: 0;
    //     @media screen and (max-width: 750px) {
    //       font-size: var(--fontSize-14);
    //     }
    //   }
    // }
  }

  .remirror-editor.ProseMirror {
    .remirror-table-tbody-with-controllers {
      p {
        @media screen and (max-width: 750px) {
          font-size: var(--fontSize-11) !important;
        }
      }
    }

    // Todo shankar : already given below
    // p {
    //   margin-bottom: 12px;
    //   line-height: 24px;
    //   font-size: var(--fontSize-16);
    //   font-weight: var(--fontNormal);
    //   color: var(--gray-600);
    //   padding: 0px;
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-14) !important;
    //   }

    //   strong {
    //     font-weight: var(--fontBold);
    //   }

    //   strong,
    //   em,
    //   u {
    //     color: var(--gray-600);
    //   }
    // }s

    //Todo : shankar already given below
    // div:is([data-callout-type], [data-option-callout-type]) {
    //   margin: 32px 0px;
    //   cursor: default;

    //   h2:first-child {
    //     width: 96%;
    //   }

    //   h4:first-child {
    //     width: 96%;
    //   }

    //   blockquote {
    //     margin: 16px 0 20px 0;
    //   }

    //   li:first-child {
    //     margin-top: 0px;
    //   }

    //   > div:first-child {
    //     margin-left: 0px;
    //     width: 100%;
    //     cursor: text;
    //   }

    //   > p {
    //     margin-bottom: 20px;
    //   }

    //   p,
    //   p strong,
    //   p strong *,
    //   p em,
    //   p u {
    //     color: var(--gray-600);
    //   }
    //   p.callout-type {
    //     display: flex;
    //     color: var(--gray-600);
    //     font-size: 26px;
    //     font-weight: var(--fontBold);
    //     font-weight: var(--fontSemiBold);
    //   }
    //   .NOTE_ROOT {
    //     margin-bottom: 4px;
    //     border: 1px solid var(--gray-100);

    //     &:hover {
    //       box-shadow: 0px 3px 7px rgba(61, 39, 24, 0.1);
    //     }

    //     .NOTE_CREATED_BY,
    //     .NOTE_CREATED_AT,
    //     .NOTE_INTERVIEW_NAME {
    //       color: var(--gray-300);
    //     }

    //     .create-cluster-button {
    //       display: none;
    //     }

    //     .bullet {
    //       background-color: var(--gray-300);
    //     }
    //   }

    //   hr {
    //     border-color: var(--gray-600) !important;
    //     margin: 40px 0;
    //   }
    // }

    blockquote p strong {
      font-family: var(--fontNewsreaderMedium);
      color: var(--gray-600);
    }

    hr {
      border-top: 3px solid var(--gray-background);
      margin: 80px -140px;
    }

    mark {
      font-size: inherit;
      font-family: inherit;
      padding: 0px;
      line-height: inherit;
      background-color: var(--yellow-300);
    }

    span.annotation-span,
    span[data-entity-reference],
    span[data-font-family],
    span[data-text-color-mark] {
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }
}

.text-editor {
  &.remirror-theme .ProseMirror:not(.remirror-editor-live-notes .ProseMirror) {
    max-width: unset;
  }

  .editor-container {
    height: calc(100vh - 125px);
    overflow: auto;
    overflow-x: hidden;
    border-right: 1px solid var(--gray-100);
  }

  .remirror-editor.ProseMirror {
    overflow: hidden;
  }

  &.remirror-theme .remirror-editor-wrapper {
    margin-bottom: 0px !important;

    h1,
    h1 * {
      line-height: 56px;
    }

    h2,
    h2 * {
      line-height: 46px;
    }

    h3,
    h3 * {
      line-height: 36px;
    }

    h4,
    h4 * {
      line-height: 30px;
    }
  }

  .remirror-resizable-view img {
    padding: 0.375rem 0;
  }
}

.text-editor {
  &.remirror-theme .ProseMirror:not(.remirror-editor-live-notes .ProseMirror) {
    background-color: var(--white-100);
    border: 0;
    box-shadow: 0px 3px 12px rgba(2, 0, 93, 0.1);
    width: 100%;
    max-width: 1120px;
    min-height: 1024px;
    margin: 0 auto;
    padding: 76px 140px 100px 140px;
    border-radius: 0 0 4px 4px;
    overflow: visible !important;
    &:active,
    &:focus {
      box-shadow: 0px 3px 12px rgba(2, 0, 93, 0.1);
    }

    @media screen and (max-width: 1370px) {
      padding-right: 80px;
      padding-left: 80px;
    }
  }

  &.remirror-theme
    .remirror-editor-wrapper:not(
      .remirror-editor-live-notes .remirror-editor-wrapper
    ) {
    padding-top: 0px !important;
    margin-bottom: 20px;
    min-height: 100vh;

    ul:not(.szh-menu),
    ol {
      padding-inline-start: 20px;
      font-size: var(--fontSize-15);
      color: var(--gray-500);
      font-weight: var(--fontNormal);
      margin-bottom: 20px;
    }

    ol {
      padding-inline-start: 0px;
      counter-reset: item;
    }

    ol > li {
      display: block;
      position: relative;
      margin-left: 2.5em;
    }

    ol > li:before {
      font-weight: var(--fontSemiBold);
      content: counters(item, '.') '. ';
      counter-increment: item;
      position: absolute;
      right: 100%;
      top: 0;
      white-space: nowrap;
      padding-right: 0.5em;
    }

    ol > li:has(span[data-font-family='monospace'])::before {
      font-family: monospace;
    }

    ol > li:has(span[data-font-family='sans-serif'])::before {
      font-family: sans-serif;
    }

    .remirror-table {
      ol {
        counter-reset: item !important;
      }

      ol li {
        counter-increment: list-item !important;
      }

      ol li::before {
        content: counter(list-item) '. ' !important;
      }
    }

    li:first-child {
      margin-top: 8px;
    }

    li:not(.szh-menu__item) {
      margin-bottom: 6px;
    }

    li p {
      margin-bottom: 0;
      margin-left: 0 !important;
    }

    img[data-custom-image='true'] {
      max-width: 100%;
    }
  }

  .remirror-editor.ProseMirror {
    .remirror-table-tbody-with-controllers {
      p {
        @media screen and (max-width: 750px) {
          font-size: var(--fontSize-11) !important;
        }
      }
    }

    blockquote p strong {
      font-family: var(--fontNewsreaderMedium);
      color: var(--gray-600);
    }

    hr {
      border-top: 3px solid var(--gray-background);
      margin: 80px -140px;
    }

    mark {
      font-size: inherit;
      font-family: inherit;
      padding: 0px;
      line-height: inherit;
      background-color: var(--yellow-300);
    }

    span.annotation-span,
    span[data-entity-reference],
    span[data-font-family],
    span[data-text-color-mark] {
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
    }
  }
}

.text-analysis-viewer-editor.remirror-theme .ProseMirror {
  padding: 1.5rem;
  box-shadow: none;
  height: 50vh;
  min-height: 50vh;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.text-analysis-viewer-editor.remirror-theme .remirror-editor-wrapper {
  margin-bottom: 0px;
  min-height: fit-content;
}

.text-analysis-viewer-editor .remirror-editor-wrapper {
  padding-top: 0px !important;
  height: 50vh;
  min-height: 50vh;
}

.note-tooltip {
  font-size: var(--fontSize-12);
  color: var(--white);
}

.remirror-theme.report-editable .remirror-editor-wrapper blockquote.note-quote {
  cursor: move;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_ROOT {
  background-color: var(--white-100);
  border: 1px solid var(--gray-100);
  box-sizing: border-box;
  border-radius: 12px;
  padding: 22px 28px 18px 28px;
  margin-bottom: 0.5rem;
  position: relative;
  @media screen and (max-width: 750px) {
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 50, 0.15);
    padding: 12px 16px;
  }
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_ROOT:hover {
  box-shadow: 0px 3px 12px 0px rgba(0, 0, 50, 0.15);
  cursor: pointer;
  border: 1px solid var(--gray-200);
}

.insight-template-preview
  .remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_ROOT:hover {
  box-shadow: none;
  cursor: auto;
  border: 1px solid var(--gray-100);
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_EDITABLE:hover {
  cursor: move;
}

.remirror-editor.ProseMirror .playlist-container:hover {
  box-shadow: 0px 3px 7px rgb(2 0 93 / 10%);
  cursor: move;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_LABELS_CONTAINER {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 4px;
  display: none;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_LINK {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_LABEL {
  height: 24px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid var(--gray-100);
  padding: 0 6px;
  line-height: 22px;
  font-size: var(--fontSize-11);
  color: var(--gray-500);
}

.remirror-theme .remirror-editor-wrapper a {
  font-size: inherit;
  font-family: inherit;
  color: var(--gray-400);
  line-height: inherit;
}

.NOTE_LABEL span {
  line-height: 18px;
  font-size: var(--fontSize-11);
  color: var(--gray-500);
}

.NOTE_FOOTER_WRAPPER div:first-child {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-start;
  min-width: 0;
}

.NOTE_FOOTER_WRAPPER .more-options-button {
  width: 28px;
  height: 28px;
  border: none;
  background: var(--white-100);
  border-radius: 4px;
}

.NOTE_FOOTER_WRAPPER .more-options-button.convert-to-quote-button svg {
  margin-left: -1px;
}

.NOTE_FOOTER_WRAPPER .more-options-button.create-cluster-button:hover {
  background-color: var(--yellow-100);
}

.NOTE_FOOTER_WRAPPER .more-options-button.convert-to-quote-button:hover {
  background-color: var(--pink-50);
}

.NOTE_FOOTER_WRAPPER .more-options-button:hover {
  background: var(--blue-50);
}

.NOTE_FOOTER_WRAPPER .more-options-button:focus {
  outline: none;
}

.NOTE_FOOTER_WRAPPER {
  .dropdown-content {
    position: absolute;
    right: 0;
    margin-top: 2.25rem;
    margin-right: 0.75rem;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--white-100);
    border: 1px solid var(--gray-100);
    box-shadow: 0px 4px 4px rgb(0 0 0 / 7%);
    border-radius: 8px;

    button:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    button:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    button {
      width: 100%;
      background: var(--white-100);
      border: none;
      color: var(--gray-600);
      font-size: var(--fontSize-12);
      padding: 10px 32px 10px 18px;
      text-align: left;

      &.no-hover:hover {
        color: var(--gray-600);
      }

      &:hover {
        background-color: var(--gray-50);
      }
    }

    button {
      &.delete-note-button {
        color: var(--orange-200);
      }

      svg,
      img {
        width: 22px;
        height: 22px;
        margin-right: 8px;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.NOTE_ROOT {
  .more-options-container {
    display: none;
    margin-right: -8px;
  }
}

.remirror-editor.ProseMirror[contenteditable='true'] {
  .NOTE_ROOT:hover {
    .more-options-container {
      display: flex;
      gap: 0.25rem;
    }
  }
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_LABEL svg {
  margin: 0 6px;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_TITLE {
  font-size: var(--fontSize-14) !important;
  font-weight: var(--fontSemiBold);
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-600);
  margin-bottom: 8px;
  @media screen and ( max-width:"750px") {
    font-size: var(--fontSize-13) !important;
  }
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION {
  font-size: var(--fontSize-14);
  line-height: 22px;
  display: flex;
  align-items: center;
  color: var(--gray-600);
  margin-bottom: 4px;
  font-weight: var(--fontNormal);
  word-break: break-word;
  @media screen and ( max-width:"750px") {
    font-size: var(--fontSize-12) !important;
  }
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION p,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION ol,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION ul,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION li,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION h1,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION h2,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION h3,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION h4,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION h5,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION li {
  padding-left: 0;
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION p,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION ol,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION ul,
.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_DESCRIPTION li {
  font-size: var(--fontSize-14);
  line-height: 22px;
  font-family: var(--fontFamily);
}

.remirror-editor.ProseMirror .note-node-view-wrapper .NOTE_FOOTER_WRAPPER {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
}

.remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_FOOTER_WRAPPER
  .NOTE_CREATED_AT,
.remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_FOOTER_WRAPPER
  .NOTE_CREATED_BY,
.remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_FOOTER_WRAPPER
  .NOTE_INTERVIEW_NAME {
  color: var(--gray-400);
  margin-bottom: 0;
  font-size: var(--fontSize-11);
  font-family: var(--fontFamily);
  @media screen and (max-width: '750px') {
    font-size: var(--fontSize-11) !important;
  }
  font-weight: var(--fontNormal);
}

.NOTE_CREATED_BY,
.NOTE_CREATED_AT {
  flex-shrink: 0;
}

.NOTE_INTERVIEW_NAME {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.NOTE_ROOT_LOADER {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-md {
  min-width: 620px;
}

.remirror-list-item-marker-container {
  margin-bottom: 0px;
  margin-top: 5px;
}

/* focus color when node is selected */
.remirror-editor .ProseMirror-selectednode {
  outline: 1px solid var(--gray-500);
  border-radius: 12px;
}

/* focus color when node is selected */
.remirror-editor
  .note-node-view-wrapper.ProseMirror-selectednode[data-variant='default'],
.remirror-editor
  .note-node-view-wrapper.ProseMirror-selectednode[data-variant='text'] {
  outline: 1px solid var(--gray-500);
  border-radius: 12px;
}

/* start: shimmer for note */
p.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  color: transparent;
  user-select: none;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

p.shimmer.loading-message {
  height: 1.25rem;
  border-radius: 0.25rem;
  margin-bottom: 0.5rem;
}

p.shimmer.loading-message.loading-message-half {
  width: 50%;
}

/* end: shimmer for note */

/* start: analyze page styles */
.analyze-editor.remirror-theme {
  .remirror-editor.ProseMirror {
    padding: 32px 44px !important;
    box-shadow: none !important;
    border-radius: 0 !important;

    hr {
      margin: 80px -24px;
      width: 108%;
    }

    div[data-callout-type] {
      margin: 32px -24px;
      padding: 32px 42px;
    }
    div[data-option-callout-type] {
      margin: 32px -24px;
      padding: 32px 42px;
    }
  }

  .remirror-editor-wrapper {
    padding: 0px !important;
    margin-bottom: 0px;

    h1 {
      margin-bottom: 24px;
    }

    blockquote {
      margin: 20px 0;

      p {
        font-size: var(--fontSize-16);
        line-height: 24px;
      }

      p.interview-source {
        font-size: var(--fontSize-11);
      }
    }
  }
}

.analyze-editor .remirror-editor.ProseMirror p {
  margin-bottom: 12px;
}

.analyze-editor.remirror-theme .remirror-editor-wrapper blockquote p {
  margin-bottom: 0px;
}

.analyze-editor
  .remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_FOOTER_WRAPPER
  .NOTE_CREATED_AT,
.analyze-editor
  .remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_FOOTER_WRAPPER
  .NOTE_CREATED_BY,
.analyze-editor
  .remirror-editor.ProseMirror
  .note-node-view-wrapper
  .NOTE_FOOTER_WRAPPER
  .NOTE_INTERVIEW_NAME {
  font-size: var(--fontSize-10);
  font-family: var(--fontFamily);
}

.analyze-editor .remirror-editor.ProseMirror .note-details-container p,
.analyze-editor .remirror-editor.ProseMirror .note-details-container ol,
.analyze-editor .remirror-editor.ProseMirror .note-details-container ul,
.analyze-editor .remirror-editor.ProseMirror .note-details-container li,
.analyze-editor .remirror-editor.ProseMirror .note-details-container h1,
.analyze-editor .remirror-editor.ProseMirror .note-details-container h2,
.analyze-editor .remirror-editor.ProseMirror .note-details-container h3,
.analyze-editor .remirror-editor.ProseMirror .note-details-container h4,
.analyze-editor .remirror-editor.ProseMirror .note-details-container h5,
.analyze-editor .remirror-editor.ProseMirror .note-details-container h6 {
  margin-top: 0;
  margin-bottom: 0;
}

.analyze-editor .remirror-editor.ProseMirror .note-details-container li {
  padding-left: 0;
}

.analyze-editor .remirror-editor.ProseMirror .note-details-container p,
.analyze-editor .remirror-editor.ProseMirror .note-details-container ol,
.analyze-editor .remirror-editor.ProseMirror .note-details-container ul,
.analyze-editor .remirror-editor.ProseMirror .note-details-container li {
  font-size: var(--fontSize-14);
  line-height: 22px;
  font-family: var(--fontFamily);
}

/* end: analyze page styles */

/* start: table styles */
.remirror-editor.ProseMirror .remirror-table p {
  font-size: var(--fontSize-13);
  color: var(--gray-500);
  margin-bottom: 0px;
}

.remirror-editor.ProseMirror .remirror-table h1,
.remirror-editor.ProseMirror .remirror-table h2 {
  margin-bottom: 0px;
}

.remirror-editor.ProseMirror .remirror-table {
  margin: 30px 0 60px 0;
  background-color: var(--white-100);
}

.remirror-editor.ProseMirror div.remirror-table-controller-mark-row-corner,
.remirror-editor.ProseMirror div.remirror-table-controller-mark-column-corner {
  display: none;
}

.remirror-theme .remirror-editor-wrapper td blockquote {
  border-left: none;
  padding: 0;
  margin: 0;
}

.remirror-theme .remirror-editor-wrapper td blockquote p {
  font-size: var(--fontSize-13);
  color: var(--gray-600);
  font-family: var(--fontNewsreaderItalic);
  margin-bottom: 0;
  line-height: 1.25rem;
}

.remirror-table-delete-row-column-inner-button svg,
.remirror-table-insert-button svg {
  display: block;
}

.remirror-menu button.remirror-menu-item:nth-child(1),
.remirror-menu button.remirror-menu-item:nth-child(2),
.remirror-menu button.remirror-menu-item:nth-child(3) {
  display: none;
}

.remirror-table th p {
  font-weight: var(--fontSemiBold);
}

.remirror-table th:not(.remirror-table-controller) {
  background-color: var(--gray-50);
  padding: 10px 12px !important;
}

.remirror-menu {
  box-shadow: rgb(0 0 50 / 9%) 0px 0px 10px;
  border-radius: 4px;
  border: 1px solid var(--gray-100) !important;
  width: 9.25rem !important;
  right: 0;
}

.remirror-button {
  border-color: var(--gray-200) !important;
  right: 4px !important;
  top: -2px !important;
}

.remirror-editor.ProseMirror[contenteditable='false']
  .remirror-table-delete-row-column-inner-button,
.remirror-editor.ProseMirror[contenteditable='false']
  .remirror-table-insert-button,
.remirror-editor.ProseMirror[contenteditable='false'] .remirror-button {
  display: none;
}

/* end: table styles */

.remirror-floating-popover {
  z-index: 2;
}

/* start: text analysis styles */

.text-analysis-container {
  .remirror-theme {
    .remirror-editor-wrapper {
      min-height: calc(100vh - 7.875rem);
      height: 7.875rem;
      overflow-y: auto;
      box-shadow: 2px 4px 12px rgba(2, 0, 93, 0.1);
      margin-bottom: 0px;
    }

    .ProseMirror {
      margin: 0px;
      width: unset;
      max-width: unset;
      padding: 3.5rem 5.5rem;
    }
  }
}

/* end: text analysis styles */

/* start: range handle styles */
.range-handle {
  white-space: normal;
  overflow-wrap: normal;
  cursor: ew-resize;
  position: relative;
  z-index: 1;
}

.range-handle-start::after {
  bottom: calc(-6px);
  box-shadow: var(--pink-500) 0px 0px 0px 50px inset;
  content: '';
  left: 0px;
  position: absolute;
  top: calc(-5px);
  width: 4px;
  z-index: 1;
}

.range-handle-start::before {
  content: '';
  display: block;
  background-color: var(--pink-500);
  width: 8px;
  height: 8px;
  position: absolute;
  left: -2px;
  top: -12px;
  border-radius: 100%;
}

.range-handle-end::after {
  bottom: calc(-6px);
  box-shadow: var(--pink-500) 0px 0px 0px 50px inset;
  content: '';
  left: 0px;
  position: absolute;
  top: -7px;
  width: 4px;
}

.range-handle-end::before {
  content: '';
  display: block;
  background-color: var(--pink-500);
  width: 8px;
  height: 8px;
  position: absolute;
  left: -2px;
  top: 19px;
  border-radius: 100%;
}

/* end: range handle styles */

/* start: research area styles */

.research-area-doc-edit-mode {
  .remirror-theme {
    border: 1px solid var(--gray-100) !important;
    border-radius: 8px !important;
    overflow: hidden !important;

    .remirror-editor-wrapper {
      min-height: calc(100vh - 20rem) !important;
      max-height: 1rem !important;
      overflow-y: auto !important;
      margin-bottom: 0px !important;
      padding-top: 0px !important;
    }

    .ProseMirror {
      margin: 0px !important;
      width: unset !important;
      max-width: unset !important;
      padding: 2.5rem !important;
      min-height: unset !important;
      min-height: calc(100vh - 20rem) !important;
    }

    .ProseMirror p {
      font-size: var(--fontSize-15) !important;
    }
  }
}

.research-area-doc-read-mode {
  .remirror-theme {
    border: 1px solid transparent;

    .remirror-editor-wrapper {
      min-height: 100%;
      margin-bottom: 0px;
    }

    .ProseMirror {
      margin: 0px;
      width: unset;
      max-width: unset;
      padding: 0 !important;
      min-height: fit-content;
      height: fit-content;
      box-shadow: none;
      overflow: auto;
      background-color: var(--gray-50);
    }

    .ProseMirror:active {
      box-shadow: none;
    }

    .ProseMirror p {
      font-size: var(--fontSize-15);
    }
  }
}

/* end: text analysis styles */

/* New Styles for Reports */
.report-editor,
.analyze-editor,
.project-overview-editor {
  &.remirror-theme .ProseMirror:not(.remirror-editor-live-notes .ProseMirror) {
    overflow: hidden;
  }
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
  }
  .comment-mention-user-name {
    color: var(--blue-600);
    font-weight: '600';
    font-size: var(--fontSize-13);
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
  }
  &.remirror-theme .remirror-editor-wrapper {
    //editor heading final design system : Shankar
    h1,
    h1 *:not(.remirror-resizable-view, .remirror-resizable-view *) {
      font-size: var(--fontSize-40) !important;
      font-weight: var(--fontBold) !important;
      line-height: 48px !important;
      margin-top: 32px !important;
      margin-bottom: 8px !important;
      color: var(--gray-600);
      .citation-node-view-wrapper {
        div * {
          margin: 0px !important;
        }
        .flyout-container > button {
          line-height: 14px !important;
          font-size: 9px !important;
          margin-bottom: 3px !important;
          margin-top: 6px !important;
          > div {
            color: var(--white-100);
            line-height: 14px !important;
            font-size: 9px !important;
          }
        }
      }
      @media screen and (max-width: 750px) {
        font-size: var(--fontSize-28) !important;
        line-height: 40px;
        margin-top: 16px;
        margin-bottom: 4px;
      }
    }

    h2,
    h2 *:not(.remirror-resizable-view, .remirror-resizable-view *) {
      font-size: var(--fontSize-32) !important;
      font-weight: var(--fontBold) !important;
      line-height: 40px !important;
      margin-top: 24px !important;
      margin-bottom: 8px !important;
      color: var(--gray-600);
      .citation-node-view-wrapper {
        div * {
          margin: 0px !important;
        }
        .flyout-container > button {
          line-height: 14px !important;
          font-size: 9px !important;
          margin-bottom: 3px !important;
          margin-top: 4px !important;
          > div {
            color: var(--white-100);
            line-height: 14px !important;
            font-size: 9px !important;
          }
        }
      }
      @media screen and (max-width: 750px) {
        font-size: var(--fontSize-22);
        line-height: 32px;
        margin-top: 12px;
        margin-bottom: 4px;
      }
    }

    h3,
    h3 *:not(.remirror-resizable-view, .remirror-resizable-view *) {
      font-size: var(--fontSize-24) !important;
      font-weight: var(--fontBold) !important;
      line-height: 32px !important;
      margin-top: 24px !important;
      margin-bottom: 8px !important;
      color: var(--gray-600);
      .citation-node-view-wrapper {
        div * {
          margin: 0px !important;
        }
        .flyout-container > button {
          line-height: 14px !important;
          font-size: 9px !important;
          margin-bottom: 3px !important;
          margin-top: 0px !important;
          > div {
            color: var(--white-100);
            line-height: 14px !important;
            font-size: 9px !important;
          }
        }
      }
      @media screen and (max-width: 750px) {
        font-size: var(--fontSize-18) !important;
        line-height: 28px;
        margin-top: 12px;
        margin-bottom: 4px;
      }
    }

    h4,
    h4 *:not(.remirror-resizable-view, .remirror-resizable-view *) {
      font-size: var(--fontSize-18) !important;
      line-height: 24px !important;
      font-weight: var(--fontBold) !important;
      margin-top: 24px !important;
      margin-bottom: 8px !important;
      color: var(--gray-600);
      .citation-node-view-wrapper {
        div * {
          margin: 0px !important;
        }
        .flyout-container > button {
          line-height: 14px !important;
          font-size: 9px !important;
          margin-bottom: 3px !important;
          margin-top: 0px !important;
          > div {
            color: var(--white-100);
            line-height: 14px !important;
            font-size: 9px !important;
          }
        }
      }
      @media screen and (max-width: 750px) {
        font-size: var(--fontSize-16) !important;
        line-height: 24px !important;
        margin-top: 12px;
        margin-bottom: 4px;
      }
    }
    // editor paragraph design system
    p {
      font-size: var(--fontSize-16) !important;
      line-height: 26px !important;
      margin-top: 8px !important;
      margin-bottom: 8px !important;
      color: var(--gray-600);
      @media screen and (max-width: '750px') {
        line-height: 22px;
      }
      span[inline-comment-id] {
        font-family: var(--Cheltenham) !important;
      }

      strong,
      strong * {
        font-family: var(--CheltenhamBold);
      }

      strong,
      strong * {
        font-weight: var(--fontBold) !important;
      }

      // TODO Shankar : Remove Eina dependency and if eina is stored in the database, then defaulting to Cheltenham/Serif
      strong [data-font-family='Eina01-Regular'],
      strong [data-font-family='Eina01-Regular'] * {
        font-family: var(--CheltenhamBold) !important;
      }

      strong [data-font-family='monospace'],
      strong [data-font-family='monospace'] * {
        font-weight: var(--fontBold) !important;
      }

      strong [data-font-family='CheltenhamITCBookBT'],
      strong [data-font-family='CheltenhamITCBookBT'] * {
        font-family: var(--CheltenhamBold) !important;
      }

      strong,
      strong *,
      em,
      u {
        color: var(--gray-600);
      }

      em {
        font-family: var(--CheltenhamItalic);
      }
    }
    [data-id='note-description'] p {
      font-size: var(--fontSize-14) !important;
      line-height: 24px !important;
      color: var(--gray-500) !important;
      font-weight: var(--fontNormal) !important;
      margin-top: 8px;
      margin-bottom: 8px;
    }

    // editor blockquote design system
    blockquote {
      border-left: 3px solid var(--gray-600);
      cursor: default;
      padding: 0px 0 0px 19px;
      margin-top: 32px;
      margin-bottom: 32px;
      * {
        cursor: text;
      }

      &.note-quote {
        border-left: 3px solid var(--gray-600);
        padding-top: 8px;
        padding-bottom: 12px;
      }

      p.subtitle {
        font-size: var(--fontSize-16);
        line-height: 24px;
        display: block;
      }

      p.interview-source {
        color: var(--gray-600);
      }

      p {
        font-size: var(--fontSize-16) !important;
        line-height: 26px !important;
        margin: 0px !important;
        color: var(--gray-600);
        @media screen and (max-width: 750px) {
          font-size: var(--fontSize-14) !important;
          line-height: 20px !important;
        }
      }
    }

    // Unified callout styles
    div:is([data-callout-type], [data-option-callout-type]) {
      margin: 32px 0px;
      cursor: default;

      h1:first-child {
        width: 96%;
        margin-top: 0px !important;
        margin-bottom: 8px !important;
      }
      h2:first-child {
        width: 96%;
        margin-top: 0px !important;
        margin-bottom: 8px !important;
      }

      h3:first-child {
        width: 96%;
        margin-top: 0px !important;
        margin-bottom: 8px !important;
      }

      h4:first-child {
        width: 85%;
        margin-top: 0px !important;
        margin-bottom: 8px !important;
      }

      blockquote {
        margin: 16px 0 20px 0;
      }

      li:first-child {
        margin-top: 0px;
      }

      > div:first-child {
        margin-left: 0px;
        width: 100%;
        cursor: text;
      }
      > div .note-node-view-wrapper:first-child {
        margin-top: 30px !important;
      }

      > p {
        margin-bottom: 20px;
      }

      p,
      p strong,
      p strong *,
      p em,
      p u {
        color: var(--gray-600);
      }
      p.callout-type {
        display: flex;
        color: var(--gray-600);
        font-size: 26px;
        font-weight: var(--fontBold);
        font-weight: var(--fontSemiBold);
      }
      .NOTE_ROOT {
        margin-bottom: 4px;
        border: 1px solid var(--gray-100);

        &:hover {
          box-shadow: 0px 3px 7px rgba(61, 39, 24, 0.1);
        }

        .NOTE_CREATED_BY,
        .NOTE_CREATED_AT,
        .NOTE_INTERVIEW_NAME {
          color: var(--gray-300);
        }

        .create-cluster-button {
          display: none;
        }

        .bullet {
          background-color: var(--gray-300);
        }
      }

      hr {
        border-color: var(--gray-600) !important;
        margin: 40px 0;
      }
    }

    // Todo : Shankar commented this code bcz dont know exact purpose of this
    // .interview-source::before {
    //   background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjUgMTlDMTQuNzQ2NyAxOSAxOSAxNC43NDY3IDE5IDkuNUMxOSA0LjI1MzI5IDE0Ljc0NjcgMCA5LjUgMEM0LjI1MzI5IDAgMCA0LjI1MzI5IDAgOS41QzAgMTQuNzQ2NyA0LjI1MzI5IDE5IDkuNSAxOVpNMTMuNzUzNyAxMC4wMjkyQzEzLjk0MjggOS45MjAwMiAxMy45NDI4IDkuNjQ3MTQgMTMuNzUzNyA5LjUzNzk5TDcuNTg1ODIgNS45NzY5NUM3LjM5Njc3IDUuODY3OCA3LjE2MDQ1IDYuMDA0MjQgNy4xNjA0NSA2LjIyMjU0TDcuMTYwNDUgMTMuMzQ0NkM3LjE2MDQ1IDEzLjU2MjkgNy4zOTY3NyAxMy42OTk0IDcuNTg1ODIgMTMuNTkwMkwxMy43NTM3IDEwLjAyOTJaIiBmaWxsPSIjOUM5OTk2Ii8+Cjwvc3ZnPgo=');
    // }

    // .note-details-container {
    //   background-color: var(--white-100);
    // }

    // p,
    // p strong,
    // p em,
    // p u {
    //   color: var(--gray-600);
    //   font-size: var(--fontSize-16) !important;
    //   line-height: 26px !important;
    //   margin: 0px !important;
    //   color: var(--gray-600);
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-14) !important;
    //     line-height: 20px !important;
    //   }
    // }

    // .playlist-duration p,
    // .playlist-count p {
    //   color: var(--white-100);
    //   font-weight: var(--fontNormal);
    //   font-style: normal;
    //   font-size: var(--fontSize-12);
    // }

    // .NOTE_ROOT {
    //   margin: 0.5rem 0;
    //   border: 1px solid var(--gray-100);

    //   &:hover {
    //     box-shadow: 0px 3px 7px rgba(61, 39, 24, 0.1);
    //   }

    //   @media screen and (max-width: 750px) {
    //     box-shadow: 0px 4px 10px 0px rgba(0, 0, 50, 0.07);
    //   }

    //   .NOTE_CREATED_BY,
    //   .NOTE_CREATED_AT,
    //   .NOTE_INTERVIEW_NAME {
    //     color: var(--gray-400);
    //     @media screen and (max-width: 350px) {
    //       font-size: 9px !important;
    //     }
    //   }
    // }

    // hr {
    //   border-color: var(--gray-300);
    //   margin: 40px 0;
    //   width: 100%;
    // }

    // blockquote {
    //   border-left: 4px solid var(--gray-300);
    // }
    // h2 {
    //   font-size: var(--fontSize-20) !important;
    //   font-weight: var(--fontBold) !important;
    //   line-height: 30px !important;
    //   margin-top: 10px !important;
    //   margin-bottom: 4px !important;
    //   color: var(--gray-600);
    //   @media screen and (max-width: 750px) {
    //     font-size: var(--fontSize-12) !important;
    //     line-height: 16px;
    //     margin-top: 2px;
    //     margin-bottom: 2px;
    //   }
    // }

    // Specific styles for option callout types
    div[data-option-callout-type='colourful'] {
      border-top: 2px solid var(--gray-100);
      border-bottom: 2px solid var(--gray-100);
      padding-top: 40px;
      padding-bottom: 40px;
      border-radius: 0px !important;

      .content-dom {
        inset: 0;
        z-index: 1 !important;
      }

      .colourfull-div-container {
        position: absolute;
        top: 12px;
        left: 28px;
        right: 28px;
        bottom: 12px;
        background: linear-gradient(
          to right,
          rgba(255, 215, 222, 0.4) 0.48%,
          rgba(255, 225, 208, 0.4) 15.28%,
          rgba(252, 241, 211, 0.4) 27.12%,
          rgba(227, 247, 229, 0.4) 52.27%,
          rgba(212, 253, 241, 0.4) 64.42%,
          rgba(198, 209, 249, 0.4) 79.85%,
          rgba(233, 238, 255, 0.4) 97.18%
        );
        filter: blur(48px);
        border-radius: inherit;
      }
    }

    div[data-option-callout-border-color='popup'],
    div[data-option-callout-type='popup'] {
      background: var(--white-100);
      border-radius: 12px;
    }

    div[data-option-callout-type='solid'] {
      padding: 28px 36px 36px;
      border-radius: 12px;
    }

    // TODO Shankar : embed insight card now use marvin ui elements
    // .remirror-editor.ProseMirror .insight-node-view-wrapper {
    //   & .insight-root {
    //     background-color: var(--white-100);
    //     border: 1px solid var(--gray-100);
    //     box-sizing: border-box;
    //     width: 100%;
    //     margin-bottom: 0.5rem;
    //     border-radius: 12px;
    //     // padding: 24px 24px 16px 28px;
    //     margin-bottom: 0.5rem;
    //     position: relative;
    //     display: flex;
    //     flex-direction: column;
    //     @media screen and (max-width: 750px) {
    //       box-shadow: 0px 3px 12px 0px rgba(0, 0, 50, 0.15);
    //       padding: 24px;
    //     }

    //     &:hover {
    //       box-shadow: 0px 3px 12px 0px rgba(0, 0, 50, 0.15);
    //       cursor: pointer;
    //       border: 1px solid var(--gray-200);
    //     }

    //     & .insight-content-wrapper {
    //       display: flex;
    //       align-items: center;
    //       gap: 24px;
    //       height: 130px;
    //     }

    //     & .insight-image {
    //       height: 36px;
    //       width: 100%;
    //       border-radius: 5px;
    //       flex-shrink: 0;
    //       object-fit: cover;
    //       background-color: var(--gray-50);
    //       @media screen and( max-width:"750px") {
    //         margin-bottom: 10px;
    //         height: 48px;
    //         width: 48px;
    //       }
    //     }

    //     & .insight-content {
    //       display: flex;
    //       flex-direction: column;
    //       height: 100%;
    //       gap: 8px;
    //     }

    //     & .insight-title {
    //       color: var(--gray-600);
    //       font-size: var(--fontSize-16);
    //       font-family: var(--CheltenhamBold);
    //       white-space: normal;
    //       overflow: hidden;
    //       text-overflow: ellipsis;
    //       display: -webkit-box;
    //       -webkit-line-clamp: 2;
    //       -webkit-box-orient: vertical;
    //       @media screen and( max-width:"750px") {
    //         font-size: var(--fontSize-16);
    //       }
    //     }

    //     & .insight-description {
    //       color: var(--gray-500);
    //       font-weight: var(--fontNormal);
    //       font-size: var(--fontSize-13);
    //       line-height: 20px;
    //       overflow: hidden;
    //       display: -webkit-box;
    //       -webkit-box-orient: vertical;
    //       -webkit-line-clamp: 3;
    //       height: auto;
    //       text-overflow: ellipsis;
    //       -webkit-line-break: auto;
    //       white-space: normal;
    //       @media screen and( max-width:"750px") {
    //         font-size: var(--fontSize-13);
    //       }
    //     }

    //     & .insight-footer {
    //       align-items: center;
    //       gap: 6px;
    //       margin-top: 8px;
    //       margin-left: -2px;
    //     }

    //     & .insight-created-by {
    //       color: var(--gray-500);
    //       font-weight: var(--fontSemiBold);
    //       font-size: 11px;
    //       font-style: normal;
    //       font-weight: var(--fontNormal);
    //       line-height: normal;
    //       margin-bottom: 0px !important;
    //       margin-top: 2px;
    //       @media screen and( max-width:"750px") {
    //         font-size: var(--fontSize-10);
    //       }
    //     }

    //     & .insight-user-avatar {
    //       width: 23px;
    //       height: 23px;
    //       border-radius: 50%;
    //     }

    //     & .insight-created-at {
    //       color: var(--gray-500);
    //       font-weight: var(--fontNormal);
    //       font-size: 11px;
    //       font-style: normal;
    //       font-weight: var(--fontNormal);
    //       line-height: normal;
    //       margin-top: 2px;
    //       @media screen and( max-width:"750px") {
    //         font-size: var(--fontSize-10);
    //       }
    //     }

    //     & .insight-bullet {
    //       background-color: var(--gray-500);
    //       border-radius: 50%;
    //       height: 3px;
    //       width: 3px;
    //     }
    //   }
    // }
  }

  .remirror-editor.ProseMirror {
    p {
      line-height: 24px;
      font-size: var(--fontSize-16);
      font-family: var(--Cheltenham);
      color: var(--gray-600);
      @media screen and (max-width: '750px') {
        line-height: 22px;
      }
      span[inline-comment-id] {
        font-family: var(--Cheltenham) !important;
      }

      strong,
      strong * {
        font-family: var(--CheltenhamBold);
      }

      strong,
      strong * {
        font-weight: var(--fontBold) !important;
      }

      // TODO Shankar : Remove This Commented Code If There is not dependecy on Eina

      strong,
      strong *,
      em,
      u {
        color: var(--gray-600);
      }

      em {
        font-family: var(--CheltenhamItalic);
      }
    }

    // TODO Shankar : Remove This Commented Code If There is not dependecy on Eina
    h1,
    h2,
    h3,
    h4 {
      font-weight: var(--fontBold) !important;
      [data-font-family='Eina01-SemiBold'],
      [data-font-family='Eina01-SemiBold'] * {
        font-weight: var(--fontSemiBold) !important;
        font-family: var(--CheltenhamBold) !important;
      }
    }

    blockquote p strong {
      font-family: var(--CheltenhamBold);
      color: var(--gray-600);
    }

    hr {
      border: none;
      width: 100%;
      margin: 40px auto;
      height: 6px;
      display: flex;
      justify-content: center;
      background: radial-gradient(circle, var(--gray-500) 2px, transparent 2px)
        repeat-x;
      background-size: 15px 6px;
    }

    div[data-callout-type] {
      .interview-source::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjUgMTlDMTQuNzQ2NyAxOSAxOSAxNC43NDY3IDE5IDkuNUMxOSA0LjI1MzI5IDE0Ljc0NjcgMCA5LjUgMEM0LjI1MzI5IDAgMCA0LjI1MzI5IDAgOS41QzAgMTQuNzQ2NyA0LjI1MzI5IDE5IDkuNSAxOVpNMTMuNzUzNyAxMC4wMjkyQzEzLjk0MjggOS45MjAwMiAxMy45NDI4IDkuNjQ3MTQgMTMuNzUzNyA5LjUzNzk5TDcuNTg1ODIgNS45NzY5NUM3LjM5Njc3IDUuODY3OCA3LjE2MDQ1IDYuMDA0MjQgNy4xNjA0NSA2LjIyMjU0TDcuMTYwNDUgMTMuMzQ0NkM3LjE2MDQ1IDEzLjU2MjkgNy4zOTY3NyAxMy42OTk0IDcuNTg1ODIgMTMuNTkwMkwxMy43NTM3IDEwLjAyOTJaIiBmaWxsPSIjOUM5OTk2Ii8+Cjwvc3ZnPgo=');
      }

      .note-details-container {
        background-color: var(--white-100);
      }

      p {
        color: var(--gray-600);
      }

      p strong,
      p em,
      p u {
        color: var(--gray-600);
      }

      .playlist-duration p,
      .playlist-count p {
        color: var(--white-100);
        font-weight: var(--fontNormal);
        font-style: normal;
        font-size: var(--fontSize-12);
      }

      .NOTE_ROOT {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        border: 1px solid var(--gray-100);
        &:hover {
          box-shadow: 0px 3px 7px rgba(61, 39, 24, 0.1);
        }
        @media screen and (max-width: 750px) {
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 50, 0.07);
        }

        .NOTE_CREATED_BY,
        .NOTE_CREATED_AT,
        .NOTE_INTERVIEW_NAME {
          color: var(--gray-400);
          @media screen and (max-width: 350px) {
            font-size: 9px !important;
          }
        }
      }

      hr {
        border-color: var(--gray-300);
        margin: 40px 0;
        width: 100%;
      }

      blockquote {
        border-left: 4px solid var(--gray-300);
      }
    }

    div[data-option-callout-type] {
      .interview-source::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik05LjUgMTlDMTQuNzQ2NyAxOSAxOSAxNC43NDY3IDE5IDkuNUMxOSA0LjI1MzI5IDE0Ljc0NjcgMCA5LjUgMEM0LjI1MzI5IDAgMCA0LjI1MzI5IDAgOS41QzAgMTQuNzQ2NyA0LjI1MzI5IDE5IDkuNSAxOVpNMTMuNzUzNyAxMC4wMjkyQzEzLjk0MjggOS45MjAwMiAxMy45NDI4IDkuNjQ3MTQgMTMuNzUzNyA5LjUzNzk5TDcuNTg1ODIgNS45NzY5NUM3LjM5Njc3IDUuODY3OCA3LjE2MDQ1IDYuMDA0MjQgNy4xNjA0NSA2LjIyMjU0TDcuMTYwNDUgMTMuMzQ0NkM3LjE2MDQ1IDEzLjU2MjkgNy4zOTY3NyAxMy42OTk0IDcuNTg1ODIgMTMuNTkwMkwxMy43NTM3IDEwLjAyOTJaIiBmaWxsPSIjOUM5OTk2Ii8+Cjwvc3ZnPgo=');
      }

      .note-details-container {
        background-color: var(--white-100);
      }

      p {
        color: var(--gray-600);
      }

      p strong,
      p em,
      p u {
        color: var(--gray-600);
      }

      .playlist-duration p,
      .playlist-count p {
        color: var(--white-100);
        font-weight: var(--fontNormal);
        font-style: normal;
        font-size: var(--fontSize-12);
      }

      .NOTE_ROOT {
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;

        .NOTE_CREATED_BY,
        .NOTE_CREATED_AT,
        .NOTE_INTERVIEW_NAME {
          color: var(--gray-400);
        }
      }

      hr {
        border-color: var(--gray-300);
        margin: 40px 0;
        width: 100%;
      }

      blockquote {
        border-left: 3px solid var(--gray-600);
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }

    mark {
      color: var(--gray-600);
    }

    mark > span {
      font-size: inherit !important;
    }

    h1,
    h2,
    h3,
    h4 {
      mark,
      span {
        font-family: inherit;
      }
    }
  }

  .remirror-editor-wrapper a {
    color: var(--blue-600);
  }

  .remirror-table p {
    font-size: var(--fontSize-14);
    line-height: 21px;
    color: var(--gray-600);
    margin-bottom: 0px;
  }

  td blockquote p {
    font-size: var(--fontSize-13);
    color: var(--gray-600);
    margin-bottom: 0;
    line-height: 1.25rem;
  }

  .remirror-table th p {
    font-family: var(--CheltenhamBold) !important;
    font-size: var(--fontSize-15) !important;
  }

  .remirror-table td p {
    font-size: var(--fontSize-15) !important;
  }

  .remirror-resizable-view img {
    padding: 0.375rem 0;
    padding-right: 1px; // To make the cursor visible
  }
}

.remirror-editor.ProseMirror .playlist-count p,
.remirror-editor.ProseMirror .playlist-duration p {
  font-size: var(--fontSize-12);
  font-weight: var(--fontSemiBold);
  margin: 0;
  color: var(--white-100);
}

.report-editor,
.project-overview-editor {
  &.remirror-theme .ProseMirror:not(.remirror-editor-live-notes .ProseMirror) {
    box-shadow: none;

    &:active,
    &:focus {
      box-shadow: none;
    }
  }
  p {
    span[inline-comment-id] {
      span {
        line-height: 24px;
        font-size: var(--fontSize-16);
        font-family: var(--Cheltenham);
        color: var(--gray-600);
      }
    }
  }

  .editor-container {
    height: calc(100vh - 34px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .editor-container.editable {
    height: calc(100vh - 64px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.text-analysis-viewer-editor {
  .editor-container {
    height: 100% !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .remirror-editor.ProseMirror {
    overflow-y: scroll !important;
  }
}

.insight-template-preview .report-editor .editor-container {
  height: calc(100vh - 160px);
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0 0 12px 12px;
}

.insight-template-preview
  .report-editor
  .remirror-editor.ProseMirror[contenteditable='false'] {
  padding: 72px 160px;
}

.research-area-doc-edit-mode .report-editor .editor-container,
.research-area-doc-read-mode .report-editor .editor-container {
  height: auto !important;
  width: 100% !important;
}

.report-container .report-editor {
  &.remirror-theme
    .ProseMirror:not(.ProseMirror .ProseMirror):not(
      .remirror-editor-live-notes .ProseMirror
    ) {
    padding: 34px 34px 100px 34px;
    width: 100%;
    @media screen and (max-width: 750px) {
      padding: 24px 24px 100px 24px;
    }
  }
}

.project-overview-editor {
  // callout of type success
  .remirror-editor.ProseMirror div[data-callout-type='success'] {
    border-left: 2px dotted var(--gray-200);
    border-radius: 0px;
    background-color: var(--white-100);
    padding: 10px 32px 0px;
    margin: 24px 0px;

    ul[data-task-list] {
      margin-bottom: 0px;
    }

    > div:first-child {
      > p {
        margin-bottom: 12px;

        strong {
          font-weight: var(--fontSemiBold);
        }
      }
    }
  }

  &.remirror-theme .ProseMirror:not(.remirror-editor-live-notes .ProseMirror) {
    padding: 64px 140px 100px 140px;
  }
}

.transcript-editor,
.text-editor {
  .flash-range-span {
    background-color: #fb356933 !important;
    position: relative;
    padding: 4px 0px !important;
  }
}

.transcript-editor,
.text-editor {
  .flash-sentence-range-span {
    color: var(--pink-500) !important;
    position: relative;
    mix-blend-mode: multiply;
    padding: 4px 0px !important;
  }
}

.pdf-inner-container {
  .flash-range-span {
    background-color: transparent !important;
    border: 1px solid var(--pink-500) !important;
    position: relative;
  }
}

.pdf-inner-container {
  .flash-sentence-range-span {
    background-color: transparent !important;
    border: 1px solid var(--pink-500) !important;
    position: relative;
  }
}


.citation-node-view-wrapper {
  .flyout-container > button {
    color: var(--white-100) !important;
    margin-bottom: 3px;
  }
  &.ProseMirror-selectednode {
    button {
      background-color: var(--gray-600);
    }
  }
}

// added styles for notes with markdown view content in insight report
#notes-markdown-view p {
  color: var(--gray-600) !important;
}

#notes-markdown-view p,
#notes-markdown-view ul,
#notes-markdown-view ul li,
#notes-markdown-view ol,
#notes-markdown-view ol li,
#notes-markdown-view li p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.dovetail-note-node-view-wrapper, .file-node-view-wrapper {
  &:hover {
    > div, > a {
      border-color: var(--gray-300);
    }
  }

  &.ProseMirror-selectednode {
    outline: none !important;

    > div, > a {
      border-color: var(--gray-300);
    }
  }
}

.report-editor,
.analyze-editor,
.project-overview-editor {
  &.remirror-theme .remirror-editor-wrapper {
    p,
    h1,
    h2,
    h3,
    h4,
    li,
    blockquote,
    td,
    th,
    div,
    span {
      font-family: var(
        --Cheltenham
      ); // default font family if not applied it is classis serif font

      &[data-font-family='Times New Roman'] {
        font-family: var(--fontTimes) !important;
      }

      &[data-font-family='Eina01-Regular'] {
        font-family: var(--Cheltenham) !important;
      }

      &[data-font-family='Eina01-SemiBold'] {
        font-weight: var(--fontSemiBold) !important;
        font-family: var(--CheltenhamBold) !important;
      }
      &[data-font-family='Eina01-Bold'] {
        font-weight: var(--fontBold) !important;
        font-family: var(--CheltenhamBold) !important;
      }

      &[data-font-family='Helvetica'] {
        font-family: var(--fontHelvetica) !important;
      }

      &[data-font-family='Courier'] {
        font-family: var(--fontCourier) !important;
      }

      &[data-font-family='Calibri'] {
        font-family: var(--fontCalibri) !important;
      }

      &[data-font-family='Arial'] {
        font-family: var(--fontArial) !important;
      }

      &[data-font-family='Comic Sans MS'] {
        font-family: var(--fontComic) !important;
      }

      &[data-font-family='Verdana'] {
        font-family: var(--fontVerdana) !important;
      }

      &[data-font-family='Poppins'] {
        font-family: var(--fontPoppins) !important;
      }
    }

    strong {
      font-weight: 700 !important;
      &[data-font-family='Times New Roman'] {
        font-family: var(--fontTimes) !important;
      }

      &[data-font-family='Helvetica'] {
        font-family: var(--fontHelvetica) !important;
      }

      &[data-font-family='Courier'] {
        font-family: var(--fontCourier) !important;
      }

      &[data-font-family='Calibri'] {
        font-family: var(--fontCalibri) !important;
      }

      &[data-font-family='Arial'] {
        font-family: var(--fontArial) !important;
      }

      &[data-font-family='Comic Sans MS'] {
        font-family: var(--fontComic) !important;
      }

      &[data-font-family='Verdana'] {
        font-family: var(--fontVerdana) !important;
      }

      &[data-font-family='Poppins'] {
        font-family: var(--fontPoppins) !important;
      }
    }

    em {
      font-style: italic !important;

      &[data-font-family='Times New Roman'] {
        font-family: var(--fontTimes) !important;
      }

      &[data-font-family='Helvetica'] {
        font-family: var(--fontHelvetica) !important;
      }

      &[data-font-family='Courier'] {
        font-family: var(--fontCourier) !important;
      }

      &[data-font-family='Calibri'] {
        font-family: var(--fontCalibri) !important;
      }

      &[data-font-family='Arial'] {
        font-family: var(--fontArial) !important;
      }

      &[data-font-family='Comic Sans MS'] {
        font-family: var(--fontComic) !important;
      }

      &[data-font-family='Verdana'] {
        font-family: var(--fontVerdana) !important;
      }

      &[data-font-family='Poppins'] {
        font-family: var(--fontPoppins) !important;
      }
    }

    ol > li {
      &:has(span[data-font-family='Times New Roman'])::before {
        font-family: var(--fontTimes) !important;
      }

      &:has(span[data-font-family='Helvetica'])::before {
        font-family: var(--fontHelvetica) !important;
      }

      &:has(span[data-font-family='Courier'])::before {
        font-family: var(--fontCourier) !important;
      }

      &:has(span[data-font-family='Calibri'])::before {
        font-family: var(--fontCalibri) !important;
      }

      &:has(span[data-font-family='Arial'])::before {
        font-family: var(--fontArial) !important;
      }

      &:has(span[data-font-family='Comic Sans MS'])::before {
        font-family: var(--fontComic) !important;
      }

      &:has(span[data-font-family='Verdana'])::before {
        font-family: var(--fontVerdana) !important;
      }

      &:has(span[data-font-family='Poppins'])::before {
        font-family: var(--fontPoppins) !important;
      }
    }
  }
}

.tag-author-info__text {
  p {
    margin-bottom: 0 !important;
  }
}
