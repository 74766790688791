.annotation-manage-note-card {
  .szh-menu__item:hover {
    background-color: unset;
  }

  .szh-menu__item {
    padding: 0px;
    border-radius: 0.5rem;
  }

  .szh-menu {
    padding: 0px;
    box-shadow: rgba(0, 0, 50, 0.25) 2px 4px 31px;
    border-radius: 0.5rem;
    border: 1px solid var(--gray-200);
    background-color: white;
  }
}
