.transcript-editor {
  .tp-node-view-content-wrapper {
    display: block;
  }

  .remirror-editor {
    min-height: 300px;
  }

  ::selection {
    background: var(--yellow-800);
  }

  .para-st::selection {
    background: transparent;
  }

  .spk-name::selection {
    background: transparent;
  }

  .remirror-editor.ProseMirror .tp-node-view-content-wrapper p {
    margin-bottom: 0px;
    min-height: 10px;
  }

  t-span,
  .tra-content,
  .tra-content *,
  .annotation-span,
  t-span span,
  .tp-node-view-wrapper p,
  .tp-node-view-wrapper p span,
  p {
    line-height: 22px;
    font-size: var(--fontSize-14);
    color: var(--gray-600);
    padding: 0px;
    margin: 0px;
    font-family: var(--Cheltenham);
    white-space: pre-wrap;
  }

  .tra-content {
    padding-left: 0.05px;
  }

  .low-confidence-subtitle {
    color: var(--gray-400);
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-skip-ink: none;
    text-decoration-color: var(--yellow-600);

    t-span,
    .annotation-span,
    t-span span {
      color: var(--gray-400);
    }
  }

  .search-class {
    color: var(--pink-500) !important;
  }

  .search-highlight-class {
    background-color: var(--yellow-500) !important;
  }
}

.annotation-span {
  position: relative;
}

.range-handle {
  white-space: normal;
  overflow-wrap: normal;
  cursor: ew-resize;
  position: relative;
  z-index: 1;
  line-height: 23px;
}

.range-handle-start::after {
  bottom: calc(-6px);
  box-shadow: var(--pink-500) 0px 0px 0px 50px inset;
  content: '';
  left: 0px;
  position: absolute;
  top: calc(-5px);
  width: 4px;
  z-index: 1;
}

.range-handle-start::before {
  content: '';
  display: block;
  background-color: var(--pink-500);
  width: 8px;
  height: 8px;
  position: absolute;
  left: -2px;
  top: -12px;
  border-radius: 100%;
}

.range-handle-end::after {
  bottom: calc(-6px);
  box-shadow: var(--pink-500) 0px 0px 0px 50px inset;
  content: '';
  left: 0px;
  position: absolute;
  top: -7px;
  width: 4px;
}

.range-handle-end::before {
  content: '';
  display: block;
  background-color: var(--pink-500);
  width: 8px;
  height: 8px;
  position: absolute;
  left: -2px;
  top: 19px;
  border-radius: 100%;
}

.speaker-container {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-flex-basis: 150px;
  -ms-flex-preferred-size: 150px;
  flex-basis: 150px;
  -webkit-box-flex: 0;
  -webkit-flex-grow: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: end;
  padding-right: 6px;
}

.speaker-para {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  color: var(--gray-600);
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0.9rem;
  margin-top: 0.1rem;
}

@media (max-width: 1200px) {
  .transcript-editor {
    padding-left: 0 !important;
    padding-right: 2rem !important;
    margin-left: 1rem;
    padding-top: 1rem !important;

    .tra-content {
      padding-left: 0.8rem;
    }
  }

  .speaker-container {
    -webkit-flex-basis: 44px;
    -ms-flex-preferred-size: 44px;
    flex-basis: 44px;
    text-align: left;
    padding-right: 6px;

    .para-st {
      left: 12px;
    }
  }

  .speaker-para {
    flex-direction: column;
    -webkit-box-align: flex-start;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .spk-name {
    max-width: 300px;
  }
}

.open-speaker-dropdown {
  background-color: var(--gray-50);

  & + .para-st {
    display: none;
  }
}
