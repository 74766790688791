.range-container {
  /* Removing the default appearance */
  .range-thumb,
  .range-thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .range-thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    z-index: 1;
  }

  .range-thumb::-webkit-slider-thumb {
    cursor: col-resize;
    pointer-events: all;
    position: relative;
    width: 2px;
    height: 45px;
    border-radius: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--white-100);
  }

  /* For Chrome browsers */
  .range-thumb.min-thumb::-webkit-slider-thumb {
    left: -1px;
  }

  .range-thumb.max-thumb::-webkit-slider-thumb {
    left: 1px;
  }

  /* For Firefox browsers */
  .range-thumb::-moz-range-thumb {
    cursor: col-resize;
    pointer-events: all;
    position: relative;
    width: 2px;
    height: 45px;
    border-radius: 0px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: var(--white-100);
  }

  .range-thumb.min-thumb::-moz-range-thumb {
    left: -1px;
  }

  .range-thumb.max-thumb::-moz-range-thumb {
    left: 1px;
  }
}
