.highlighted-text-search {
  color: var(--pink-highlight);
  background-color: white;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
}

.highlighted-text-search-tree {
  color: var(--pink-highlight);
  background-color: #fafafa;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
}

.unhighlighted-text-search-tree {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-13);
  text-transform: none;
  color: var(--gray-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
}

.highlighted-text-question-text {
  color: var(--pink-highlight);
  text-transform: none;
  font-size: var(--fontSize-13);
  font-weight: var(--fontSemiBold);
  background-color: transparent;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
  line-height: 20px;
}

.unhighlighted-text-question-text-for-question-filter {
  font-size: var(--fontSize-13);
  text-transform: none;
  color: var(--gray-500);
  font-weight: var(--fontSemiBold);
  line-height: 20px;
}

.unhighlighted-text-question-text {
  font-family: inherit;
  font-size: var(--fontSize-13);
  text-transform: none;
}

.highlighted-text-question-text.active,
.unhighlighted-text-question-text.active {
  font-weight: var(--fontSemiBold);
}

.highlighted-text-question-text.active.strike-through,
.unhighlighted-text-question-text.active.strike-through {
  text-decoration: line-through;
}

.highlighted-text-label-filter-text {
  color: var(--pink-highlight);
  text-transform: none;
  font-size: var(--fontSize-13);
  background-color: transparent;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
}

.unhighlighted-text-label-filter-text {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-12);
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
}

.unhighlighted-text-label-filter-text.active,
.highlighted-text-label-filter-text.active {
  font-weight: var(--fontSemiBold);
}

.highlighted-text-label-filter-description,
.unhighlighted-text-label-filter-description {
  color: var(--gray-400);
  font-size: var(--fontSize-11);
  text-transform: none;
  font-family: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  user-select: none;
}

.highlighted-text-label-filter-description {
  color: var(--pink-highlight);
  font-size: var(--fontSize-11);
  background-color: transparent;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
}

.highlighted-text-project-group-name {
  font-weight: var(--fontSemiBold);
  color: var(--pink-highlight);
  text-transform: none;
  font-size: var(--fontSize-13);
  background-color: transparent;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
}

.unhighlighted-text-project-group-name {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-13);
  text-transform: none;
  color: var(--gray-600);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.unhighlighted-checkbox-item {
  overflow: unset;
  text-overflow: unset;
  white-space: normal;
  word-wrap: break-word;
  line-height: 20px;
  font-size: var(--fontSize-13);
}

.highlighted-checkbox-item {
  background-color: transparent;
  /*  reset padding and margin  */
  padding: 0;
  margin: 0;
  color: var(--pink-highlight);
  overflow: unset;
  text-overflow: unset;
  white-space: normal;
  word-wrap: break-word;
  line-height: 20px;
  font-size: var(--fontSize-13);
}

.unhighlighted-survey-question-text,
.highlighted-survey-question-text {
  color: var(--darkGray);
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-13);
  max-width: 600px;
  line-height: 20px;
}

.highlighted-survey-question-text {
  color: var(--pink-highlight);
  background-color: transparent;
  padding: 0;
  margin: 0;
  line-height: 20px;
}

.highlighted-modal-search-text {
  color: var(--pink-500);
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: var(--fontSize-13);
}
.unhighlighted-modal-search-text {
  color: var(--grey-600);
  font-size: var(--fontSize-13);
}
