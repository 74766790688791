.ellipsis-video-container {
  position: absolute;
  width: 100%;
  height: calc(100% - 36px);
  z-index: 1;
  overflow: hidden;
  top: 0px;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-color: rgba(0, 0, 0, 0.3);
}

.react-lds-ellipsis {
  position: absolute;
  top: 50%;
  margin: auto;
  width: 80px;
  left: calc(50% - 40px);
}

.react-lds-ellipsis div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--white-100);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.react-lds-ellipsis.lds-ellipsis-pink {
  position: absolute;
  left: calc(50vw + 83px);
  top: calc(50vh + 20px);
}

.react-lds-ellipsis.lds-ellipsis-pink > div {
  background: #fb3569;
}

.react-lds-ellipsis.lds-ellipsis-upload {
  top: 30vh;
  margin: 0 auto;
}

.react-lds-ellipsis.lds-ellipsis-upload > div {
  background: #fb3569;
}

.react-lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.react-lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.react-lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.react-lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

:fullscreen #video-player-controls {
  position: absolute;
  bottom: 0;
}

:fullscreen video {
  height: unset;
  max-height: 100%;
}

:fullscreen #speed-options {
  top: -220px;
  z-index: 10;
}
