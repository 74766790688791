.questions-page-container {
  .fullscreen-modal {
    align-items: center;
  }

  .edit-confirmation-modal {
    max-width: 560px;
  }

  .szh-menu {
    padding: 0;
    border-radius: 0.5rem;
  }

  .szh-menu__item {
    display: flex;
    flex-direction: row;
    -webkit-box-pack: start;
    justify-content: flex-start;
    color: var(--gray-700);
    -webkit-box-align: center;
    align-items: center;
    padding: 0.5rem 1.7rem 0.5rem 1rem;
    width: 100%;
    font-size: var(--fontSize-12);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: none;
    position: relative;
  }

  .szh-menu__item:hover {
    color: var(--gray-700);
    text-decoration: none;
    background-color: var(--gray-50);
    cursor: pointer;
  }

  .szh-menu .szh-menu__item:nth-child(2):hover {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }

  .szh-menu .szh-menu__item:last-child:hover {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  .szh-menu__submenu > .szh-menu__item::after {
    display: none;
  }
}
