.live-notes-label-tree {
  .rst__lineBlock::after,
  .rst__lineBlock::before,
  .rst__lineChildren::after {
    background-color: var(--gray-100);
  }

  .rst__rowContentsDragDisabled {
    border-left: none;
  }

  .rst__rowContents {
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
    min-width: auto;
    width: 100%;
    border-radius: 0;
  }

  .rst__rowLabel {
    padding-right: 0;
  }

  .rst__collapseButton,
  .rst__expandButton,
  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    border: solid 1px var(--gray-100);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .rst__node {
    height: 46px !important;
    z-index: 0;
  }
}
