.event-page__container {
  min-height: max-content;
  width: 100%;
}

.event-page__content {
  border-radius: 8px;
  border: solid 1px var(--gray-100);
  background-color: var(--white-100);
  width: 93.87vw;
  max-width: 45.25rem;
  margin: 0.75rem auto;
}

@media screen and (min-width: 768px) {
  .event-page__content {
    margin: 2rem auto;
  }
}

.content__comment {
  display: flex;
  color: var(--gray-500);
}

.content__avatar--container {
  height: 45px;
  width: 45px;
}

.comment__container {
  padding: 0 16px;
}

.comment__container--tag {
  font-weight: var(--fontSemiBold);
}

.comment__container--description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.comment__container--author {
  font-size: 12px;
  margin-bottom: 0;
  color: var(--gray-400);
}

.event-page__video-player {
  width: 100%;
  height: 0px;
  padding-top: 56.25%;
  position: relative;
}

.video-player__video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--gray-600);
}

.video-player__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-overlay__btn--error {
  color: #ecf0f1;
  font-size: 14px;
}

.player-overlay__btn--loader {
  display: inline-block;
  position: relative;
  width: 80px;
  margin: auto;
}

.player-overlay__btn--loader div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--white-100);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.player-overlay__btn--loader div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.player-overlay__btn--loader div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.player-overlay__btn--loader div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.player-overlay__btn--loader div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.player-overlay__btn {
  height: 18%;
  max-height: 80px;
  border-radius: 50%;
  cursor: pointer;
}

.content__transcript {
  font-size: 12px;
  color: var(--gray-500);
}

.transcript__title {
  font-size: var(--fontSize-14);
  color: var(--gray-600);
  font-weight: var(--fontSemiBold);
}

@media screen and (min-width: 900px) {
  .transcript__title {
    color: var(--pink-500);
  }

  .transcript__title:hover {
    color: var(--pink-500);
  }
}

.transcript__srt {
  line-height: 1.83;
  color: var(--gray-400);
  margin-top: -10px;
}

/* ANIMATIONS */

.anim-fade-out {
  -webkit-animation: fadeOut 1.5s ease-in 0.5s both;
  animation: fadeOut 1.5s ease-in 0.5s both;
}

.subtitle-speaker {
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  color: var(--gray-500);
  margin: 16px 0 4px;
}

.subtitle-text {
  color: var(--gray-300);
}

.subtitle-text em {
  font-style: normal;
  color: var(--gray-500);
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.show-subs-before,
.show-subs-after {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 132px;
  height: 19px;
  color: #fb3569;
  cursor: pointer;
}

.show-subs-before-img {
  height: 14px;
}

.show-subs-after-img {
  height: 14px;
}

.show-subs-before {
  margin-bottom: -4px;
  font-size: 12px;
}

.show-subs-after {
  margin-top: 12px;
  font-size: 12px;
}

.rotate-180 {
  transform: rotate(180deg);
}
