:root {
  // From JSpreadsheet
  --header_color: var(--black);
  --header_color_highlighted: var(--black);
  --header_background: var(--gray-50);
  --header_background_highlighted: var(--gray-100);
  --content_color: var(--gray-600);
  --content_color_highlighted: var(--gray-700);
  --content_background: var(--white-100);
  --content_background_highlighted: rgba(0, 0, 0, 0.05);
  --menu_background: var(--white-100);
  --menu_background_highlighted: #ebebeb;
  --menu_color: #555;
  --menu_color_highlighted: #555;
  --menu_box_shadow: 2px 2px 2px 0px rgba(143, 144, 145, 1);
  --border_color: var(--gray-200);
  --border_color_highlighted: var(--gray-500);
  --cursor: var(--gray-50);
  --active_color: var(--pink-500);

  //Custom
  --cell-with-note-bg-color: var(--pink-200);
}

$search-width: 10vw;

.jss_container {
  position: relative;

  // Toolbar Overrides
  .jss_toolbar {
    background-color: var(--white-100);
    border: none;
    padding: 12px 20px;
    cursor: default;

    // For search; Toolbar padding (20px) and Input margin (12px)
    padding-left: calc($search-width + 32px);

    > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .jtoolbar-item:not(.jtoolbar-divisor) {
      padding: 4px;
      border-radius: 4px;
      margin: 0;
      cursor: pointer;

      i {
        width: unset;
        min-width: 24px;
        font-style: normal;

        &:hover {
          background-color: unset;
        }
      }

      &:hover {
        background-color: var(--gray-50);
      }

      &.jss_toolbar_selected {
        background-color: var(--pink-200);

        img {
          filter: brightness(0) saturate(100%) invert(37%) sepia(53%)
            saturate(5061%) hue-rotate(324deg) brightness(99%) contrast(99%);
        }

        &:hover {
          background-color: var(--pink-200);
        }
      }

      // Dropdown
      .jpicker-header {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="5" viewBox="0 0 8 5" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.6313 1.08753L3.99615 3.16992L6.3754 1.08753C6.68915 0.806545 7.22487 1.26752 6.89792 1.54901L4.21872 3.92388C4.10114 4.02537 3.90497 4.02537 3.80058 3.92388L1.09498 1.54901C0.781826 1.26802 1.31695 0.806546 1.6313 1.08753V1.08753Z" fill="%23505C95" stroke="%23505C95" stroke-width="0.4"/></svg>');
        background-position: top 50% right 6px;
        padding-right: 10px;
        padding-left: 4px;

        > span {
          pointer-events: none;
        }

        &:hover {
          background-color: transparent !important;
        }
      }

      .jpicker-content {
        gap: 4px;
        padding: 6px;
        left: 0;
        top: 8px;
        border-radius: 0.5rem;
        box-shadow: 0px 0px 10px rgba(0, 0, 50, 0.09);
        border: thin solid var(--gray-100);

        .jpicker-item {
          padding: 0;
          border-radius: 4px;

          > span {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            &:hover {
              background-color: var(--gray-50);
            }
          }
        }
      }

      &.jpicker-focus > .jpicker-content {
        display: flex;
        flex-direction: row;
      }

      // Colorpicker
      .jcolor {
        .jcolor-content {
          border-radius: 0.5rem;
          box-shadow: 2px 4px 31px rgba(0, 0, 50, 0.16);
          border: thin solid var(--gray-100);

          .jcolor-controls {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 6px;
            position: relative;

            &::before {
              content: 'Select color';
              position: absolute;
              left: 16px;
              top: 12px;
              font-size: var(--fontSize-13);
              font-weight: var(--fontSemiBold);
              color: var(--gray-500);
            }

            > div {
              flex: 0;
              font-size: var(--fontSize-12);
              font-weight: var(--fontSemiBold);
              text-transform: capitalize;
            }

            .jcolor-reset {
              padding: 2px 8px;
              color: var(--pink-500);

              &:hover {
                color: var(--pink-600);
              }
            }
            .jcolor-close {
              color: var(--white-100);
              background-color: var(--pink-500);
              border-radius: 32px;
              padding: 2px 16px;
              &:hover {
                background-color: var(--pink-600);
              }
            }
          }

          .jtabs-headers-container {
            .jtabs-headers {
              color: var(--gray-600);
              background-color: var(--white-100) !important;
              border: 1px solid var(--gray-100);
              border-radius: 18px;
              gap: 0.25rem;
              padding: 5px;

              div:not(.jtabs-border) {
                line-height: 1.5;
                text-align: center;
                font-weight: var(--fontSemiBold);
                font-size: var(--fontSize-12);
                color: var(--gray-500);
                border: none;
                background-color: transparent;
                padding: 3px 10px !important;
                margin: 0;
                border-radius: 18px;
                z-index: 1;
                transition: color 0.3s ease-in-out;

                &.jtabs-selected {
                  color: var(--white-100);
                }
              }

              .jtabs-border {
                height: 22px;
                border-radius: 18px;
                background-color: var(--pink-500);
                bottom: 6px !important;
                z-index: 0;
              }
            }
          }

          .jcolor-grid {
            td {
              padding: 8px;
            }
          }

          .jcolor-hsl canvas,
          .jcolor-sliders {
            width: 272px;
          }

          .jcolor-sliders {
            label {
              color: var(--gray-600);
              margin-bottom: 0;
            }
          }
        }
      }

      &[title='Text color'] {
        .jcolor .jcolor-controls {
          &::before {
            content: 'Select text color';
          }
        }
      }

      &[title='Fill color'] {
        .jcolor .jcolor-controls {
          &::before {
            content: 'Select fill color';
          }
        }
      }
    }

    .jtoolbar-divisor {
      width: 1px;
      height: 32px;
      margin-left: 8px;
      margin-right: 8px;
      background-color: var(--gray-100);
    }
  }

  // Spreadsheet Overrides
  .jss_content {
    border-top: 1px solid var(--gray-200);
  }

  .jss_selectall {
    &:after {
      border-bottom: 12px solid var(--gray-300);
    }
  }

  .jss_freeze_control_row {
    border-bottom: 1px solid var(--gray-300) !important;
  }

  .jss_freeze_control_col {
    border-right: 1px solid var(--gray-300) !important;
  }

  .jss thead .jss_frozen {
    box-shadow: 2px 0 0 0 var(--gray-300);
  }

  .jss tbody .jss_frozen {
    box-shadow: 1px 1px 2px 0 var(--gray-300);
  }

  .jss > thead > tr > th,
  .jss > tbody > tr > .jss_row {
    font-size: var(--fontSize-11);
    color: var(--gray-500);
  }

  .jss > tbody > tr > .jss_row {
    border-left: transparent;
  }

  .jss_content_overflow {
    border-right-color: var(--gray-200);
    border-bottom-color: var(--gray-200);
    overscroll-behavior: contain;
  }

  // Cell with comment
  .jss > tbody > tr > td[title] {
    background-color: var(--cell-with-note-bg-color);
  }

  // Highlighted cell
  .jss > tbody > tr > td.jss-highlighted-cell {
    background-color: var(--yellow-300) !important;
  }

  .jss > tbody > tr > td.jss-highlighted-cell--search {
    background-color: var(--yellow-50) !important;
  }

  // Context Menu overrides
  .jss_contextmenu {
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 50, 0.09);
    border: thin solid var(--gray-100);
    hr {
      border-color: var(--gray-50);
      &:last-child,
      &:nth-last-child(2) {
        display: none;
      }
    }

    div {
      width: auto;
      min-width: 200px;
      padding: 8px 16px;

      &:hover {
        background: var(--gray-50) !important;
      }

      a {
        color: var(--gray-600);
        font-size: var(--fontSize-13);
      }
      span {
        color: var(--gray-500);
      }
    }
  }

  // Search overrides
  .jss_search_container {
    background-color: var(--white-100);
    border: none;

    // Workaround to show search in toolbar
    position: absolute;
    top: 13px;
    left: 20px;
    z-index: 5;

    div {
      padding: 0;
      font-size: 0; // To hide the search label
      display: flex;
      position: relative;

      // Search Icon
      &::before {
        content: '';
        position: absolute;
        left: 12px;
        top: 10px;
        width: 12px;
        height: 12px;
        background-image: url("data:image/svg+xml,%3Csvg width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.981 10.962C3.23432 10.962 1 8.72746 1 5.981C1 3.23432 3.23453 1 5.981 1C8.72746 1 10.962 3.23453 10.962 5.981C10.962 8.7277 8.72746 10.962 5.981 10.962ZM5.981 1.41861C3.46518 1.41861 1.41861 3.46539 1.41861 5.981C1.41861 8.49664 3.46518 10.5434 5.981 10.5434C8.49682 10.5434 10.5434 8.49682 10.5434 5.981C10.5434 3.46518 8.49664 1.41861 5.981 1.41861Z' fill='%23505C95' stroke='%238B95C6' stroke-width='0.4'/%3E%3Cpath d='M12.1944 13.0002C12.1409 13.0002 12.0873 12.9797 12.0465 12.9389L8.9149 9.80751C8.83306 9.72567 8.83306 9.59337 8.9149 9.51153C8.99674 9.42975 9.12898 9.42975 9.21082 9.51153L12.3422 12.6429C12.4241 12.7248 12.4241 12.8571 12.3422 12.9389C12.3014 12.98 12.248 13.0002 12.1944 13.0002Z' fill='%23505C95' stroke='%238B95C6' stroke-width='0.4'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        pointer-events: none;
      }

      .jss_search {
        background-color: var(--white-100);
        padding: 3px 12px;
        border-radius: 32px;
        width: $search-width;
        font-size: var(--fontSize-12);
        border: thin solid var(--gray-100);

        &::placeholder {
          font-size: var(--fontSize-12);
          color: var(--gray-400);
        }

        // For the search icon
        padding-left: 28px;
      }
    }
  }

  // Tabs overrides
  > .jtabs-headers-container {
    // To add scroll hint for sheet tabs
    &::after {
      content: '';
      width: 80px;
      height: 36px;
      position: absolute;
      right: 0;
      top: 0;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgb(255, 255, 255) 100%
      );
      opacity: 0.8;
      z-index: 1;
      pointer-events: none;
    }

    // To hide the hint when scrolled to the end
    &.jtabs-headers-container--end {
      &::after {
        display: none;
      }
    }

    .jtabs-headers {
      padding: 12px 20px 0px;
      gap: 4px;
      overflow: auto;

      // To handle overflow when there are lot of sheets
      max-width: calc(100vw - 420px - 72px);

      // Hide scrollbar
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      > div:not(.jtabs-border) {
        font-size: var(--fontSize-11);
        border-radius: 12px;
        padding: 4px 10px;
        line-height: 16px;
        margin: 0;
        background-color: transparent;
        z-index: 1;
        color: var(--gray-500);
        transition: all 0.3s ease-in-out;

        &:hover {
          color: var(--gray-600);
        }

        &.jtabs-selected {
          font-weight: var(--fontSemiBold);
        }
      }

      .jtabs-border {
        height: 24px;
        border-radius: 12px;
        background-color: var(--gray-50);
        z-index: 0;
      }
    }

    .jtabs-controls {
      display: none;
    }
  }

  // To change the search position when there are multiple sheets/sheets tabs
  &.with-multiple-sheets {
    .jss_search_container {
      top: 49px;
    }
  }

  &.jss_container--expanded {
    .jtabs-headers-container {
      .jtabs-headers {
        max-width: calc(100vw - 72px);
      }
    }
  }

  // Cell highlight overrides
  .jss_border {
    background-color: #8b95c640; // var(--gray-400) with 25% opacity
  }
  .jss_border.jss_border_main {
    background-color: #8b95c640; // var(--gray-400) with 25% opacity
  }

  // Remove underline on sort
  .arrow-up,
  .arrow-down {
    text-decoration: none;
  }
}

// Hide loader
.jloading {
  display: none;
}

// Add default font family for spreadsheet when the imported spreadsheet doesn't have any font family associated with it.
// Otherwise, the table will have Marvin's font and the bold property won't work since we use font family for weights
.jss > tbody > tr > td {
  font-family: 'Arial';
}

// Override cell input font family
.jss_input {
  font-family: 'Arial';
}

// Absolute positioning the create note button
.jss-custom-toolbar-items {
  position: absolute;
  top: 13px;
  right: 20px;
  z-index: 6;
  gap: 8px;
}

// To update position when there are multiple sheets
.jss_container.with-multiple-sheets ~ .jss-custom-toolbar-items {
  top: 49px;
}
