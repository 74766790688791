:root {
  --playlist-note-card-shadow: 0px 2px 16px 0px rgba(0, 0, 50, 0.11);
}

@keyframes scaleIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.playlist-completion-modal {
  max-width: min(632px, 80%) !important;

  .modal-content {
    border-radius: 12px;
    animation: scaleIn 0.3s ease-in;
    transform-origin: center;
  }
}
