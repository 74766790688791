.pdf-container-box * {
  padding: 0;
  margin: 0;
}

.pdf-container-box {

  body,
  input,
  button,
  select {
    font: message-box;
    outline: none;
    scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg-color);
  }
}

html .pdf-analysis .remirror-theme:not(:has(.remirror-editor-live-notes)) .ProseMirror * {
  font-family: sans-serif;
  line-height: normal;
}

body .pdf-analysis .remirror-theme:not(:has(.remirror-editor-live-notes)) .ProseMirror {
  margin: 0;
  font-family: Segoe UI, Tahoma, sans-serif;
}

.pdf-analysis .row {
  width: 100%;
  min-width: 1364px;
  margin-left: 0px;
  height: 100%;
}

.pdf-analysis.pdf-viewer .row {
  min-width: inherit;
}

.pdf-analysis .remirror-editor.ProseMirror:not(.remirror-editor-live-notes .remirror-editor.ProseMirror) {
  bottom: 7px;
  left: 0;
  line-height: 1;
  overflow: hidden;
  position: absolute !important;
  right: 0;
  top: 0;
}

.pdf-analysis .remirror-editor.ProseMirror:not(.remirror-editor-live-notes .remirror-editor.ProseMirror) {
  overflow-y: hidden !important;
}


.pdf-analysis .remirror-theme:not(:has(.remirror-editor-live-notes)) .ProseMirror {
  padding: 0;
  width: 100%;
  max-width: unset;
  margin: inherit;
  background-color: transparent;
}

.pdf-analysis .remirror-theme:not(:has(.remirror-editor-live-notes)) .remirror-editor-wrapper {
  min-height: 0 !important;
  height: 0 !important;
  margin-bottom: 0 !important;
}


.pdf-analysis .remirror-theme {
  display: flex;
  gap: 1rem;
  flex-grow: 1;
  height: 100%;
}

.pdf-container-box {
  height: 100%;

  .pdf-container {
    position: absolute;
    text-align: initial;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    forced-color-adjust: none;
    box-sizing: border-box;
    filter: drop-shadow(2px 4px 11px rgba(0, 0, 50, 0.11));
  }

  .pdf-span {
    color: transparent;
    position: absolute;
    white-space: pre;
    cursor: text;
    transform-origin: 0 0;
    padding-right: 20px;
  }

  .pdf-container {
    opacity: 1;
  }

  .pdf-span span {
    font-size: inherit;
    font-family: inherit;
    color: transparent;
    transform-origin: 0 0;
  }

  .remirror-editor.ProseMirror ::selection,
  .remirror-editor.ProseMirror .selection {
    background-color: #ffe9a885;
    color: transparent;
  }

  .remirror-editor.ProseMirror p.pdf-inner-container {
    margin: 0;
  }

}
