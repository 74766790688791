#interview-processing-section {
  .progress {
    width: 400px;
    margin-bottom: 42px;
  }

  .progress-bar.bg-marvin {
    background-color: var(--pink-500);
  }
}
