.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
  color: transparent;
  user-select: none;
}

.shimmer-text {
  width: 100%;
  height: 28px;
  border-radius: 4px;
}

@keyframes shimmer {
  0% {
     background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.file-name {
  width: 240px;
  vertical-align: unset;
}

.shimmer-tiny-text {
  width: 600px;
  height: 6px;
  margin-top: 3px;
}
.shimmer-small-text {
  width: 200px;
  height: 12px;
  margin-top: 3px;
}
.shimmer-big-text {
  width: 200px;
}
.shimmer-avatar {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  margin-right: 6px;
}

.shimmer-full{
  width:100%;
}

/** file shimmer icons */
.shimmer-file-title{
  height:20px;
}

.shimmer-file-icon{
  width:32px;
  height:32px;
}

.shimmer-file-detail{
  width:100px;
  height:20px;
  margin-right:20px;
  display:inline-block;
}
