:root {
  --active-nav-link-bg: linear-gradient(90deg, #ffe5ec 0.02%, #e4eaff 99.95%);
  --fontFamily: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Helvetica, Arial, sans-serif;
  --fontNewsreaderMedium: 'Newsreader-Medium';
  --fontNewsreaderItalic: 'Newsreader-Italic';
  --CheltenhamBold: 'CheltenhamITCBoldBT';
  --CheltenhamBoldItalic: 'CheltenhamITCBoldItalicBT';
  --Cheltenham: 'CheltenhamITCBookBT';
  --CheltenhamItalic: 'CheltenhamITCBookItalicBT';
  --fontTimes: 'Times New Roman', Times, serif;
  --fontHelvetica: 'Helvetica', 'Helvetica Neue', Arial, sans-serif;
  --fontCourier: 'Courier New', Courier, monospace;
  --fontCalibri: 'Calibri', 'Segoe UI', 'Trebuchet MS', sans-serif;
  --fontArial: 'Arial', 'Helvetica Neue', Helvetica, sans-serif;
  --fontComic: 'Comic Sans MS', 'Comic Sans', cursive;
  --fontVerdana: 'Verdana', Geneva, Tahoma, sans-serif;

  --fontNormal: 400;
  --fontSemiBold: 500;
  --fontBold: 600;
  --fontBlack: 900;

  --fontSize-10: 10px;
  --fontSize-11: 11px;
  --fontSize-12: 12px;
  --fontSize-13: 13px;
  --fontSize-14: 14px;
  --fontSize-15: 15px;
  --fontSize-16: 16px;
  --fontSize-18: 18px;
  --fontSize-20: 20px;
  --fontSize-22: 22px;
  --fontSize-24: 24px;
  --fontSize-26: 26px;
  --fontSize-28: 28px;
  --fontSize-30: 30px;
  --fontSize-32: 32px;
  --fontSize-36: 36px;
  --fontSize-40: 40px;
  --fontSize-42: 42px;

  --gray-25: #F4F7FF;
  --gray-50: #ecf0fb;
  --gray-100: #dce1f1;
  --gray-200: #c0c9e2;
  --gray-300: #adbbe3;
  --gray-400: #8b95c6;
  --gray-500: #505c95;
  --gray-600: #1c275c;
  --gray-700: #000B29;
  --gray-background: #ecf0fb;

  --pink-50: #ffebf0;
  --pink-100: #fff0ed;
  --pink-150: #ffeeee;
  --pink-200: #fff5f8;
  --pink-250: #fee3f0;
  --pink-300: #fec2d2;
  --pink-350: #f5c6cb;
  --pink-400: #f9005e;
  --pink-500: #fb3569;
  --pink-600: #db0053;
  --pink-highlight: #ff5678;
  --pink-disabled: #fec2d2;

  /* nylas composer global style start */
  --composer-primary-color: #fb3569;
  --composer-shadow: none;
  --composer-primary-light-color: #ecf0fb;
  --composer-text-secondary-color: #1c275c;
  --composer-primary-dark-color: #fb3569;
  /* nylas composer global style end */

  --white-100: #ffffff;
  --white-200: #f9f9f9;
  --white-300: #fbfbfb;

  --green-25: #e6f7ee;
  --green-50: #dbf6e9;
  --green-75: #a0e6d0;
  --green-100: #65d6ce;
  --green-200: #21c59f;
  --green-300: #20bf9b;

  --red-25: #f0caca;
  --red-50: #eb5757;
  --red-100: #f05945;
  --red-200: #ff5757;
  --red-300: #ff2727;
  --red-400: #eb5757;

  --blue-50: #f7edff;
  --blue-100: #e4f9ff;
  --blue-200: #6378a2;
  --blue-300: #6e98f3;
  --blue-350: #4c6fb6;
  --blue-400: #7d92ff;
  --blue-450: #2f80ed;
  --blue-500: #384f8a;
  --blue-600: #2e549e;
  --blue-700: #000245;
  --blue-800: #3856ab;
  --blue-900: #000b29;

  --yellow-highlight: #fcf8e3;
  --yellow-50: #fff6db;
  --yellow-100: #fff4c3; /* selected row color from theme.js */
  --yellow-200: #fff1c8;
  --yellow-300: #fff3d1;
  --yellow-350: #fffcf3;
  --yellow-400: #f8f9dd;
  --yellow-500: #ffe9a885;
  --yellow-600: #ffd000;
  --yellow-700: #ffc92f;
  --yellow-800: #ffe501;

  --orange-100: #ff7e67; /* error color from theme.js */
  --orange-200: #eb5757;

  --cream-100: #f9f7f5;
  --cream-200: #f3f1ec;
  --cream-500: #9c9996;

  --black-600: #000000;
  --black: #000;

  --purple-50: #D2CAF1;
  --purple-100: #e8ecff;
  --purple-200: #6E7ED3;
  --purple-300: #6576CF;
  --purple-400: #5E63EC;
  --purple-500: #4E53C1;

  --chat-bg-color: #EDE9FF;

  --fullCircleRadius: 9999px;

  --mediumShadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);

  --boxShadow: 0px 2px 8px 0px rgba(0, 0, 50, 0.06);
  --boxShadow-hover: 0px 2px 16px 0px rgba(0, 0, 50, 0.15);

  --boxShadow-lg: 1px 2px 18px 0px rgba(0, 0, 50, 0.08);

  --ask-ai-gradient-bg: linear-gradient(90deg, #FFECF1 0.02%, #EBF2FF 50.49%, #F2FFFA 99.95%);
}
