$modal-footer-height: 88px;

.modal.ai-interviewer-form-modal {
  .modal-content {
    background-color: transparent;
  }

  .modal-body {
    position: relative;
    height: 100vh;
    padding: 64px;
    padding-bottom: calc(64px + $modal-footer-height);
    scrollbar-gutter: stable;
  }
}

.ai-interviewer-form-modal__backdrop {
  background-color: var(--gray-50);

  &.show {
    opacity: 1;
  }
}
