/* TODO: UF-1970  (remove code for bootstrap v5 css) */

/* bootstrap v5 css ----- start */
html,
body,
#__next {
  width: 100%;
  height: 100%;
  margin: 0;
}

.discard-changes-modal {
  width: 456px;
}

.label-tree-preview-modal-container {
  max-width: 800px;
}

.admin-settings-newsletter-modal-container {
  max-width: 532px !important;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
  overflow: hidden;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
  overflow: auto;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

.modal-backdrop ~ .modal-backdrop {
  z-index: 1501;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}

.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: '';
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

/* bootstrap v5 css ----- end */

.down-caret {
  filter: invert(14%) sepia(92%) saturate(6723%) hue-rotate(357deg)
    brightness(111%) contrast(123%);
}

.has-search .form-control-feedback {
  height: 32px;
  line-height: 2rem;
}

.has-search .form-control {
  width: 100%;
  height: 32px;
  border-radius: 2px;
}

.filter-label-disabled {
  color: var(--gray-300) !important;
  cursor: not-allowed;
}

.filter-label-disabled span {
  color: var(--gray-300) !important;
  cursor: not-allowed;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.tag-modal {
  height: 100%;
  overflow: auto;
  background-color: #ecf0fb;
}

.tag-modal .lds-ellipsis.lds-ellipsis-pink {
  left: 50vw;
}

@media (min-width: 1024px) {
  .card-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (min-width: 1366px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
  }
}

@media (min-width: 1920px) {
  .card-columns {
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
  }
}

@media (min-width: 2560px) {
  .card-columns {
    -webkit-column-count: 5;
    -moz-column-count: 5;
    column-count: 5;
  }
}

.board-modal {
  width: 500px;
}

.medium-centered-modal,
.edit-tag-modal,
.create-playlist-modal,
.create-insight-modal,
.create-miroboard-modal {
  width: 524px;
}

.medium-centered-modal .modal-content,
.edit-tag-modal .modal-content,
.create-playlist-modal .modal-content,
.create-insight-modal .modal-content {
  height: auto;
  min-height: 438px;
  padding: 40px;
}

.meeting-joining-modal .modal-content {
  height: auto;
  padding: 40px;
}

.create-miroboard-modal .modal-content {
  height: auto;
  padding: 40px;
}

.project-share-modal {
  background-color: var(--gray-50);
}

.meeting-joining-modal {
  width: 682px;
  height: 600px;
}

.zoom-import-modal .modal-content,
.gong-modal .modal-content {
  height: 600px;
  background-color: var(--gray-50);
  padding: 2rem 0 0 0;
}

.gong-modal .modal-content {
  height: 690px;
  padding: 0;
  overflow: hidden;
}

.gong-modal .modal-dialog {
  min-width: 90vw;
  width: 90vw;
}
.gong-modal .modal-content {
  max-width: 1024px;
}

.manage-tag-group-modal .modal-content, .merge-tag-groups-modal .modal-content {
  align-items: center;
}

.manage-tag-group-modal .modal-header {
  border: 1px solid var(--gray-100);
  width: 37.5rem;
  margin-top: 5rem;
}

.merge-tags-modal .modal-header {
  border-bottom: 1px solid var(--gray-100) !important;
}

.merge-tag-groups-modal .modal-footer {
  border-top: 1px solid var(--gray-background);
  box-shadow: 0px -1px 20px 0px rgba(0, 0, 50, 0.05);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: var(--white-100) !important;
}

.insight-chart-modal .modal-content {
  height: 90vh;
  padding: 1rem 0 0 0;
  overflow: auto;
  max-width: 90vw;
}

.insight-chart-modal .modal-dialog {
  min-width: 90vw;
  width: 90vw;
}

.notion-modal .modal-content {
  background-color: var(--gray-50);
  width: 560px;
  padding: 2.5rem 0 0 0;
}

.tag-description__without-clamp {
  height: auto;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.6;
  font-weight: var(--fontNormal);
  white-space: pre-wrap;
  word-break: break-word;
}

.create-playlist__spinner {
  position: absolute;
  left: 68px;
  top: 15px;
  width: 12px;
  height: 12px;
  margin-right: 13px;
  border-color: var(--white-100);
  border-right-color: transparent;
}

.create-spreadsheet__spinner {
  width: 25px;
  height: 25px;
  border-color: #ff5678;
  border-right-color: transparent;
  margin-left: 25px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 28px;
  height: 18px;
  margin-right: 12px;
  margin-bottom: 6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-200);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.15);
}

input:checked + .slider {
  background-color: #fb3569;
}

input:checked:disabled + .slider {
  background-color: var(--pink-300);
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

.change-speed-menu__chevron {
  filter: invert(100%) sepia(4%) saturate(15%) hue-rotate(78deg)
    brightness(103%) contrast(106%);
  margin-top: 4px;
  margin-left: 6px;
}
q {
  quotes: '\201c''\201d';
}
q:before {
  content: open-quote;
}
q:after {
  content: close-quote;
}

.more-options_action-row {
  display: flex;
  align-items: center;
  gap: 0.125rem;
  width: 100%;
  padding: 6px 12px 6px 8px;
}

.more-options_action-row:hover {
  background-color: #fafafa;
}

.more-options-row-text-insights {
  padding-top: 4px;
}

.tag-buttons__button--more-options:hover {
  background-color: #fff0ed;
}

.displayMessage {
  display: flex;
  align-items: center;
  gap: 15px;
}

.fullscreen-modal {
  height: 100%;
  display: flex;
  align-items: center;
  overflow-y: auto;
}

.information-tooltip-text {
  color: var(--white-100);
}

.min-h-100 {
  min-height: 100%;
}

.full-height {
  height: 100%;
}

.research-area-doc-edit-mode.modal-semi-fullscreen{
  width: 90%;
}

@media (min-width: 576px) {
  .modal-dialog:not(.modal-fullscreen):not(.modal-xl):not(.modal-lg):not(
      .modal-sm
    ):not(.modal-semi-fullscreen) {
    max-width: 632px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    max-width: 800px;
  }
}

@media (min-width: 993px) {
  .modal-xl {
    max-width: 1024px;
  }
}

.modal .modal-semi-fullscreen {
  max-width: 1180px;
}

@media (min-width: 576px) {
  .modal-dialog.modal-sm {
    max-width: 520px;
  }
}

.modal-backdrop {
  z-index: 1499;
}

.modal {
  padding: 0 !important;
  z-index: 1501;
}

.modal-dialog {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.modal-content {
  border-radius: 16px;
}

.modal-100h {
  min-height: calc(100vh - 4rem);
}

.highlighted-text-search-project {
  color: var(--pink-400);
  background-color: transparent;
}

.unhighlighted-text-search-project > * {
  font-weight: var(--fontSemiBold);
  line-height: 14px;
  margin-bottom: 6px;
  padding: 0;
}

.szh-menu {
  padding: 0 !important;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 50, 0.09);
  border: solid 1px var(--gray-100);
  z-index: 1001;
}

.szh-menu__item:first-child {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.szh-menu__item:last-child {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.szh-menu__item {
  display: flex;
  flex-direction: row;
  -webkit-box-pack: start;
  justify-content: flex-start;
  color: var(--gray-700);
  -webkit-box-align: center;
  align-items: center;
  padding: 10px 15px;
  width: 100%;
  font-size: var(--fontSize-12);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: none;
  position: relative;
}

.szh-menu__item:hover {
  color: var(--gray-700);
  text-decoration: none;
  background-color: var(--gray-50);
  cursor: pointer;
}

.szh-menu .szh-menu__item:nth-child(2):hover {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.szh-menu .szh-menu__item:last-child:hover {
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.szh-menu__submenu > .szh-menu__item::after {
  display: none;
}

.access-control-locked {
  box-sizing: content-box;
  position: relative;
}

.access-control-disabled {
  position: relative;
}

.access-control-disabled > *,
.access-control-locked > * {
  opacity: 0.5 !important;
}

.disabled-locked-menu-item {
  cursor: not-allowed !important;
}

.disabled-locked-menu-item > * {
  opacity: 0.5 !important;
  pointer-events: none;
}

.menu-lock-icon,
.access-control-lock {
  opacity: 1 !important;
}

.notes-list.ReactVirtualized__List,
.notes-list .ReactVirtualized__Grid__innerScrollContainer {
  overflow: unset !important;
}

.draggable-note {
  margin: 0 12px;
  margin-bottom: -16px;
  padding-bottom: 8px;
}

.highlighted-words {
  font-weight: var(--fontSemiBold);
  font-size: inherit;
  line-height: inherit;
  color: #fb3569;
  padding: 0;
}

.underlined-words {
  text-decoration: underline;
}

.layout .react-grid-item img {
  pointer-events: auto;
}

.popover .popover-arrow {
  display: none;
}

.popover {
  border-radius: 0.5rem;
  box-shadow: 2px 4px 31px rgba(0, 0, 50, 0.16);
  border-color: var(--gray-200);
  max-width: inherit;
}

.popover-body {
  padding: 0;
}

.bs-popover-auto[x-placement^='bottom'],
.bs-popover-bottom {
  margin-top: 0;
}

.d3-tooltip {
  position: absolute;
  background: var(--white-100);
  border-radius: 0.5rem;
  pointer-events: none;
}

::cue {
  font-size: 14px;
  color: var(--white-100);
  background-color: var(--gray-300);
  line-height: 21px;
}

/* Research Area Styles */

.research-area-tree-row .research-area-overflow-btn {
  display: none;
}

.research-area-tree-row:hover .ra-drag-handle {
  visibility: visible;
}

.research-area-highlighted-title {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-12);
  line-height: 16px;
  color: var(--pink-500);
  padding: 0;
  background: none;
}

.research-area-non-highlighted-title {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-12);
  line-height: 16px;
  color: var(--gray-500);
}

.research-area-active-non-highlighted-title {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-12);
  line-height: 16px;
  color: var(--gray-600);
}

.research-area-not-used-non-highlighted-title {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-12);
  line-height: 16px;
  color: var(--gray-500);
}

.inverted-border-radius {
  position: relative;
  background-color: var(--white-100);
  border-bottom: 1px solid var(--gray-100);
}

.inverted-border-radius::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -50px;
  border-top: 1px solid var(--gray-100);
  left: 0;
  height: 50px;
  width: 25px;
  border-top-left-radius: 18px;
  box-shadow: 0 -25px 0 0 var(--white-100);
}

@media screen and (min-width: 900px) {
  .research-area-not-used-non-highlighted-title {
    color: var(--gray-400);
  }
}

.rstcustom__collapseButton::after {
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-width: 6px;
  border-bottom-width: 4px;
  top: 19px;
  border-top-color: var(--gray-500);
}

.rstcustom__expandButton::after {
  border-left-width: 4px;
  border-right-width: 4px;
  border-top-width: 6px;
  border-bottom-width: 4px;
  top: 14px;
  border-top-color: var(--gray-500);
}

.rstcustom__collapseButton:hover::after,
.rstcustom__expandButton:hover:after {
  border-top-color: var(--gray-500);
}

.research-area-tree-container .rstcustom__collapseButton:focus::after {
  filter: none;
}

.pods-container .rst__tree {
  padding-bottom: 12px;
}

.rstcustom__collapseButton,
.rstcustom__expandButton {
  width: 10px;
  margin-left: 19px;
}

.project-group-tree .rstcustom__collapseButton {
  width: 10px;
  margin-left: 23px;
}

.project-group-tree .rstcustom__expandButton {
  width: 10px;
  margin-left: 21px;
}

.project-group-tree .rstcustom__node {
  height: 38px !important;
}

.project-group-dropdown {
  width: 310px;
  border-radius: 0.75rem !important;
}

.file-tags-popover.popover {
  z-index: 1000;
  width: 302px;
  border-radius: 0.75rem !important;
}

button.rstcustom__collapseButton::after,
button.rstcustom__expandButton::after {
  border-left-width: 4px !important;
  border-right-width: 4px !important;
  border-top-color: var(--gray-500);
  border-width: 6px;
}

.research-area-tree-container .rstcustom__expandButton:focus::after {
  filter: none;
}

.rstcustom__rowWrapper > div {
  height: 36px;
}

.rstcustom__rowWrapper:hover {
  opacity: 1 !important;
}

.rstcustom__rowWrapper .research-area-tree-row {
  width: 100%;
  padding-left: 14px;
  border-radius: 24px;
}

.rstcustom__rowWrapper .research-area-tree-row:hover {
  background-color: var(--gray-50) !important;
  border-radius: 8px;
}

.rstcustom__rowWrapper .research-area-tree-row:focus-within {
  background-color: var(--gray-50) !important;
  border-radius: 8px;
}

@media screen and (min-width: 900px) {
  .rstcustom__rowWrapper .research-area-tree-row:hover {
    background-color: var(--gray-100) !important;
    border-radius: 8px;
  }

  .rstcustom__rowWrapper .research-area-tree-row:focus-within {
    background-color: var(--gray-100) !important;
    border-radius: 8px;
  }
}

.rstcustom__rowWrapper
  .research-area-tree-row.research-area-create-new-button:hover {
  background-color: var(--white-100) !important;
  border-radius: 8px;
  color: var(--gray-500);
  border: 1px solid var(--gray-100) !important;
}

.rstcustom__rowWrapper
  .research-area-tree-row.research-area-create-new-button
  .rstcustom__rowLabel {
  padding-left: 0px;
}

.rstcustom__rowWrapper .research-area-tree-row:hover .new-research-area-button {
  color: var(--gray-500);
}

.rstcustom__rowWrapper
  .research-area-tree-row:hover
  .research-area-overflow-btn {
  display: flex;
}

.rstcustom__rowWrapper .rstcustom__lineBlock {
  display: none;
}

.rstcustom__rowWrapper .research-area-tree-row .rstcustom__rowLabel {
  padding-right: 0;
}

.rstcustom__rowWrapper .research-area-tree-row .rstcustom__rowLabel {
  padding-left: 21px;
  width: 100%;
}

.rstcustom__rowWrapper .pods-tree-row .rstcustom__rowLabel {
  padding-left: 14px;
}

.rstcustom__rowWrapper
  .research-area-tree-row.all-research-insights
  .rstcustom__rowLabel {
  padding-left: 4px;
  width: 100%;
}

.rstcustom__rowWrapper .research-area-tree-row .rstcustom__rowContents {
  justify-content: flex-start;
  width: 100%;
}

.EmojiPickerReact {
  --epr-emoji-size: 24px !important;
  --epr-category-navigation-button-size: 28px !important;
}

.emoji-popover,
.emoji-popover .popover-body {
  padding: 0;
  border: none;
}

.research-area-popover .rstcustom__collapseButton,
.research-area-popover .rstcustom__expandButton {
  margin-left: 0;
}

[data-print-id='change-report-banner-container'] > div .popover,
.banner-img-canvas {
  border-radius: 12px;
}

.labels-modal-content {
  border-radius: 16px;
}

.modal-90w {
  max-width: 1280px !important;
}

.speaker-down {
  width: 8px;
  height: 5px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAFCAYAAAB4ka1VAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACGSURBVHgBXYs7CoNAGIT/P4shRZpsErBc8gAFC6/gDQQV7LyK3kCPYC94BEuxsrPyAFaWIoKuD1wQB6aYb2YI/Vjl/aUVXVs1cND77+i3h5pfEHk0TlMqKy4TpcxMtjIEHuAK6M/2gXPvKkkG9D0MhGSAGLd14qN4idEW9hLOol8rfC4+shnBvCfRwZinygAAAABJRU5ErkJggg==');
}

.para-st {
  width: fit-content;
  font-weight: var(--fontNormal);
  align-self: end;
  color: var(--gray-300);
  font-size: 9px;
  position: absolute;
  top: 20px;
  right: 25px;
}

.spk-container {
  cursor: pointer;
}

.spk-label-container {
  display: inline-flex;
  align-items: center;
  gap: 0.3rem;
}

.spk-name {
  width: fit-content;
  font-weight: var(--fontSemiBold);
  color: var(--gray-600);
  display: inline-block;
  font-size: var(--fontSize-12);
  max-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spk-dropdown-menu {
  position: absolute;
  background: white;
  z-index: 2;
  border: 1px solid black;
  width: 80%;
}

.flash-word-span {
  border-radius: 4px;
  background-color: var(--pink-500) !important;
  color: white !important;
  animation: heartbeat 1.5s both;
  padding: 5px 0px;
}

.spk-label-container {
  padding: 3px 0.5rem 0.15rem 0.75rem;
  border-radius: 24px;
}

.spk-label-container:hover {
  border-color: var(--gray-500);
  color: var(--gray-500);
  background: var(--gray-50);
}

.spk-label-container:not(.disabled):not(.view-only):hover + .para-st {
  display: none;
}

.spk-label-container.disabled {
  opacity: 0.5;
}

.spk-label-container.disabled:hover {
  background: var(--gray-50);
}

.spk-container.disabled {
  cursor: not-allowed;
}

.spk-label-container.view-only:hover {
  background: none;
}

.spk-container.view-only {
  cursor: auto;
}

.spk-label-container.view-only + .para-st {
  right: 15px;
}

font {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  text-transform: inherit;
  text-overflow: inherit;
  overflow: inherit;
}

@-webkit-keyframes smooth-height-insight-network {
  0% {
    height: 0px;
  }
  100% {
    height: 32px;
  }
}

@keyframes smooth-height-insight-network {
  0% {
    height: 0px;
  }
  100% {
    height: 32px;
  }
}

hr {
  border-color: var(--gray-100);
}

.insight-comment-resolve-spin-loader {
  width: 16px;
  height: 16px;
  border: 0.25em solid var(--gray-50);
  border-top-color: var(--green-300);
  border-right-color: var(--green-300);
  border-radius: 50%;
  animation: insight-image-loader-spin 1s linear infinite;
}
.insight-comment-delete-spin-loader {
  width: 16px;
  height: 16px;
  border: 0.25em solid var(--gray-50);
  border-top-color: var(--pink-500);
  border-right-color: var(--pink-500);
  border-radius: 50%;
  animation: insight-image-loader-spin 1s linear infinite;
}

.insight-image-uploader-spin-loader {
  width: 18px;
  height: 18px;
  border: 0.25em solid var(--gray-50);
  border-top-color: var(--pink-500);
  border-right-color: var(--pink-500);
  border-radius: 50%;
  animation: insight-image-loader-spin 1s linear infinite;
}

@keyframes insight-image-loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-div {
  display: inline-block;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  top: -40%;
  position: absolute;
}

.loader-wrapper {
  position: relative;
}

.loader-container {
  display: flex;
  justify-content: center;
}

.tabulator .tabulator-loader .tabulator-loader-msg {
  padding: 0 !important;
  border: none !important;
}

@keyframes loader-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes loader-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes loader-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.loader-div div {
  background-color: var(--pink-500);
}

.loader-div div:nth-child(1) {
  left: 8px;
  animation: loader-ellipsis1 0.6s infinite;
}

.loader-div div:nth-child(2) {
  left: 8px;
  animation: loader-ellipsis2 0.6s infinite;
}

.loader-div div:nth-child(3) {
  left: 32px;
  animation: loader-ellipsis2 0.6s infinite;
}

.loader-div div:nth-child(4) {
  left: 56px;
  animation: loader-ellipsis3 0.6s infinite;
}

.loader-div div {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.tabulator .tabulator-loader {
  background: rgba(220, 225, 241, 0.5) !important;
}

/* To fix windows specific scroll issue in pods popover */
.rstcustom__rowWrapper .rstcustom__row.popover-list-tree-row {
  padding: 3px 0px;
}

.ask-ai-result-modal .modal-title {
  width: 100%;
}

.ask-ai-result-modal .modal-content {
  background-color: var(--white-100);
}

.overflow-hidden {
  overflow: hidden;
}
.more-options-menu {
  left: 250px !important;
  top: -270px !important;
}

.background-color-gray {
  background-color: var(--gray-50);
}
.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: var(--gray-50);
}

.xxl-modal .modal-content {
  height: 90vh;
  padding: 0;
  margin: 0;
}
.xxl-modal .modal-dialog {
  min-width: 90vw;
  width: 90vw;
}
.xxl-modal .modal-body {
  padding: 0px !important;
}

.xxl-modal .modal-content {
  height: 90vh;
  padding: 0;
  margin: 0;
}
.xxl-modal .modal-dialog {
  min-width: 90vw;
  width: 90vw;
}
.xxl-modal .modal-body {
  padding: 0px !important;
}

.settings-fullscreen-modal {
  min-height: 100%;
  position: relative;
}

#interview-tags-popover.popover {
  z-index: 1500;
}

.select-insight-modal-backdrop.fade, .create-insight-modal-backdrop.fade {
  transition: none;
}

.select-insight-modal-container.fade, .create-insight-modal-container.fade {
  transition: none;
}


.file-notes-bulk-actions-menu {
  border: 1px solid var(--gray-200);
}

.dovetail-modal-body{
  height: 100%;
}
