.default-group__masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -16px;
  width: auto;
}

.default-group__masonry-column {
  padding-left: 16px; /* gutter size */
  background-clip: padding-box;
}

.default-group__masonry-list {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  flex-direction: row;
  width: 100%;
}

.insights-default-group__masonry-column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}
