/* ===== Custom Fonts ===== */
@font-face {
  font-family: 'Newsreader-Italic';
  src: url('./fonts/Newsreader-Italic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Newsreader-Medium';
  src: url('./fonts/Newsreader-MediumItalic.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CheltenhamITCBoldBT';
  src: url('./fonts/CheltenhamITCBoldBT.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CheltenhamITCBoldItalicBT';
  src: url('./fonts/CheltenhamITCBoldItalicBT.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CheltenhamITCBookBT';
  src: url('./fonts/CheltenhamITCBookBT.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'CheltenhamITCBookItalicBT';
  src: url('./fonts/CheltenhamITCBookItalicBT.ttf') format('truetype');
  font-display: swap;
}
