.react-caller-section {
  text-align: center;
  padding-top: 60px;
  height: 100%;
  overflow-y: scroll;
}

.number-pad-container {
  width: 312px;
  height: 444px;
  margin: 12px auto 14px auto;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  box-shadow: 2px 4px 4px rgb(0 0 0 / 4%);
  padding: 28px 24px;
  background: var(--white-100);
}

.number-pad {
  margin: 12px auto 0 auto;
  width: 192px;
  display: flex;
  flex-wrap: wrap;
}

.number-input-container {
  display: flex;
}

.number-input-container input {
  font-weight: var(--fontSemiBold);
  font-size: 20px;
  color: var(--gray-500);
  width: 100%;
  border: 1px solid #fff;
}

.number-input-container input:focus {
  border: none !important;
}

.number-input-container button {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-18);
  color: var(--gray-500);
}

.number-input-container button:hover {
  color: var(--gray-500);
}

.dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
  width: 190px;
}

.take-notes__spinner {
  border-color: white;
  margin-left: 15px;
  margin-right: -33px;
  width: 18px;
  height: 18px;
  border-right-color: transparent;
}

#country-code-dropdown::after {
  border-top: 0.2em solid;
  border-right: 0.2em solid transparent;
  border-left: 0.2em solid transparent;
}

PageDescription {
  margin-bottom: 34px;
}
