/** ===== Search ===== */

.search-container {
  height: calc(100vh - 4.75rem);
  margin: 0 24px 0 24px;
  padding-top: 40px;
}

.form-control,
.interviews-table {
  width: calc(100% - 4.375rem);
  max-width: 980px;
}

.clear-search-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  top: 6px;
  right: 52px;
}

.end-search-icon {
  width: 12px;
  height: 12px;
}

.search-tabs {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 36px;
  padding: 0 4px;
  background-color: #fbfbfb;
  border: 1px solid var(--gray-100);
  border-radius: 5px;
  /* TODO: Remove z-index after refactoring search input */
  z-index: 2;
}

.search-tabs__tab {
  height: 28px;
  padding-left: 8px;
  padding-right: 8px;
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  text-transform: capitalize;
  background: none;
  border: none;
  border-radius: 5px;
  color: var(--gray-400);
}

.search-tabs__tab:hover {
  background-color: #f9f9f9;
}

.search-tabs__tab:focus {
  outline: none;
}

.search-tabs__tab.active {
  color: var(--white-100);
  background-color: #fb3569;
}

.interviews-table {
  margin-top: 42px;
  width: 100%;
}

.search-empty-component {
  padding-top: 72px;
}

.interview__empty-state-img,
.note__empty-state-img,
.transcript__empty-state-img {
  height: 72px;
  width: 76px;
  margin-bottom: 16px;
}

h1.empty-state__heading {
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  display: inline;
  margin-bottom: 16px;
}

h5.empty-state__searched-term {
  margin-bottom: 36px;
  color: var(--gray-500);
}

.interview-term,
.transcript-term {
  font-weight: var(--fontSemiBold);
  font-size: 16px;
}

.search__video-player-container {
  width: 280px;
}

.search__video-player {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  background-color: black;
}

.search__video-player-overlay {
  width: 100%;
  height: 100%;
  position: relative;
  top: -162px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.player-overlay__btn {
  height: 42px;
  border-radius: 50%;
  cursor: pointer;
}

.search-result-container {
  margin-top: 32px;
  padding: 20px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(247, 248, 250, 1) 100%
  );
  border-radius: 8px;
}

.transcript__container {
  margin-bottom: 16px;
  padding: 20px;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
  background-color: var(--white-100);
  cursor: pointer;
}

.transcript__container:hover {
  box-shadow: 0px 3px 7px rgba(2, 0, 93, 0.1);
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.transcript__container:last-child {
  margin-bottom: 0px;
}

.transcript__body {
  width: calc(100% - 304px);
  margin-right: 24px;
}

.transcript__url:hover {
  text-decoration: none;
}

.transcript__date,
.transcript__speaker {
  font-size: 12px;
  line-height: 12px;
}

.username,
.interview {
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  color: var(--gray-500);
}

.transcript__date {
  margin-left: 14px;
  color: var(--gray-300);
}

.transcript__interview-name-and-date,
.interview__filename > em {
  font-weight: var(--fontSemiBold);
  font-size: 14px;
}

.transcript__interview-name-and-date {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  color: var(--gray-500);
}

.transcript__speaker {
  font-size: 12px;
  color: var(--gray-400);
  margin-bottom: 8px;
}

.transcript__text {
  display: block;
  font-size: 14px;
  line-height: 1.6;
  color: var(--gray-500);
}

.match-color {
  font-weight: var(--fontSemiBold);
  font-size: 14px;
}

.transcript__speaker {
  display: block;
}

.transcript__match {
  margin-bottom: 16px;
}

.interview-fle-name > em,
.tag-name em,
.transcript__text em,
.label__tag-label-text em {
  font-weight: var(--fontSemiBold);
  font-size: 14px;
  font-style: normal;
  color: #fb3569;
  line-height: 1.6;
}

.tag-description__without-clamp em {
  font-style: italic;
  font-size: var(--fontSize-13);
  line-height: 1.6;
}

.document-content em {
  font-weight: var(--fontSemiBold);
  font-size: var(--fontSize-14);
  color: var(--pink-500);
  line-height: 20px;
  font-style: normal;
}

.label__tag-label-text em {
  font-size: 12px;
}

.note__url {
  border: none;
  background-color: transparent;
  padding: 0;
}

.note__url:focus {
  outline: none;
}

.transcript__interview-name-and-date:hover {
  color: #fb3569;
}

/* ANIMATIONS */

.anim-fade-out {
  -webkit-animation: fadeOut 0.5s ease-in 0.5s both;
  animation: fadeOut 0.5s ease-in 0.5s both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
