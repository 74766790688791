.page-loader-container {
  display: flex;
  height: 100%;
  width: 100%;

  .shimmer-line {
    width: 100%;
    height: 100%;
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
  }

  .shimmer-circle {
    width: 100%;
    height: 100%;
    animation: shimmer 2s infinite linear;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    border-radius: 50%;
  }

  .sidenav {
    display: flex;
    flex-direction: column;
    width: 250px;
    border-right: 1px solid var(--gray-100);

    .sidenav-header {
      height: 68px;
      min-height: 68px;
      display: flex;
      width: 100%;
      align-content: center;
      padding: 13px 16px;
      justify-content: space-between;
    }

    .sidebar-content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 0px;

      .sidebar-item {
        height: 44px;
        display: flex;
        align-items: center;
        padding: 0 14px 0 24px;
        .shimmer-line {
          height: 22px;
        }
      }

      .sidebar-top {
        display: flex;
        flex-direction: column;
        gap: 0px;
        flex: 1;
      }

      .sidebar-bottom {
        display: flex;
        flex-direction: column;
        gap: 0px;
        padding-bottom: 1rem;
      }
    }

    .sidenav-toggle-btn {
      display: flex;
      align-items: center;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: var(--gray-50);

    .content-header {
      display: flex;
      flex-direction: row;
      -webkit-box-pack: justify;
      justify-content: space-between;
      color: var(--gray-600);
      -webkit-box-align: center;
      align-items: center;
      padding: 0.625rem 1rem 0.625rem 2rem;
      border-bottom: 1px solid var(--gray-100);
      background-color: var(--white-100);
      height: 68px;
      min-height: 68px;

      .content-header-left {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .shimmer-line {
          height: 24px;
          width: 200px;
        }
      }

      .content-header-right {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;

        .shimmer-circle {
          height: 2rem;
          width: 2rem;
        }
      }
    }

    .content-body {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: 10px;
      padding: 2rem;
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.marvin-logo-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
