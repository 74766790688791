.live-questions {
  strong span {
    font-weight: var(--fontSemiBold);
  }

  span.question-command {
    padding: 2px 6px 1px 6px;
    color: #f9005e;
    background-color: #f1f1f1;
    border-radius: 3px;
    margin-right: 8px;
    user-select: none;
  }

  p.question {
    margin-bottom: 8px;
    cursor: pointer;
    padding: 6px 12px;
    border-radius: 4px;
    background-color: var(--gray-50);
    color: var(--gray-600);
  }

  p.question:hover {
    background-color: var(--gray-100);
  }

  p.asked-question {
    background-color: var(--green-25);
  }

  p.question span {
    color: var(--blue-600);
    font-size: 13px;
    font-weight: var(--fontSemiBold);
  }

  h1.heading span {
    font-weight: var(--fontSemiBold);
    font-size: 16px;
    color: var(--gray-600);
  }

  .selected-empty-element {
    position: relative;
  }

  .selected-empty-element:after {
    content: " Use '/' to create question";
    color: #aaa;
    position: absolute;
    top: 0;
  }

  p.regular {
    margin-bottom: 8px;
    line-height: 1.6;
  }

  p.regular span {
    font-size: 14px;
    line-height: 1.24;
    margin-bottom: 16px;
    color: var(--gray-600);
  }

  ul.list-container {
    margin-bottom: 0px;
    padding-left: 24px;
  }

  ol.list-container {
    margin-bottom: 0px;
    padding-left: 24px;
  }

  li.list-item span {
    font-size: 14px;
    color: var(--gray-600);
  }

  li.list-item {
    margin-bottom: 4px;
  }

  h1.heading {
    margin-top: 20px;
    margin-bottom: 12px;
  }

  .element-link a span {
    color: var(--gray-400) !important;
    text-decoration: none !important;
  }

  .element-link {
    display: inline;
    position: relative;
  }

  .element-link a {
    display: inline-block;
  }
}
