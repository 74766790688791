.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  margin-bottom: 0;
  padding: 12px;
  padding-top: 13px;
}

.checkbox-with-label {
  width: auto;
  max-width: 100%;
  height: 100%;
  justify-content: flex-start;
  padding: 13px 12px 12px 0;
}

.checkbox__input > input {
  opacity: 0;
  width: 1em;
  height: 1em;
  margin-right: 8px;
  cursor: pointer;
  z-index: 1;
}

.checkbox__control {
  display: inline-grid;
  justify-content: center;
  align-items: center;
  width: 1em;
  height: 1em;
  border-radius: 3px;
  border: 1px solid var(--gray-300);
}

.checkbox__input {
  display: grid;
  grid-template-areas: 'checkbox';
  cursor: pointer;
}

.checkbox__input > * {
  grid-area: checkbox;
}

.checkbox__control svg,
.checkbox__control .tick-icon {
  transition: transform 0.1s ease-in 25ms;
  transform: scale(0);
  stroke-width: 4px;
}

.checkbox__input input:checked + .checkbox__control {
  background: rgba(251, 53, 105, 1);
  border-radius: 3px;
  border: 1px solid rgba(251, 53, 105, 1);
}

.checkbox-round .checkbox__input input:checked + .checkbox__control {
  border-radius: 50%;
}

.checkbox__input input:checked:disabled + .checkbox__control {
  background: var(--pink-300);
  border: 1px solid var(--pink-300);
}

.checkbox__input input:disabled + .checkbox__control {
  cursor: not-allowed;
}

.checkbox__input input:checked.active + .checkbox__control {
  background-color: var(--gray-500);
}

.checkbox__input input.active + .checkbox__control {
  border: 1.5px solid var(--gray-500);
}

.checkbox__input input:checked + .checkbox__control svg,
.checkbox__input input:checked + .checkbox__control .tick-icon {
  height: 8px;
  width: auto;
  transform: scale(1);
  cursor: pointer;
}

.checkbox__input input:disabled + .checkbox__control svg,
.checkbox__input input:disabled + .checkbox__control .tick-icon {
  cursor: not-allowed;
}

.checkbox__input input:checked + .checkbox__control .partial-select-svg {
  height: initial;
}

.radio__label {
  font-size: 13px;
  text-transform: none;
  color: var(--gray-500);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.note-checkbox.checkbox-with-label {
  position: absolute;
  top: -10px;
  left: -10px;
  padding: 0;
  justify-content: normal;
  height: unset;
  width: unset;
  max-width: unset;
  background-color: var(--gray-400);
  border-radius: 999px;
  z-index: 5;
}

.note-checkbox.checkbox-with-label:hover {
  background-color: var(--gray-500);
}

.note-checkbox.checkbox-with-label .checkbox__control {
  border-color: var(--gray-400);
}

.note-card-container.command-key-pressed:hover
  .note-checkbox
  .checkbox__control {
  border-color: transparent;
}

.note-checkbox.checkbox-with-label:hover .checkbox__control {
  border-color: var(--gray-500);
}

.note-checkbox:hover .checkbox__input input:checked + .checkbox__control {
  border-color: rgba(251, 53, 105, 1);
}

.select-all-checkbox.checkbox-with-label {
  padding: 0;
  justify-content: normal;
  height: unset;
  width: unset;
  max-width: unset;
  background-color: var(--white-100);
  border-radius: 999px;
  margin-right: 10px;
}

.note-checkbox .checkbox__input > input {
  height: 20px;
  width: 20px;
  margin-right: 0px;
  border-radius: 999px;
}

.select-all-checkbox .checkbox__input > input {
  height: 18px;
  width: 18px;
  margin-right: 0px;
  border-radius: 999px;
}

.note-checkbox .checkbox__control {
  height: 20px;
  width: 20px;
  border-radius: 999px;
}

.select-all-checkbox .checkbox__control {
  height: 18px;
  width: 18px;
  border-radius: 999px;
}

.note-checkbox .checkbox__input input:checked + .checkbox__control,
.select-all-checkbox .checkbox__input input:checked + .checkbox__control {
  border-radius: 999px;
}

.select-all-checkbox .checkbox__input input:disabled + .checkbox__control {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.note-checkbox .checkbox__input input + .checkbox__control svg,
.note-checkbox .checkbox__input input + .checkbox__control .tick-icon,
.select-all-checkbox .checkbox__input input + .checkbox__control svg,
.select-all-checkbox .checkbox__input input + .checkbox__control .tick-icon {
  height: 10px;
  width: auto;
  transform: scale(1);
  cursor: pointer;
}

.note-checkbox .checkbox__control {
  border: 1px solid var(--gray-200);
}

.select-all-checkbox .checkbox__control {
  border: 1px solid var(--gray-400);
}

.select-question-section
  .checkbox__input
  input:checked
  + .checkbox__control
  .tick-icon {
  height: 6px;
}

/* start: Label checkbox styles*/

.label-checkbox.checkbox-with-label {
  top: 0;
  left: 0;
  position: inherit;
}

/* end: Label checkbox styles*/


/* start: note selection styles */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* if command key pressed, the checkbox should fade in */
/* except "checkbox in hovered card",  "checkbox hovered in any card", "selected checkboxes" */
.note-card-container.command-key-pressed
  .note-checkbox:not(
    .note-card-container.command-key-pressed:hover .note-checkbox,
    .note-card-container.command-key-pressed .note-checkbox:hover,
    .note-card-container.command-key-pressed .note-checkbox:has(input:checked)
  ) {
  animation: 0.2s cubic-bezier(0.42, 0, 0.58, 1) fade-in;
}

.note-card-container.command-key-pressed:hover .note-checkbox {
  background-color: var(--gray-500);
}
