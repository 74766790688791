@keyframes highlight-box-pulse {
  0% { box-shadow: 0 0 0 0 rgba(0, 136, 255, 0.5) }
  70% { box-shadow: 0 0 0 10px rgba(0, 136, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 136, 255, 0); }
}

// Marvin Voice Orb Styles

#orb-container {
  position: relative;
  width: 168px;  /* Reduced from 320px */
  height: 168px;  /* Reduced from 320px */
  perspective: 1000px;
}

/* Enhanced ambient glow */
#glow {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 210px;  /* Reduced from 400px */
  height: 210px;  /* Reduced from 400px */
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: radial-gradient(circle at center,
      rgba(120, 190, 255, 0.15) 0%,
      rgba(100, 180, 255, 0.09) 40%,
      rgba(80, 160, 255, 0) 80%);
  filter: blur(25px);
  z-index: -1;
  pointer-events: none;
  animation: pulse 12s ease-in-out infinite alternate;
}

@keyframes pulse {
  0% { opacity: 0.5; transform: translate(-50%, -50%) scale(1); }
  50% { opacity: 0.7; transform: translate(-50%, -50%) scale(1.08); }
  100% { opacity: 0.5; transform: translate(-50%, -50%) scale(1); }
}

/* Main orb */
#orb {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 168px;  /* Reduced from 320px */
  height: 168px;  /* Reduced from 320px */
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 18px rgba(100, 180, 255, 0.15);  /* Adjusted shadow size from 35px */
  animation: gentle-float 20s ease-in-out infinite;
  transition: transform 0.2s ease-out;
}

@keyframes gentle-float {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  33% { transform: translate(-51%, -49%) rotate(0.8deg); }
  66% { transform: translate(-49%, -51%) rotate(-0.8deg); }
  100% { transform: translate(-50%, -50%) rotate(0deg); }
}

/* Base gradient for the fluid */
#fluid-base {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #0066cc, #60b0ff);
  opacity: 0.8;
}

/* Fluid wave elements */
.wave {
  position: absolute;
  border-radius: 47%;
  mix-blend-mode: soft-light;
}

#wave1 {
  width: 210px;  /* Reduced from 380px */
  height: 210px;  /* Reduced from 380px */
  top: -15px;  /* Adjusted positioning from -30px */
  left: -15px;  /* Adjusted positioning from -30px */
  background-color: rgba(255, 255, 255, 0.7);
  animation: fluid-rotate1 24s linear infinite;
  opacity: 0.5;
}

#wave2 {
  width: 220px;  /* Reduced from 400px */
  height: 210px;  /* Reduced from 350px */
  top: -8px;  /* Adjusted positioning from -15px */
  left: -20px;  /* Adjusted positioning from -40px */
  background-color: rgba(255, 255, 255, 0.6);
  animation: fluid-rotate2 18s linear infinite reverse;
  opacity: 0.4;
  border-radius: 42%;
}

#wave3 {
  width: 200px;  /* Reduced from 370px */
  height: 210px;  /* Reduced from 390px */
  top: -17px;  /* Adjusted positioning from -35px */
  left: -12px;  /* Adjusted positioning from -25px */
  background-color: rgba(230, 245, 255, 0.5);
  animation: fluid-rotate3 30s linear infinite;
  opacity: 0.45;
  border-radius: 45%;
}

@keyframes fluid-rotate1 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes fluid-rotate2 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
}

@keyframes fluid-rotate3 {
  0% { transform: rotate(0deg) scale(1); }
  50% { transform: rotate(180deg) scale(1.05); }
  100% { transform: rotate(360deg) scale(1); }
}

/* Fluid blobs with more natural movement */
.fluid-blob {
  position: absolute;
  background-color: white;
  mix-blend-mode: soft-light;
  border-radius: 50%;
  filter: blur(15px);
  transition: transform 0.3s ease-out;
}

#blob1 {
  width: 90px;  /* Reduced from 180px */
  height: 75px;  /* Reduced from 150px */
  top: 20px; /* Adjusted positioning from 40px */
  left: 25px; /* Adjusted positioning from 50px */
  animation: fluid-morph1 20s ease-in-out infinite alternate;
  opacity: 0.6;
  transform-origin: 30% 70%;
}

#blob2 {
  width: 105px;  /* Reduced from 210px */
  height: 85px;  /* Reduced from 170px */
  top: 70px; /* Adjusted positioning from 140px */
  left: 40px; /* Adjusted positioning from 80px */
  animation: fluid-morph2 24s ease-in-out infinite alternate-reverse;
  opacity: 0.5;
  transform-origin: 70% 30%;
}

#blob3 {
  width: 65px;  /* Reduced from 130px */
  height: 50px;  /* Reduced from 100px */
  top: 90px; /* Adjusted positioning from 180px */
  left: 25px; /* Adjusted positioning from 40px */
  animation: fluid-morph3 18s ease-in-out infinite alternate;
  opacity: 0.55;
  transform-origin: 50% 50%;
}

@keyframes fluid-morph1 {
  0% {
      transform: scale(1) translate(0, 0);
      border-radius: 60% 40% 70% 30% / 40% 50% 60% 50%;
  }
  33% {
      transform: scale(1.1) translate(-15px, 5px);
      border-radius: 50% 50% 30% 70% / 60% 40% 50% 60%;
  }
  66% {
      transform: scale(0.9) translate(15px, 10px);
      border-radius: 40% 60% 50% 50% / 30% 60% 70% 40%;
  }
  100% {
      transform: scale(1) translate(-5px, -15px);
      border-radius: 50% 50% 40% 60% / 30% 60% 40% 70%;
  }
}

@keyframes fluid-morph2 {
  0% {
      transform: scale(1) translate(0, 0);
      border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  33% {
      transform: scale(1.15) translate(-10px, -5px);
      border-radius: 70% 30% 50% 50% / 40% 60% 40% 60%;
  }
  66% {
      transform: scale(0.85) translate(20px, 10px);
      border-radius: 30% 70% 70% 30% / 60% 40% 30% 70%;
  }
  100% {
      transform: scale(1.05) translate(5px, -15px);
      border-radius: 40% 60% 60% 40% / 60% 40% 50% 50%;
  }
}

@keyframes fluid-morph3 {
  0% {
      transform: scale(1) translate(0, 0);
      border-radius: 50% 50% 50% 50% / 50% 50% 50% 50%;
  }
  33% {
      transform: scale(1.2) translate(15px, -10px);
      border-radius: 40% 60% 50% 50% / 40% 60% 50% 50%;
  }
  66% {
      transform: scale(0.8) translate(-20px, 5px);
      border-radius: 60% 40% 30% 70% / 50% 50% 60% 40%;
  }
  100% {
      transform: scale(1.1) translate(10px, 15px);
      border-radius: 45% 55% 60% 40% / 60% 40% 35% 65%;
  }
}

/* Fluid currents */
.fluid-current {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
  mix-blend-mode: overlay;
  filter: blur(20px);
  border-radius: 100%;
  opacity: 0.4;
}

#current1 {
  width: 140px;  /* Reduced from 250px */
  height: 55px;  /* Reduced from 100px */
  top: 40px; /* Adjusted positioning from 80px */
  left: -10px; /* Adjusted positioning from -20px */
  transform: rotate(30deg);
  animation: current-flow1 15s ease-in-out infinite alternate;
}

#current2 {
  width: 120px;  /* Reduced from 200px */
  height: 55px;  /* Reduced from 90px */
  top: 80px; /* Adjusted positioning from 160px */
  left: 60px; /* Adjusted positioning from 100px */
  transform: rotate(-20deg);
  animation: current-flow2 18s ease-in-out infinite alternate-reverse;
}

@keyframes current-flow1 {
  0% { transform: rotate(30deg) translateX(0); opacity: 0.4; }
  50% { transform: rotate(25deg) translateX(20px); opacity: 0.5; }
  100% { transform: rotate(35deg) translateX(-20px); opacity: 0.3; }
}

@keyframes current-flow2 {
  0% { transform: rotate(-20deg) translateX(0); opacity: 0.3; }
  50% { transform: rotate(-25deg) translateX(-30px); opacity: 0.5; }
  100% { transform: rotate(-15deg) translateX(15px); opacity: 0.4; }
}

/* Highlight */
#highlight {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 45%;
  height: 45%;
  border-radius: 50%;
  background: radial-gradient(circle at center,
      rgba(255, 255, 255, 0.7) 0%,
      rgba(255, 255, 255, 0) 70%);
  mix-blend-mode: overlay;
  z-index: 3;
  transition: opacity 0.5s ease;
}

/* Wave pulses instead of ripples */
.wave-pulse {
  position: absolute;
  background: linear-gradient(to bottom,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0));
  border-radius: 50% 50% 0 0 / 100% 100% 0 0;
  mix-blend-mode: screen;
  transform-origin: center bottom;
  filter: blur(5px);
  pointer-events: none;
  z-index: 2;
  opacity: 0;
  animation: wave-movement 3s cubic-bezier(0.2, 0.8, 0.2, 1) forwards;
}

@keyframes wave-movement {
  0% {
      transform: scaleY(0.1) scaleX(0.8);
      opacity: 0.9;
      height: 10px;
  }
  100% {
      transform: scaleY(1) scaleX(1.2);
      opacity: 0;
      height: 60px;
  }
}
