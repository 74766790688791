.projects-list-container {
  max-height: 270px;
  height: 270px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.insights-list-container {
  max-height: 270px;
  min-height: 56px;
  overflow: auto;
  margin-bottom: 16px;
}

.import-label-modal {
  min-width: 700px;
}

.insights-list-container .react-lds-ellipsis {
  top: 0;
}

.insights-search .has-search .form-control-feedback {
  height: 2.375rem;
  line-height: 2.55rem;
}

.insights-search .has-search .form-control {
  height: 40px;
  border-radius: 8px;
  line-height: 40px;
}

.notes-modal {
  height: 100%;
  overflow: auto;
  background-color: var(--white-100);
}

.tag-modal {
  height: 100%;
  overflow: auto;
  background-color: #ECF0FB;
}

.delete-label {
  margin: 0;
}
.delete-parent-label .modal-footer {
  padding-top: 24px;
}
  
.delete-parent-label .modal-footer > button {
  margin: 0px;
}
