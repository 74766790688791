.annotation-labels-page {
  .treeRow,
  .treeHeading {
    width: 900px !important;
  }

  .treeRow-2 {
    width: 856px !important;
  }

  .treeRow-3 {
    width: 812px !important;
  }

  .treeRow-4 {
    width: 768px !important;
  }

  .treeRow-5 {
    width: 723px !important;
  }
}

.project-theme-app,
.label-template,
.merge-labels-modal,
.annotation-theme-app {
  .rst__virtualScrollOverride {
    overflow-x: hidden !important;
  }

  .treeRow,
  .treeHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 974px;
    cursor: pointer;
  }

  .treeRow:hover .showOnHover {
    display: none;
  }

  .treeRow-2 {
    width: 930px;
  }

  .treeRow-3 {
    width: 886px;
  }

  .treeRow-4 {
    width: 842px;
  }

  .treeRow-5 {
    width: 798px;
  }

  .name-column {
    width: 100%;
  }

  .label-buttons {
    display: none;
  }

  .treeHeading {
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-right: 40px;
    border-bottom: 1px solid var(--gray-100);
  }

  .treeHeading * {
    font-weight: var(--fontSemiBold);
    font-size: 12px;
    color: var(--gray-400);
  }

  .virtualized-tree {
    overflow: hidden !important;
    padding-bottom: 4rem;
  }

  h2 {
    font-size: 16px;
  }

  .tree-ellipsis-color {
    color: var(--gray-300);
  }

  .treeColumn {
    width: 150px;
    overflow: hidden;
  }

  .labelColumn {
    width: 750px;
  }

  .labelNameColumn {
    cursor: pointer;
    margin-bottom: 0px;
  }

  .labelNameColumn span,
  .labelNameColumn mark {
    font-size: var(--fontSize-13);
    color: var(--gray-600);
  }

  .label-description span,
  .label-description mark {
    font-size: var(--fontSize-11);
    line-height: 0.8125rem;
    color: var(--gray-500);
  }

  .label-description.deleting span,
  .label-description.deleting mark {
    color: var(--red-50);
    font-weight: var(--fontSemiBold);
  }

  .label-description {
    padding: 0 16px 0 4px;
  }

  .labelNameColumn.inactive span,
  .label-description.inactive span {
    color: var(--gray-300);
    cursor: default;
  }

  .usageColumn.inactive {
    background-color: var(--gray-50);
  }

  .usageColumn.inactive span {
    color: var(--gray-300);
  }

  .activateColumn {
    width: 1.75rem;
    height: 1.75rem;
    margin-right: 0.5rem;
    margin-left: 0.25rem;
    display: flex;
    align-items: center;
  }

  .label-author,
  .add-child-label,
  .editColumn,
  .mergeColumn,
  .subscriptionColumn {
    width: 28px;
    height: 28px;
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
  }

  .editColumn img,
  .mergeColumn img,
  .subscriptionColumn img {
    display: none;
  }

  .showOnHover {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    width: 28px;
    height: 28px;
  }

  .showOnHover:hover {
    display: none;
  }

  .treeRow:hover .label-author img,
  .treeRow:hover .add-child-label img,
  .treeRow:hover .editColumn img,
  .treeRow:hover .mergeColumn img,
  .treeRow:hover .subscriptionColumn img {
    display: block;
  }

  .treeRow:hover .toggle-container {
    display: flex;
  }

  .editColumn:hover,
  .subscriptionColumn:hover,
  .mergeColumn:hover,
  .add-child-label:hover {
    background-color: var(--gray-50);
  }

  .usageColumn {
    min-width: 2.5rem;
    width: auto;
    margin: 0 1.25rem;
    height: 1.5rem;
    border-radius: 16px;
    background-color: var(--gray-50);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.0625rem;

    &.deleting {
      background-color: var(--red-50);

      img {
        filter: brightness(0) saturate(100%) invert(93%) sepia(100%)
          saturate(0%) hue-rotate(217deg) brightness(107%) contrast(107%);
      }

      span {
        color: var(--white-100);
      }
    }
  }

  .usageColumn img {
    margin-right: 4px;
  }

  .usageColumn span {
    color: var(--gray-500);
    font-size: var(--fontSize-10);
    line-height: 10px;
  }

  .timeColumn {
    width: 85px;
    padding: 0 5px;
  }

  .createdByColumn {
    width: 150px;
    text-transform: capitalize;
    padding: 0 5px;
  }

  .labelImg {
    margin: 0 0.75rem 0 0.5rem;
  }

  .labels-header {
    margin-bottom: 8px;
  }

  .table-headings {
    padding-right: 72px;
  }

  .table-headings th {
    padding-left: 5px;
    padding-right: 5px;
  }

  .no-width {
    width: unset;
  }

  .deactivated {
    color: #ff7e67;
  }

  .deactivated:hover {
    color: #ff7e67;
  }

  .rst__rowTitle {
    font-weight: var(--fontNormal);
  }

  .rst__collapseButton,
  .rst__expandButton,
  .rst__collapseButton:focus,
  .rst__expandButton:focus {
    left: -22px;
    border: solid 1px var(--gray-200);
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 8.9px;
  }

  .rst__moveHandle {
    background-color: #fff;
    background-image: url('../../img/drag.svg');
    border: solid 1px var(--gray-100);
    border-right: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0.5rem 0 0 0.5rem;
    z-index: 0;
  }

  .rst__rowContents {
    border: 1px solid var(--gray-100);
    border-left: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 0px;
  }

  .rst__rowLabel {
    height: 100%;
    padding-right: 0px;
  }

  .rst__rowWrapper {
    padding: 4px 0;
  }

  .rst__row:hover {
    box-shadow: 0px 3px 10px rgba(2, 0, 93, 0.1);
    border-radius: 8px;
  }

  .rst__row:hover:not(.selected) .rst__moveHandle,
  .rst__row:hover:not(.selected) .rst__rowContents {
    background-color: var(--white-100);
    border-color: var(--gray-200);
  }

  .rst__lineBlock::after,
  .rst__lineBlock::before,
  .rst__lineChildren::after {
    background-color: var(--gray-200);
  }

  .treeRow:hover .usageColumn.active {
    background-color: var(--gray-100);
  }

  /* Start: Toggle Styles */
  input:checked + .slider-labels {
    background-color: var(--green-300);
  }

  input:focus + .slider {
    box-shadow: none;
  }

  .treeRow input:checked + .slider:before {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray-200);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
  }

  .treeRow .slider:before {
    position: absolute;
    content: '';
    height: 8px;
    width: 8px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.15);
  }

  /* End: Toggle Styles */

  .tippy-content div {
    color: white;
  }

  .treeRow:hover .label-buttons {
    display: flex;
    margin-left: auto;
  }

  .label-subscribed-bell-icon {
    display: flex;
    margin-left: auto;
  }

  /* Start: Hover Styles */
  .treeRow:hover .label-subscribed-bell-icon {
    display: none;
  }

  .treeRow:hover .labelNameColumn.active {
    color: var(--gray-600);
  }

  .treeRow:hover .label-description.active {
    color: var(--gray-600);
  }

  /* End: Hover Styles */

  /* start: search styles */

  .rst__row.rst__rowSearchMatch {
    outline: none;
  }

  mark.highlighted-label,
  span.highlighted-label {
    color: var(--pink-500);
    background-color: var(--yellow-200);
    padding: 0;
    margin: 0;
  }

  .disable-search .rst__moveHandle {
    background-image: linear-gradient(
        rgba(255, 255, 255, 0.7),
        rgba(255, 255, 255, 0.7)
      ),
      url('../../img/drag.svg');
    pointer-events: none;
  }

  .selected .rst__moveHandle,
  .selected .rst__rowContents {
    border-color: var(--pink-500);
  }

  .grid-container {
    overflow-x: hidden;
  }

  .project-theme-app {
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  /* end: search styles */
}

.project-theme-app:not(.label-tree-preview),
.label-template,
.merge-labels-modal,
.annotation-theme-app {
  .rst__virtualScrollOverride {
    overflow-y: hidden !important;
  }
}

.merge-labels-modal {
  .usageColumn {
    margin: 0px;
  }

  .label-description {
    padding: 0px;
  }
}

.label-template {
  height: 100%;
  background-color: var(--gray-background);
}

.tree-label-loader {
  .rst__node {
    height: 48px !important;
    z-index: 0;
  }

  .rst__rowLabel {
    width: 100%;
  }
}

.label-tree-preview {
  overflow-x: unset;

  .rst__node {
    height: 40px !important;
    z-index: 0;
  }

  .rst__rowContents {
    border: 1px solid var(--gray-100);
    border-radius: 8px;
  }

  .rst__row:hover {
    box-shadow: none;
  }

  .rst__rowContents:hover {
    box-shadow: none;
    border: 1px solid var(--gray-100);
  }

  .rst__row:hover:not(.selected) .rst__moveHandle,
  .rst__row:hover:not(.selected) .rst__rowContents {
    background-color: var(--white-100);
    border-color: var(--gray-100);
  }

  .treeRow {
    padding-left: 16px;
    cursor: auto;
  }

  .treeRow-1 {
    width: 680px;
  }

  .treeRow-2 {
    width: 635px;
  }

  .treeRow-3 {
    width: 590px;
  }

  .treeRow-4 {
    width: 546px;
  }

  .treeRow-5 {
    width: 500px;
  }

  .labelNameColumn {
    cursor: auto;
  }

  .label-description {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 436px;
    color: var(--gray-400);
    padding: 0px;
    padding-right: 2px;
  }

  .usageColumn {
    margin: 0;
  }

  .treeRow:hover .usageColumn.active {
    background-color: var(--gray-50);
  }

  .treeRow:hover .usageColumn.inactive {
    background-color: var(--gray-50);
  }

  .rst__expandButton {
    display: none;
  }

  .rst__collapseButton {
    display: none;
  }
}

.view-only-tree {
  .rst__tree {
    opacity: 70%;
  }

  .rst__node {
    display: flex;
    height: 48px !important;
    z-index: 0;

    .rst__lineBlock {
      flex: 0 0 auto;
    }

    .rst__nodeContent {
      flex: 0 1 auto;
      left: auto !important;
      position: relative !important;
      top: auto !important;
      width: 100%;
    }
  }

  .treeRow {
    width: 492px;
  }

  .treeRow-2 {
    width: 452px;
  }

  .treeRow-3 {
    width: 412px;
  }

  .treeRow-4 {
    width: 372px;
  }

  .treeRow-5 {
    width: 332px;
  }
}

/* Ping Animation Styles */
@keyframes ping-fade {
  0% {
    background-color: var(--gray-100);
    transform: scale(1);
  }
  50% {
    background-color: rgba(220, 225, 241, 0.5); /* var(--gray-100) with 50% opacity */
    transform: scale(1.02);
  }
  100% {
    background-color: transparent;
    transform: scale(1);
  }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(220, 225, 241, 0.9); /* var(--gray-100) with 90% opacity */
  }
  70% {
    box-shadow: 0 0 0 10px rgba(220, 225, 241, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(220, 225, 241, 0);
  }
}

.ping-highlight {
  animation: ping-fade 1.5s ease-out, ripple 1.5s cubic-bezier(0, 0, 0.2, 1);
  border-radius: 32px;
}
