.action-container {
  display: none;
  margin-left: auto;
}

.reply-container {
  &:hover {
    .action-container {
      display: flex;
    }

  }
}
