/* ===== Interviews Page ===== */

.file-icon-wrapper {
  display: inline-block;
}

.file_icon.video-icon,
.file_icon.text-icon,
.file_icon.pdf-icon,
.file_icon.doc-icon,
.file_icon.sheet-icon {
  margin-right: 20px;
}

.file_icon.audio-icon {
  margin-right: 25px;
}

.file_icon.recording-icon {
  height: 24px;
  width: 24px;
  margin: 0 31px 0 5px;
}

.file-row:hover .metadata-preview {
  max-width: calc(100% - 150px);
}

.metadata-preview em {
  font-weight: var(--fontSemiBold);
  font-style: normal;
  font-size: 12px;
  color: #fb3569;
}

.metadata-preview {
  font-size: 12px;
  color: var(--gray-400);
  max-width: calc(100% - 100px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-table {
  width: 100%;
}

.file-actions-container {
  display: none;
  position: absolute;
  right: 0;
  top: 30%;
  cursor: pointer;
}

.file-row:hover {
  background-color: #fbfbfb;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.file-row:hover .file-actions-container {
  display: block;
}

.file-row {
  border: 1px solid var(--gray-100);
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 8px;
  min-height: 80px;
  width: 100%;
  background-color: var(--white-100);
}

.file-content {
  display: inline-block;
  position: relative;
  width: calc(100% - 65px);
}

.file-info-item {
  display: inline-block;
}

.item-full-width {
  margin-right: 20px;
}

.item-half-width {
  margin-right: 20px;
}

.file-info-item-content {
  color: var(--gray-400);
  font-size: 12px;
}

.tagging-done {
  color: #21c59f;
}

.file-info-item-image {
  width: 13px;
}

.file-info-item-image-upload {
  width: 15px;
}

.file-info-item-image-tag {
  width: 10px;
}

.files-container {
  height: calc(100vh - 4.75rem);
  width: 980px;
  margin-left: 15px;
}

.file-actions-container a {
  text-decoration: none;
}

.file-content input {
  width: 400px;
  font-weight: var(--fontSemiBold);
}

.file-item {
  box-sizing: border-box;
  margin: auto;
  padding: 16px 28px;
}

/** ===== Move Modal ===== */
.move-interview__name,
.move-interview__warning {
  color: var(--gray-600);
  font-size: 16px;
}

.move-interview__warning {
  margin-top: 8px;
  margin-bottom: 24px;
  color: #f53635;
}

.move-interview__projects-list {
  width: 640px;
  max-height: 260px;
  overflow-y: auto;
  padding: 34px;
  padding-bottom: 10px;
  margin-bottom: 80px;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
}

.move-interview__project {
  margin-bottom: 24px;
}

.move-interview__move-btn {
  color: #ff5678;
  cursor: pointer;
}

.move-interview__project-name {
  width: 440px;
  margin-bottom: 0;
}

.move-interview__close-btn {
  margin-top: 38px;
  margin-right: 48px;
}

.file-participants {
  color: var(--gray-300);
}

.file-participants em {
  color: #fb3569;
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  font-style: normal;
}

.transcript_status_verified {
  color: #21c59f;
}

.file-hyperlink:hover {
  text-decoration: none;
}

.file-action-buttons {
  border: none;
  background-color: transparent;
}

.file-name-edit {
  width: 240px;
}
