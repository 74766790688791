.multiple-discussion-guides {
  [data-slate-editor] {
    padding: 4rem 6.25rem 6rem;
    min-height: calc(100vh - 160px);
    cursor: text;
  }

  [data-slate-editor] ::marker {
    color: var(--gray-600);
  }

  [data-slate-node='element'] {
    padding: 0.1rem 0.8rem;
  }

  @media (max-width: 1200px) {
    [data-slate-editor] {
      padding: 2rem 3.12rem 3rem;
    }
  }

  strong span {
    font-weight: var(--fontSemiBold);
  }

  span.question-command {
    padding: 2px 6px 1px 6px;
    color: #f9005e;
    background-color: #f1f1f1;
    border-radius: 3px;
    margin-right: 8px;
    user-select: none;
  }

  p.question {
    margin-bottom: 2px;
  }

  p.question span {
    color: var(--blue-600);
    font-size: 15px;
    text-decoration: inherit;
    line-height: 1.3rem;
  }

  p.question [data-slate-string='string']::first-letter {
    margin-right: 10px;
    color: var(--pink-500);
  }

  span.question-symbol span {
    margin-right: 6px;
    color: var(--pink-500);
  }

  h1.heading span {
    font-weight: var(--fontNormal);
    font-size: 1.25rem;
    color: var(--gray-600);
  }

  h1.heading strong span {
    font-weight: var(--fontSemiBold);
  }

  .selected-empty-element {
    position: relative;
  }

  .selected-empty-element:after {
    content: " Use '/' to create question";
    color: var(--gray-300);
    position: absolute;
    top: 0;
    line-height: 2rem;
  }

  p.regular {
    margin-top: 4px;
    margin-bottom: 4px;
  }

  p.regular span {
    font-size: 15px;
    line-height: 1.13;
    margin-bottom: 16px;
    color: var(--gray-600);
  }

  ul.list-container {
    margin-bottom: 0px;
    padding-left: 32px;
  }

  ol.list-container {
    margin-bottom: 0px;
    padding-left: 32px;
  }

  li.list-item span {
    font-size: 15px;
    color: var(--gray-600);
  }

  li.list-item {
    margin-bottom: 8px;
  }

  h1.heading {
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .edit-question {
    border: 1px solid transparent;
    border-radius: 4px;
    padding-bottom: 6px;
    padding-top: 6px;
  }

  .edit-question.focused {
    background: var(--gray-50);
    border: 1px solid var(--gray-100);
    box-sizing: border-box;
    border-radius: 6px;
  }

  p.question span.question-slash-marker {
    color: var(--pink-500);
  }

  p.question question-marker span {
    color: var(--pink-500);
    font-weight: var(--fontSemiBold);
    letter-spacing: 10px;
    caret-color: var(--blue-600);
    font-weight: var(--fontBold);
    text-decoration: none;
  }

  question-marker {
    line-height: 1rem;
  }

  .element-link {
    display: inline;
    position: relative;
  }

  .element-link .popup a {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-right: 10px;
  }

  .element-link .popup button {
    border: none;
    background: transparent;
  }

  .slate-link span {
    color: var(--gray-400) !important;
    text-decoration: none !important;
  }

  .slate-target-link span {
    color: var(--blue-600) !important;
  }

  [data-slate-node='element'] a {
    padding: 0;
  }
}

.multiple-discussion-guides-preview {
  [data-slate-editor] {
    padding: 0rem;
  }
}
