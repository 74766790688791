.animate-enter {
  opacity: 0;
}

.animate-enter.animate-enter-active {
  /* opacity: 1; */
  /* transition: opacity 500ms ease-out; */
  /* animation: slide-in 0.5s forwards; */
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
  animation: slide-in-right 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

.animate-exit {
  opacity: 1;
}

.animate-exit.animate-exit-active {
  opacity: 0;
  /* transition: opacity 500ms ease-out; */
  /* animation: slide-in 0.5s forwards; */
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    reverse both;
  animation: slide-in-right 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) reverse
    both;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(34%);
    transform: translateX(34%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(34%);
    transform: translateX(34%);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes inAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
