.half-border-left {
  position: relative;
  padding-left: 0px;
}

.half-border-left::before {
  content: '';
  position: absolute;
  top: 24%;
  left: 0;
  height: 50%;
  width: 1px;
  background-color: var(--gray-200);
  transform: translateY(-50%);
}
