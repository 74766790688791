@import url('theme.css');

html * {
  font-family: var(--fontFamily);
  font-size: 14px;
  line-height: 24px;
  color: var(--gray-600);
}

*,
*::before,
*::after {
  font-family: var(--fontFamily);
}

/* ===== Typography Helpers ===== */

.fontsize-10 {
  font-size: var(--fontSize-10);
}

.fontsize-12 {
  font-size: 12px;
}

.fontsize-13 {
  font-size: var(--fontSize-13);
}

.fontsize-14 {
  font-size: 14px;
}

.fontsize-16 {
  font-size: 16px;
}

.font-normal {
  font-weight: var(--fontNormal);
}

.font-semibold {
  font-weight: var(--fontSemiBold);
}

.font-bold {
  font-weight: var(--fontBold);
}

/* ===== Anchors ===== */

a {
  font-size: 14px;
  line-height: 24px;
  color: var(--gray-400);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--gray-600);
}

a.nav-link {
  font-size: 14px;
}

a.unstyled-with-underline {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
  background-color: inherit;
  text-decoration: underline;
}

a.unstyled {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: inherit;
  background-color: inherit;
  text-decoration: inherit;
}

a.navbar-brand.auth {
  margin-bottom: 36px;
}

/* ===== Border Helpers ===== */
.border-default-top {
  border-top: 1px solid var(--gray-100);
}

.border-default-right {
  border-right: 1px solid var(--gray-100);
}

.border-default-bottom {
  border-bottom: 1px solid var(--gray-100);
}

.border-default-left {
  border-left: 1px solid var(--gray-100);
}

.border-default-all {
  border: 1px solid var(--gray-100);
}

/* ===== Color Helpers ===== */
.color-fff {
  color: var(--white-100);
}

.color-333 {
  color: var(--gray-500);
}

.color-555 {
  color: var(--gray-500);
}

.color-666 {
  color: var(--gray-400);
}

.color-brand {
  color: #fb3569;
}

.bg-color {
  background: var(--gray-background);
}

/* ===== Header ===== */
.header {
  background-color: var(--white-100);
  border-bottom: 1px solid var(--gray-100);
  box-sizing: content-box;
  height: 68px;
  min-height: 68px;
  align-items: center;
}

.header-title-text {
  font-weight: var(--fontSemiBold);
  font-size: 16px;
  line-height: 24px;
  color: var(--gray-600);
}

.header-pill {
  height: 40px;
  width: 132px;
  border: 1px solid var(--pink-500) !important;
  box-sizing: border-box;
  border-radius: 54px !important;
  color: var(--pink-500);
  font-weight: var(--fontSemiBold);
  background: white;
  font-size: 12px;
}

.header-pill:hover {
  background: var(--pink-500);
  color: white;
}

.header-pill-insights {
  width: 134px;
}

/* ===== Containers ===== */

.grid-container {
  display: grid;
  grid-template-columns: 250px calc(100% - 250px);
  height: 100vh;
}

.grid-container-shortened {
  display: grid;
  grid-template-columns: 80px calc(100% - 80px);
  height: 100vh;
}

.grid-container-report-anonymous {
  height: 100vh;
  background-color: var(--gray-50);
  min-width: max-content;
}

.grid-container-report {
  display: grid;
  grid-template-columns: 80px 1fr;
  height: 100vh;
  background-color: var(--gray-background);
  min-width: max-content;
}

.grid-container-text-analysis {
  display: grid;
  grid-template-columns: 80px calc(100vw - 80px);
  height: 100vh;
  background-color: var(--gray-50);
  min-width: max-content;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.labels-container,
.projects-container,
.notepad-default-container {
  height: calc(100vh - 4.75rem);
  padding: 24px 48px 0px 48px;
}

.notepad-default-container {
  padding-top: 44px;
}

.analyze-container {
  background-color: var(--white-100);
  height: calc(100vh - 5rem);
}

.project-theme-app,
.annotation-theme-app {
  background-color: var(--gray-50);
  min-height: 100%;
  height: 100%;
}

.project-themes-container {
  margin-left: 44px;
  flex-grow: 1;
}

.project-caller-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  height: 100vh;
}

.playlist-container {
  background-color: var(--gray-50);
  height: auto;
  width: 100%;
}

.integration-container {
  display: flex;
}

.zoom-waiting-container {
  padding: 60px 0 48px;
  margin: auto;
}

/* ===== Margin Helpers ===== */

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-20 {
  margin-left: 20px;
}

.m-12 {
  margin: 12px;
}

.mt-12 {
  margin-top: 12px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-12 {
  margin-right: 12px;
}

.mb-12 {
  margin-bottom: 12px;
}

.ml-12 {
  margin-left: 12px;
}

.m-16 {
  margin: 16px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-16 {
  margin-left: 16px;
}

.m-28 {
  margin: 28px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mr-16 {
  margin-right: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.mt-28 {
  margin-top: 28px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-28 {
  margin-right: 28px;
}

.mb-28 {
  margin-bottom: 28px;
}

.ml-28 {
  margin-left: 28px;
}

.m-34 {
  margin: 34px;
}

.mt-20 {
  margin-top: 20;
}
.mt-34 {
  margin-top: 34px;
}

.mr-34 {
  margin-right: 34px;
}

.mb-20 {
  margin-bottom: 20;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-34 {
  margin-bottom: 34px;
}

.ml-34 {
  margin-left: 34px;
}

.m-42 {
  margin: 42px;
}

.mt-42 {
  margin-top: 42px;
}

.mr-42 {
  margin-right: 42px;
}

.mb-42 {
  margin-bottom: 42px;
}

.ml-42 {
  margin-left: 42px;
}

.m-48 {
  margin: 48px;
}

.mt-48 {
  margin-top: 48px;
}

.mr-48 {
  margin-right: 48px;
}

.mb-48 {
  margin-bottom: 48px;
}

.ml-48 {
  margin-left: 48px;
}

.m-64 {
  margin: 64px;
}

.mt-64 {
  margin-top: 64px;
}

.mt-68 {
  margin-top: 68px;
}

.mr-64 {
  margin-right: 64px;
}

.mb-64 {
  margin-bottom: 64px;
}

.ml-64 {
  margin-left: 64px;
}

.mb-72 {
  margin-bottom: 72px;
}

/* ===== Padding Helpers ===== */
.p-0 {
  padding: 0;
}

.p-20 {
  padding: 20px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-0 {
  padding-left: 0px;
}

.pl-8 {
  padding-left: 8px;
}

.pl-20 {
  padding-left: 20px;
}

.pl-28 {
  padding-left: 28px;
}

.pl-32 {
  padding-left: 32px;
}

.pl-44 {
  padding-left: 44px;
}

.pl-80 {
  padding-left: 80px;
}

.pt-40 {
  padding-top: 40px;
}

/* ===== Width Helpers ===== */

.width-10p {
  width: 10%;
}

.width-14p {
  width: 14%;
}

.width-20p {
  width: 20%;
}

.width-25p {
  width: 25%;
}

.width-36p {
  width: 36%;
}

.width-40p {
  width: 40%;
}

.width-47p {
  width: 47%;
}

.width-50p {
  width: 50%;
}

.width-56p {
  width: 56%;
}

.width-60p {
  width: 60%;
}

.width-85p {
  width: 85%;
}

.width-100p {
  width: 100%;
}

.width-28 {
  width: 28px;
}

.width-108 {
  width: 108px;
}

.width-120 {
  width: 120px;
}

.width-300 {
  width: 300px;
}

.width-320 {
  width: 320px;
}

.width-346 {
  width: 346px;
}

.width-360 {
  width: 360px;
}

.width-400 {
  width: 400px;
}

.width-500 {
  width: 500px;
}

.width-600 {
  width: 600px;
}

.close {
  opacity: 1;
}

/* ===== Height Helpers ===== */

.height-100p {
  height: 100%;
}

.height-8 {
  height: 8px;
}

.height-28 {
  height: 28px;
}

.height-50 {
  height: 50px;
}

.height-68 {
  height: 68px;
}

/* ===== Cursor Helpers ===== */
.cursor-pointer {
  cursor: pointer;
}

/* ===== Text Overflow ===== */
.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-alert {
  width: 450px;
  position: fixed;
  left: 50%;
  right: 25%;
  margin-left: -225px;
  background-color: #111111;
  color: var(--white-100);
  border-radius: 8px;
  border-color: #111111;
  z-index: 10000;
  bottom: 0px;
  margin-bottom: 50px;
}

.close-alert-button {
  height: 100%;
  font-size: 0;
}

.close.close-alert-button:hover {
  opacity: 1 !important;
}

.close-alert-icon,
.delete-label-icon {
  filter: invert(99%) sepia(0%) saturate(1227%) hue-rotate(275deg)
    brightness(100%) contrast(100%);
}

.secondary-links {
  color: #fb3569;
}

.secondary-links:hover {
  color: #fb3569;
  text-decoration: none;
  border-bottom: 1px solid #fb3569;
}

.secondary-links-forgot-password {
  font-size: var(--fontSize-12);
  font-weight: var(--fontSemiBold);
  line-height: 14px;
}

/*Turn a whole div into a link*/
.link-spanner {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: 7%; /*Leave room for play/pause button */
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  padding: 20px;
  padding-top: 12px;
  border-right: 1px solid var(--gray-100);
  background-color: var(--white-100);
}

.sidebar--notepad {
  border-right: none;
  background-color: #ecf0fb;
}

.sidebar.sidebar-right {
  left: auto;
  right: 0;
  background-color: rgba(247, 248, 250, 0.5);
  width: 345px;
  padding: 46px;
}

.sidebar-filler {
  padding: 40px 40px 40px 40px;
}

.fixed-width {
  width: 250px;
  max-width: 250px;
}

.fixed-width-nav-thin {
  width: 80px;
}

.right-space {
  margin-top: -80px;
  margin-right: -15px;
  padding-right: 0;
  background-color: #ecf0fb;
}

.meeting-card {
  position: fixed;
  right: 0;
  z-index: 100;
  height: 264px;
  border-radius: 4px;
  margin-top: -32px;
  margin-right: 32px;
}
.record-icon {
  animation: 1s infinite alternate ease-out breathing-color;
  margin-right: 12px;
}

.recording-icon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  border: 1px solid var(--gray-500);
  border-radius: 9999px;
  animation: 1s infinite alternate ease-out breathing-color;
}

@keyframes breathing-color {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.sidenav__project-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  padding: 0;
  border-left: none;
}

.sidenav__project-container:hover {
  text-decoration: none;
}

.sidenav__project-text {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
  font-weight: var(--fontNormal);
  font-size: 12px;
  color: var(--gray-400);
}

.sidenav__project-name {
  margin-bottom: 0px;
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
  font-size: 14px;
  line-height: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidenav-item {
  width: 100%;
  margin-bottom: 6px;
  margin-left: -3px;
}

.thin-sidenav-item {
  height: 34px;
  width: 100%;
  margin-left: 0 !important;
}

.thin-sidenav-link {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}

.thin-sidenav-link > .sidenav-icon {
  margin-right: 0;
}

#back-to-interviews-button {
  margin-bottom: 24px;
}

.beta-icon {
  margin-left: 6px;
}

.sidebar .nav-link {
  min-height: 34px;
  display: flex;
  align-items: center;
  font-weight: var(--fontSemiBold);
  font-size: 13px;
  padding: 0 12px;
  border-radius: 8px;
  color: var(--gray-500);
}

.sidebar .nav-link:hover {
  color: var(--gray-600);
}

.sidebar .nav-link:hover > .sidenav-icon,
.thin-sidenav-link:hover > .sidenav-icon {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(324deg) brightness(96%)
    contrast(104%);
}

.sidebar .nav-link.active,
.thin-sidenav-link.active {
  color: var(--gray-600);
  background: var(--active-nav-link-bg);
}

.sidenav-icon {
  filter: brightness(100%) contrast(75%);
  margin-right: 12px;
}

.nav-link.active > .sidenav-icon,
.thin-sidenav-link.active > .sidenav-icon {
  filter: invert(14%) sepia(14%) saturate(1294%) hue-rotate(186deg)
    brightness(95%) contrast(91%);
}

.bg-light {
  background-color: #ecf0fb !important;
}
.dropdown-item {
  cursor: pointer;
}

.dropdown-item.dropdown-item-info {
  background-color: var(--gray-50);
  margin-left: 0;
  padding: 7px 20px 7px 20px;
  text-align: center;
}

.dropdown-menu {
  background: var(--white-100);
  border: 0.996px solid var(--gray-100);
  box-shadow: 2px 4px 20px rgba(0, 0, 50, 0.12);
  border-radius: 8px;
  padding-top: 0;
  padding: 0;
}

.dropdown-icon {
  height: 7px;
}

.dropdown-menu.sidenav__user-dropdown {
  padding: 4px 8px;
  background-color: var(--gray-600);
  color: white;
  box-shadow: none;
  border: none;
}

.sidenav__user-info-body:hover > .dropdown-menu {
  display: block;
  position: absolute;
  transform: translate3d(80px, -63px, 0px);
  top: 48px;
  left: -8px;
  will-change: transform;
}

.sidenav-avatar-container {
  width: 2.25rem;
  height: 2.25rem;
  margin-right: 4px;
}

.sidenav__user-dropdown::before {
  content: ' ';
  height: 0;
  position: absolute;
  width: 0;
  left: -16px;
  top: 32px;
  border: 8px solid transparent;
  border-right-color: var(--gray-600);
}
.sidenav_projects__user-info-container {
  width: 100%;
  margin-top: 12px;
}

.sidenav__user-info-container {
  width: 100%;
  margin-left: -24px;
  position: absolute;
  bottom: 28px;
}

.sidenav__user-info-container > .sidenav__user-info-message {
  margin-left: 24px;
}

.sidenav__placement-bottom {
  position: absolute;
  bottom: 28px;
}

.thin-sidenav__placement-bottom {
  width: 100%;
  position: absolute;
  bottom: 28px;
  left: 0;
}

.sidenav__separator-line {
  height: 12px;
  border-bottom: 1px solid var(--gray-100);
}

.sidenav__user-info-message {
  font-size: 12px;
  margin-bottom: 12px;
}

.sidenav__user-avatar,
.thin-sidenav__user-avatar {
  margin-right: 8px;
  margin-left: -6px;
  margin-top: -4px;
  width: 36px;
}

.thin-sidenav__user-avatar {
  margin-right: 0px;
  margin-left: 0px;
}

.sidenav__user-name,
.thin-sidenav__user-name {
  text-transform: capitalize;
  font-weight: var(--fontSemiBold);
  margin-top: -4px;
  margin-bottom: -6px;
}

.sidenav__user-name,
.sidenav__user-email,
.thin-sidenav__user-name,
.thin-sidenav__user-email {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidenav__user-email,
.sidenav__user-logout,
.thin-sidenav__user-email,
.thin-sidenav__user-logout {
  font-size: 12px;
  margin-bottom: -2px;
}

.thin-sidenav__user-name {
  margin-top: 4px;
}

.thin-sidenav__user-logout {
  margin-bottom: 4px;
}

.thin-sidenav__user-name,
.thin-sidenav__user-email,
.thin-sidenav__user-logout,
.thin-sidenav__user-logout:hover {
  text-align: left;
  color: white;
}

.thin-sidenav__align-left {
  display: flex;
}

.top-navbar {
  padding: 20px 74px;
  border-bottom: 1px solid var(--gray-100);
}

.top-navbar > .navbar-brand {
  margin-right: 62px;
}

.navbar-brand {
  padding-top: 0;
  margin-left: 4px;
}

.navbar-brand__thin-sidenav {
  width: 100%;
  margin-bottom: 39px;
  padding: 0 0 0 0.25rem;
}

.userfocus-icon-2 {
  margin-left: -3px;
  height: 35.67px;
}

.nav-dropdown {
  margin-left: -44px;
}

.dropdown-menu {
  box-shadow: 1px 4px 4px 0 rgba(0, 0, 0, 0.12);
  margin-top: 10px;
}

.dropdown-item {
  padding: 10px 20px 10px 20px;
}

.btn:focus {
  box-shadow: none;
}

.btn-xs {
  padding: 2px;
  position: relative;
  top: -1.5px;
}

.close:focus {
  outline: none;
}

.negative-margin {
  margin-left: -1.85rem;
  margin-right: -1.85rem;
}

/* ===== Table ===== */

.table {
  white-space: nowrap;
}

.table td,
.table th {
  padding: 0.85rem 1rem;
  border: 0;
  vertical-align: middle;
}

.table thead th {
  font-size: 12px !important;
  color: var(--gray-400) !important;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #ececec;
}

.table th.upload-interview-header {
  text-align: center;
}

.table th.last-header {
  text-align: right;
  padding-right: 62px;
}

.table-striped > tbody > tr:nth-child(2n + 1) > td {
  background-color: #f7f9fa;
}

.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: var(--white-100);
}

.project-row {
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 20px;
  min-height: 80px;
  width: 100%;
}

.project-row:hover,
.zoom-meetings-row:hover {
  background-color: #fbfbfb;
}

.project-col {
  width: 50%;
}

.project-col__project-name {
  font-weight: var(--fontSemiBold);
  color: var(--gray-600);
  width: 28vw;
  margin-bottom: 0;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.creation-col {
  vertical-align: middle;
}

.upload-col {
  vertical-align: middle;
  text-align: center;
}

.share-col {
  vertical-align: middle;
}

.project-folder-img {
  margin-right: 18px;
}

.spinner-border {
  width: 28px;
  height: 28px;
  margin-right: 28px;
  border-color: #fb3569;
  border-right-color: transparent;
}

.btn-nofocus:focus {
  box-shadow: none;
}

.btn.btn-nofocus.overflow-button {
  margin-right: -1.25rem;
}

.overflow-filter {
  cursor: pointer;
  height: 24px;
  filter: invert(76%) sepia(10%) saturate(394%) hue-rotate(184deg)
    brightness(93%) contrast(83%);
}

.overflow-filter:hover {
  filter: invert(34%) sepia(19%) saturate(401%) hue-rotate(183deg)
    brightness(94%) contrast(91%);
}

.dropdown.show > button img.overflow-filter {
  filter: invert(34%) sepia(19%) saturate(401%) hue-rotate(183deg)
    brightness(94%) contrast(91%);
}

.card-bordered {
  border-radius: 10px;
  border: 1px solid #eceeef;
  background: #fff;
}

.card-header-normal {
  background: #fff;
  border-radius: inherit !important;
  border-bottom: hidden;
}

.card-wide {
  width: 125%;
}

.search__large-input {
  display: inline-block;
}

.search__small-input:focus,
.search__large-input:focus,
.tag-form__search-labels-input:focus {
  border: none !important;
  outline: none !important;
}

.notepad-default__notepad-icon {
  height: 39px;
  width: 39px;
  margin-bottom: 28px;
}

.notepad-default__warning-icon {
  height: 40px;
  width: 40px;
  margin-bottom: 28px;
}

.notepad-default__zoom-connected-icon {
  height: 40px;
  width: 40px;
  margin-bottom: 28px;
}

.notepad-instruction {
  margin-bottom: 34px;
}

.notepad-instruction-image {
  margin-right: 20px;
}

.notepad-instruction__zoom-icon {
  height: 28px;
  width: 28px;
}

.notepad-instruction-text {
  font-size: 16px;
  color: var(--gray-500);
  margin-bottom: 0;
}

.return-description__img {
  position: absolute;
  left: 178px;
  top: 2px;
}

.mock-timer {
  font-weight: var(--fontSemiBold);
  font-size: 16px;
  color: #fb3569;
  margin-right: 16px;
}

.zoom-connect {
  margin-top: 60px;
}

.key-style {
  font-size: 16px;
  color: #f9005e;
  padding: 8px 8px;
  margin: 0 2px;
  border-radius: 3px;
  background-color: var(--gray-100);
}

button:disabled {
  cursor: not-allowed;
}

button.button-center-align-text {
  margin: -8px;
}

button.pause {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  background: #fb3569;
  border: 2px solid #fb3569;
  margin-top: 35px;
  margin-bottom: 35px;
}

button.pause-small {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #fb3569;
  border: 1px solid #fb3569;
  margin-top: 17px;
  margin-bottom: 17px;
}

.label-plus {
  width: 80px;
  height: 80px;
  border-radius: 40px;
  border: 2px solid #fb3569;
  background: #fb3569;
  cursor: pointer;
  text-align: center;
  align-items: center;
}

button.wide {
  margin-bottom: 30px;
  width: 352px;
  height: 48px;
  background: #fb3569;
  border: none;
}

button.integration-failed-cls-btn {
  margin-bottom: 30px;
  padding: 12px 52px;
  background: var(--pink-500);
  border: none;
}

.button-text {
  font-weight: var(--fontSemiBold);
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--white-100);
}

.emphasized-text {
  font-weight: var(--fontSemiBold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--gray-600);
}

div.mid-align {
  align-items: center;
}

div.center-align {
  text-align: center;
}

div.read-only {
  background-color: rgba(197, 191, 191, 0.5);
}

.recording-notes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
  margin-bottom: 32px;
  padding-left: 28px;
  padding-top: 26px;
}

.row-padded-bottom {
  margin-bottom: 2%;
}

.row-padded-top {
  margin-top: 2%;
}

div.transcript-text {
  height: 200px;
  overflow-y: scroll;
}

input.long-text {
  margin-top: 2%;
}

input.placeholder {
  font-size: small;
}

.text-subject {
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -0.00395px;
  color: var(--gray-500);
  margin-bottom: 5%;
}

.text-deemphasize {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.00230417px;
  color: var(--gray-400);
  margin-bottom: 3%;
}

.text-closer {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  letter-spacing: -0.00230417px;
  color: var(--gray-400);
  margin-bottom: -5%;
}

.text-heading {
  font-weight: var(--fontBold);
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  letter-spacing: -0.00395px;
  color: var(--gray-600);
  margin-bottom: 8px;
}

.text-heading-login {
  font-weight: var(--fontSemiBold);
}

.text-subheading {
  font-size: 16px;
  text-align: left;
  letter-spacing: -0.00230417px;
  color: var(--gray-500);
  margin-bottom: 35px;
  height: 24px;
}

.form-check-inline {
  margin-left: 25px;
  margin-right: 0px;
}

.no-wrap {
  white-space: nowrap;
}

label {
  display: block;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gray-500);
}

.form-login label {
  font-weight: var(--fontSemiBold);
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gray-500);
}

.input-box {
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  border-radius: 4px;
  width: 35%;
  margin-left: 25px;
}

input:focus {
  border: 1px solid var(--gray-500);
  outline: none;
}

select:focus {
  border: 1px solid var(--gray-500) !important;
  outline: none;
}

.app__container {
  height: 100vh;
  background-color: #ecf0fb;
  overflow: auto;
}

/** below interview classes are used in worker, some classes are shared in interview.css as well */
/** TODO: Change the names or add prefixes to the below classes to signify that they are worker classes to avoid shared styles */

.interview__icon.video-icon {
  margin-right: 20px;
}

.interview__icon.audio-icon {
  margin-right: 32px;
}

.interview__icon.document-icon {
  margin-right: 28px;
}

.upload-progress-circle {
  margin-right: 30px;
}

.interview__icon.recording-icon {
  height: 24px;
  width: 24px;
  margin: 0 31px 0 5px;
}

.interview__icon.beta-icon {
  position: absolute;
  left: -6px;
  top: 10px;
}

.interview-row {
  position: relative;
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: 12px;
  min-height: 80px;
  width: 100%;
}
.interview-row:hover {
  box-shadow: 0px 3px 7px rgba(2, 0, 93, 0.1);
  border-color: var(--gray-100);
}

.interview__filename {
  margin-bottom: 4px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0px;
  line-height: 28px;
}

@media (max-width: 1440px) {
  .interview__filename {
    width: 240px;
  }
}

.interview-card {
  width: 254px;
  border-radius: 20px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05),
    0 -5px 10px 0 rgba(0, 0, 0, 0.05);
  margin-left: 20px;
  margin-right: 20px;
  border-color: var(--gray-100);
}

.interview-card:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15),
    0 -5px 10px 0 rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.2s;
  border-color: var(--gray-200);
}

/** ===== Move Modal ===== */
.move-interview__name,
.move-interview__warning {
  color: var(--gray-600);
  font-size: 16px;
}

.move-interview__warning {
  margin-top: 8px;
  margin-bottom: 24px;
  color: #f53635;
}

.move-interview__projects-list {
  width: 640px;
  max-height: 260px;
  overflow-y: auto;
  padding: 34px;
  padding-bottom: 10px;
  margin-bottom: 80px;
  border: 1px solid var(--gray-100);
  border-radius: 8px;
}

.move-interview__project {
  margin-bottom: 24px;
}

.move-interview__move-btn {
  color: #ff5678;
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.move-interview__project-name {
  width: 440px;
  margin-bottom: 0;
}

.move-interview__close-btn {
  margin-top: 38px;
  margin-right: 48px;
}

.navigation__top--public {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border: solid 1px var(--gray-100);
}

.form-control {
  border-radius: 0;
}

input.form-control {
  border-radius: 0.25rem;
  border-color: var(--gray-100);
}

.form-control:focus {
  border: 1px solid var(--gray-500);
  outline: none;
  box-shadow: none;
}

.form-login input,
.form-login-accept-invite input {
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 50px;
  width: 320px;
  padding: 16px 15px;
}

.form-login input,
.form-login-accept-invite input:focus {
  border: 1px solid var(--gray-100);
}

.form-login-accept-invite input[name='first_name'],
.form-login-accept-invite input[name='last_name'] {
  width: 156px;
}

.form-login-accept-invite input[readonly] {
  background-color: var(--gray-50);
}

.form-login-accept-invite label {
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  margin-bottom: 7px;
}

.form-login.share-project input {
  width: 400px;
}

.form-upload {
  width: 400px;
}

.form-upload label {
  font-weight: var(--fontSemiBold);
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--gray-500);
  text-align: left;
}

.error-container {
  width: 320px;
  padding: 12px 15px;
  background-color: rgba(245, 54, 53, 0.2);
  margin-bottom: 35px;
}

.form-error {
  font-size: 12px;
  line-height: 1.65;
  color: var(--gray-500);
  margin-bottom: 0;
}

ul.errorlist.nonfield {
  display: none;
}

ul.errorlist {
  display: none;
}

.hidden-position {
  position: absolute;
  top: -9999px;
}

.s3direct .progress {
  background: #c4c4c4 !important;
}

.s3direct .cancel-button {
  right: -30px !important;
  cursor: pointer !important;
}

.s3direct .file-link {
  font-size: 12px;
  margin-top: -66px;
  margin-left: 4px;
  width: 500px;
}

.file-size-text {
  margin-bottom: 5px;
  font-size: 12px;
  color: var(--gray-400);
}

.progress .bar {
  width: 0;
  height: 100%;
  color: #fff;
  float: left;
  font-size: 12px;
  text-align: center;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  background-color: #fb3569 !important;
  background-image: -moz-linear-gradient(top, #fb3569, #fb3569) !important;
  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    from(#fb3569),
    to(#fb3569)
  ) !important;
  background-image: -webkit-linear-gradient(top, #fb3569, #fb3569) !important;
  background-image: -o-linear-gradient(top, #fb3569, #fb3569) !important;
  background-image: linear-gradient(180deg, #fb3569, #fb3569) !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FB3569",endColorstr="#FB3569",GradientType=0) !important;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.sel-handle {
  cursor: ew-resize;
  color: #fb3569;
  width: 2px;
  display: inline-block;
  overflow: visible;
  background-color: #fb3569;
  height: 26px;
  border-radius: 4px;
  vertical-align: top;
  position: relative;
  padding: 0 2px;
  margin: 0 -2px;
  top: 1px;
}

.sel-handle.sel-handle-start::before,
.sel-handle.sel-handle-end::before {
  content: '';
  display: block;
  background-color: #fb3569;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -2px;
  top: -3px;
  border-radius: 100%;
}

.sel-handle.sel-handle-end::before {
  top: 22px;
}

.wavesurfer-lane {
  border-radius: 20px;
}

wave {
  border-top: 1px solid #eeeeee;
}

cursor {
  height: 148px;
  top: -6px !important;
}

handle {
  background-color: transparent !important;
  border-left: 1px solid var(--gray-500) !important;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scaleX(0.95);
    transform: scaleX(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scaleX(0.98);
    transform: scaleX(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scaleX(0.92);
    transform: scaleX(0.92);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scaleX(0.95);
    transform: scaleX(0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scaleX(0.98);
    transform: scaleX(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scaleX(0.92);
    transform: scaleX(0.92);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

a.custom-card,
a.custom-card:hover {
  color: inherit;
  text-decoration: none;
}

.recording-medium {
  font-weight: var(--fontSemiBold);
  color: var(--gray-600);
  margin-bottom: 2px;
}

.file-types {
  font-size: 12px;
  margin-bottom: 0;
  color: var(--pink-500);
}

.taking-notes {
  font-weight: var(--fontNormal);
  color: var(--gray-400);
  margin-bottom: 0px;
}

.taking-notes--zoom-card {
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
  margin-bottom: 8px;
}

.recording-phone-call {
  margin-bottom: 0;
}

.meeting-description {
  font-size: 14px;
  margin-bottom: 32px;
}

.meeting-time {
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
  margin-bottom: 0;
}

select.select-project {
  border: 1px solid var(--gray-200);
  box-sizing: border-box;
  height: 50px;
  width: 440px;
  padding: 16px 15px;
  margin: 20px 0 30px 0;
  background-color: white;
}

.form-upload input,
.interview-name {
  box-sizing: border-box;
  margin: auto;
  height: 50px;
  padding: 16px 15px;
}

.form-upload input[type='file'] {
  border: none;
}

#logout-button {
  background: none;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  color: #428bca;
}

#logout-button:hover {
  background-color: var(--gray-100);
  color: #2a6496;
}

.container.paddings {
  padding-left: 15px;
  padding-right: 15px;
}

#class-panel-header {
  padding-top: 2px;
  text-align: right;
}
.classes-container {
  margin-top: 60px;
}
.class-btn {
  display: inline-block;
  margin-left: 25px;
  padding: 2px 15px;
  cursor: pointer;
  border-radius: 14px;
  font-size: 14px;
  background-color: #23c5c1;
}
.tags span.shortcut {
  font-weight: var(--fontNormal);
}

.upload-int-projects-icon {
  border-radius: 30%;
}

.upload-int-projects-icon:hover {
  background-color: var(--gray-100);
  height: 36px;
  width: 36px;
  padding: 6px;
  margin-bottom: -2px;
}

.move-interview-icon,
.delete-interview-icon,
.edit-interview-icon,
.add-metadata-icon {
  border-radius: 30%;
  height: 36px;
  width: 36px;
  padding: 9px;
}

.edit-demographics-icon {
  border-radius: 30%;
  height: 36px;
  width: 36px;
  padding: 7px;
}

.move-interview-icon:hover,
.delete-interview-icon:hover,
.edit-interview-icon:hover,
.add-metadata-icon:hover,
.edit-demographics-icon:hover {
  background-color: var(--gray-50);
}

.user-avatar {
  width: 36px;
  height: 36px;
  border: 2px solid white;
  cursor: pointer;
}

.tag-author-info,
.reply-author-info {
  display: flex;
  align-items: center;
}

.tag-author-avatar,
.reply-author-avatar {
  width: 28px;
  height: 28px;
  margin: -4px 6px 0 -2px;
}

.tag-author-avatar-qualtrics {
  width: 20px;
  height: 12px;
  border-radius: 0;
}

.tag-author-info__text,
.reply-author-info__text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 24px;
}

.tag-author-info__text.list {
  flex-direction: row;
  height: unset;
  gap: 8px;
  align-items: center;
}

.tag-author-info__text.list .tag-author-name {
  margin-bottom: unset;
}

.tag-author-info__text.list .tag-created-at {
  margin-top: unset;
}

.reply-author-avatar {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}

.tag-author-name,
.reply-author-name {
  font-weight: var(--fontSemiBold);
  font-size: 10px;
  line-height: 10px;
  margin-bottom: 0;
  color: var(--gray-400);
}

.tag-created-at,
.reply-created-at {
  font-size: 10px;
  line-height: 10px;
  color: var(--gray-400);
  margin-top: 4px;
  margin-bottom: 0;
}

.tag-created-at_Analyze {
  color: var(--gray-400);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bullet {
  height: 3px;
  width: 3px;
  min-width: 3px;
  max-width: 3px;
  border-radius: 50%;
  background-color: var(--gray-400);
  margin-bottom: 2px;
}

.bullet-dark {
  background-color: var(--gray-500);
}

.add-user {
  width: 35px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 12px;
}

.add-user:hover {
  filter: invert(9%) sepia(11%) saturate(87%) hue-rotate(183deg) brightness(90%)
    contrast(78%);
}

.share-project__icon {
  width: 35px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 12px;
}

.share-project__icon:hover {
  filter: invert(30%) sepia(0%) saturate(13%) hue-rotate(138deg)
    brightness(101%) contrast(85%);
}

.share-project__icon-disabled {
  width: 35px;
  border: none;
  cursor: not-allowed;
  margin-left: 5px;
  margin-right: 12px;
  filter: opacity(0.5) !important;
}

.project-users,
.meeting-participants {
  display: flex;
  justify-content: center;
  align-items: center;
}

.meeting-participants {
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 400px;
  min-height: 36px;
  margin: 4px 0px 24px 36px;
}

.people-count {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-weight: var(--fontSemiBold);
  background: var(--gray-200);
  color: var(--gray-500);
  border: 2px solid white;
  border-radius: 9999px;
  margin-top: 2px;
  height: 38px;
  width: 38px;
}

.shared-user-names {
  color: white;
  display: block;
}

h5.big-title {
  font-size: 24px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  color: var(--gray-500);
}

h5.title {
  font-weight: var(--fontBold);
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  color: var(--gray-500);
}

h1.title {
  font-weight: var(--fontBold);
  font-size: 20px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  color: var(--gray-500);
}
h2.subtitle {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  color: var(--gray-400);
  display: inline;
}
p.subtitle {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  color: var(--gray-400);
  display: inline;
}

h5.subtitle {
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  color: var(--gray-400);
  display: inline;
}

h5.warning {
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.00329167px;
  display: inline;
}

h5.basic {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.00329167px;
  color: var(--gray-400);
  display: inline;
}

h5.sub-subtitle {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.00329167px;
  color: var(--gray-400);
  display: inline;
}

div.sub-subtitle {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.00329167px;
  color: var(--gray-400);
}

.title.size-16 {
  font-size: 16px;
}

.dot {
  height: 96px;
  width: 96px;
  background-color: var(--gray-200);
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 25px;
}

.loading {
  background: #696969;
  box-sizing: border-box;
  border-radius: 4px;
}

.normal {
  font-weight: var(--fontSemiBold);
  background: #fb3569;
  color: white;
  padding: 10px 26px;
  font-size: 12px;
}

.normal:hover {
  color: white;
}

.normal-grey {
  background: white;
  border: 1px solid var(--gray-200);
  color: var(--gray-400);
  padding: 10px 26px;
  font-size: 12px;
}

.outlined {
  font-weight: var(--fontSemiBold);
  background: white;
  border: 1px solid #fb3569;
  color: #fb3569;
  padding: 10px 26px;
  font-size: 12px;
}

.outlined:hover {
  background: #fb3569;
  color: white;
}

.invisible {
  visibility: hidden;
}

.tag-element {
  display: inline-block;
  margin-left: 25px;
  box-shadow: 0 0 6px grey;
  padding: 2px 20px;
  border-radius: 2px;
  color: rgba(35, 197, 193, 0.6);
  font-size: 13px;
  font-weight: var(--fontBold);
  text-transform: uppercase;
  margin-top: 10px;
}

#regions-mode-tag-container {
  text-align: right;
}

#regions-mode-tag-label {
  display: none;
}

#tips-container {
  padding: 10px 30px;
  margin-top: 10px;
  background: none;
}

#tips-container .number {
  color: #00b1b9;
  font-size: 18px;
  font-weight: var(--fontSemiBold);
}

#switch-view {
  float: left;
  margin-top: 55px;
}

.delete-tag-button {
  cursor: pointer;
  color: black;
  position: absolute;
  margin-top: -1px;
  margin-left: 7px;
}

.tags.active {
  background-color: #609ab6;
  color: #fff;
}

.tags {
  display: inline-block;
  margin-left: 25px;
  padding: 2px 15px;
  cursor: pointer;
  border-radius: 14px;
  font-size: 14px;
  background-color: #23c5c1;
  text-align: right;
  font-style: oblique;
}

.tags-comment {
  display: inline-block;
  padding: 2px 15px;
  margin-bottom: 10px;
  border-radius: 14px;
  font-size: 14px;
  background-color: #23c5c1;
  text-align: left;
  font-style: oblique;
}

.tags-comment-timestamp {
  padding: 2px 15px;
  font-style: oblique;
}

.row-comment-section {
  margin-left: 15px;
  margin-bottom: 15px;
}

.row-comment-text {
  margin-top: -8px;
}

#login-signup-container {
  margin-top: 90px;
}

.ui-widget.ui-autocomplete {
  z-index: 1100;
}

/* ===== Modals ===== */

.fullscreen-modal-dialog {
  z-index: 1050;
  max-width: 100% !important;
  min-height: 100vh !important;
  margin: 0 auto !important;
}

.fullscreen-modal-dialog .modal-content {
  border-radius: 0;
  height: 100vh !important;
}

.modal-content {
  border: none;
  border-radius: 8px;
}

.modal-header-no-border {
  border-bottom: 0px;
}

.modal-footer-no-border {
  border: 0px;
  justify-content: flex-start;
}

.modal-wide {
  width: 100%;
  height: 100vh;
}

.modal-dialog.modal-dialog-centered {
  max-width: 520px !important;
  margin: auto;
}

.modal-dialog.modal-dialog-centered > .modal-content {
  border-radius: 8px !important;
}

.modal-dialog.modal-dialog-centered.modal-lg {
  max-width: 640px !important;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal__small-close-btn {
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  padding: 0;
  z-index: 2;
  background: none;
  border: none;
}

.modal__small-close-btn:hover {
  background-color: var(--gray-100);
  border-radius: 4px;
}

.change-speaker_modal-header {
  padding: 0;
  display: flex;
  position: relative;
  left: 450px;
  top: 5px;
}

.change-speaker_modal-title {
  font-weight: var(--fontSemiBold);
  margin-bottom: 14px;
  line-height: 1.5;
  color: var(--gray-600);
}

.change-speaker_modal-footer {
  padding: 0;
  padding-left: 8px;
  margin-bottom: 4px;
  border: 0px;
}

.change-speaker_modal-body {
  padding-top: 0;
  padding-bottom: 0;
}

.change-speaker_modal-content {
  padding: 20px 28px 28px 28px;
  height: 208px;
  width: 530px;
}

.change-speaker_modal-text {
  color: var(--gray-600);
}

.change-speaker_modal-btn {
  margin-right: 4px;
}

.select-project__close-icon {
  height: 12px;
  filter: invert(65%) sepia(0%) saturate(1002%) hue-rotate(207deg)
    brightness(95%) contrast(89%);
}

.export-modal__header-img {
  height: 20px;
  width: auto;
}

.export-modal__close-icon {
  height: 15px;
  color: var(--gray-400);
  filter: invert(65%) sepia(0%) saturate(1002%) hue-rotate(207deg)
    brightness(95%) contrast(89%);
}

input.search-filter-row {
  width: 96%;
  margin: 2%;
  background-color: #f5f5f5;
  border-radius: 2px;
  border: none;
}

.export-modal__header-title {
  font-size: 18px;
  font-weight: var(--fontBold);
  color: var(--gray-500);
  margin-left: 12px;
  margin-bottom: 0;
}

.non-resizable-textarea {
  resize: none;
}

.select-project-modal-header {
  padding: 24px 28px 0;
}

.select-project-header {
  padding: 20px;
}

.create-asana-task_modal-header {
  width: 100%;
  padding: 11px 40px;
}

.select-project__close-btn {
  font-size: 0;
  padding: 16px 12px 16px 16px;
}

.select-project-body {
  padding: 20px 30px 40px 30px;
}

.select-project__title {
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
}

.select-project__dropdown {
  height: 50px;
  margin-bottom: 8px;
}

.select-project__dropdown,
.select-project__dropdown-menu {
  width: 440px;
}

.dropdown-menu.select-project__dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
  top: -6px;
  border: 1px solid var(--gray-100);
}

.select-project__btn {
  height: 100%;
  width: 100%;
  border: 1px solid var(--gray-100);
}

.select-project__project-name {
  cursor: pointer;
}

.select-project__project-name:active {
  color: #212529;
}

.select-project-buttons {
  padding: 0;
  width: 300px;
}

.select-project__warning {
  font-size: 12px;
  color: var(--gray-400);
  margin-bottom: 22px;
  width: 440px;
  text-align: center;
}

.invite-team-form {
  margin-top: 35px;
}

.invite-team-buttons {
  padding: 0;
  margin-top: 28px;
}

.control-form {
  margin-top: 50px;
}

tag.active {
  background-color: #cfca7f;
  color: black;
  padding: 2px 10px;
}

tag {
  position: absolute;
  white-space: nowrap;
  background-color: #7c7c7c;
  color: var(--white-100);
  padding: 0px 5px;
  border-radius: 2px;
  font-size: 12px;
  text-transform: uppercase;
}

#controls-container {
  padding: 10px 30px;
  margin-top: 10px;
  background: none;
}

#controls-container .number {
  color: #00b1b9;
  font-size: 18px;
  font-weight: var(--fontSemiBold);
}

#controls-button2 {
  display: none;
}

#tips-button2 {
  display: none;
}

.container {
  padding-left: 0;
  padding-right: 0;
  width: inherit;
}

#back-annotations-button {
  display: none;
}

#class-prominence-container {
  display: none;
  width: 300px;
  background-color: rgb(220, 220, 220);
  position: absolute;
  z-index: 100;
  box-shadow: 10px 10px 5px rgba(40, 40, 40, 0.5);
  border-radius: 0px 5px 5px 5px;
}

#class-prominence-container.active {
  display: block;
}

#class-prominence-container .circle {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  background: #1d84b9;
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
}

#class-prominence-container .circle.active {
  background: #0b344a;
}

#class-prominence-container .prominence-circles {
  display: inline-block;
}

#class-prominence-container .prominence-label-container {
  display: inline-block;
  width: 90px;
  padding-left: 10px;
}

#class-prominence-container .class-prominence-item {
  padding-bottom: 16px;
  padding-top: 16px;
}

#class-prominence-container .prominence-label {
  display: table-cell;
  padding-bottom: 5px;
  font-weight: var(--fontBold);
}

#class-prominence-container .fake-item {
  display: none;
}

.finish-annotation-button {
  margin-left: 10px;
}

tag span {
  font-size: 10px;
}

.form-control {
  font-size: 14px;
}

::-webkit-input-placeholder {
  font-size: 14px;
  color: var(--gray-300);
}

::-moz-placeholder {
  font-size: 14px;
  color: var(--gray-300);
}

:-ms-input-placeholder {
  font-size: 14px;
  color: var(--gray-300);
}

::placeholder {
  font-size: 14px;
  color: var(--gray-300);
}

.container-md {
  max-width: 1400px;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.login-background,
.password-reset-background,
.accept-invite-background,
.account-activated-background {
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100%;
}

.signup-background {
  background-repeat: no-repeat;
  background-position: right;
  background-size: auto 100%;
  background-attachment: local;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 100vh;
}

@media screen and (max-width: 1032px) {
  .signup-form {
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 1032px) {
  .login-background {
    display: flex;
    justify-content: center;
  }
}

@media (min-width: 1032px) {
  .login-day-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_1743px_Day.webp');
  }

  .login-night-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_1743px_Night.webp');
  }

  .signup-background {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/signup/Signup_1743px.webp');
  }

  .password-reset-background,
  .accept-invite-background {
    background-image: url('../img/password-reset/cactus_qmvfbi_c_scale,w_1174.jpg');
  }

  .account-activated-background {
    background-image: url('../img/activation/cherry_zprrxp_c_scale,w_1163.jpg');
  }
}

@media (min-width: 1825px) {
  .login-day-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_2783px_Day.webp');
  }

  .login-night-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_2873px_Night.webp');
  }

  .signup-background {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/signup/Signup_2873px.webp');
  }

  .password-reset-background,
  .accept-invite-background {
    background-image: url('../img/password-reset/cactus_qmvfbi_c_scale,w_1805.jpg');
  }

  .account-activated-background {
    background-image: url('../img/activation/cherry_zprrxp_c_scale,w_1765.jpg');
  }
}

@media (min-width: 2039px) {
  .login-day-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_3567px_Day.webp');
  }

  .login-night-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_3567px_Night.webp');
  }

  .signup-background {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/signup/Signup_3567px.webp');
  }

  .password-reset-background,
  .accept-invite-background {
    background-image: url('../img/password-reset/cactus_qmvfbi_c_scale,w_2065.jpg');
  }

  .account-activated-background {
    background-image: url('../img/activation/cherry_zprrxp_c_scale,w_2028.jpg');
  }
}

@media (min-width: 2180px) {
  .login-day-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_3874px_Day.webp');
  }

  .login-night-img {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/login/Login_3874px_Night.webp');
  }

  .signup-background {
    background-image: url('https://audioofyourusers.s3-accelerate.amazonaws.com/static_assets/signup/Signup_3874px.webp');
  }

  .password-reset-background,
  .accept-invite-background {
    background-image: url('../img/password-reset/cactus_qmvfbi_c_scale,w_2180.jpg');
  }

  .account-activated-background {
    background-image: url('../img/activation/cherry_zprrxp_c_scale,w_2180.jpg');
  }
}

@media (max-width: 576px) {
  .form-login input {
    width: 100%;
  }

  .form-upload {
    width: 100%;
  }

  button.wide {
    width: 100%;
  }

  .grid-container {
    grid-template-columns: auto;
    margin-right: 250px;
    margin-left: 250px;
  }
}

@media (min-width: 960px) {
  .login-form,
  .signup-form,
  .password-reset-form,
  .accept-invite-form,
  .account-activated-info {
    margin-left: 160px;
  }
}

@media (max-width: 1440px) {
  .middle-content {
    padding-left: 72px;
    padding-right: 72px;
  }

  .sidebar-right > .sidebar-sticky > ul {
    margin-left: -14px;
  }
}

/* iPads */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .form-login input {
    width: 100%;
  }

  button.wide {
    width: 100%;
  }
}

/* iPad Pro */
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) {
  .login-form,
  .signup-form,
  .password-reset-form,
  .accept-invite-form,
  .account-activated-info {
    margin-left: auto;
  }
}

/* ===== Annotation Tool ===== */

.annotation-tool-container {
  height: 100vh;
}

.annotation-tool-header {
  padding-left: 40px;
  border-bottom: 1px solid var(--gray-100);
  height: 68px;
  display: flex;
}

.annotation-tool-body {
  height: calc(100vh - 68px);
  display: flex;
}

.video-player {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 40%;
}

.video-player > .lds-ellipsis {
  position: absolute;
  top: unset;
}

.audio-player {
  height: 96px;
}

.video-element {
  background: #000000;
  height: 100%;
  width: 100%;
  aspect-ratio: 16 / 9;
}

.audio-element {
  height: 0%;
  width: 100%;
}

.transcription {
  height: calc(60% - 16px);
  margin-top: 16px;
  padding: 0 16px;
}

.audio-transcription {
  height: calc(100% - 124px);
}

.title.audio-name {
  font-size: 14px;
  color: var(--gray-600);
  font-weight: var(--fontSemiBold);
  margin-bottom: 0;
  max-width: 660px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#wav-name-input {
  max-width: 660px;
  font-weight: var(--fontSemiBold);
  font-size: 14px;
  color: var(--gray-500);
  padding-left: 8px;
}

.back-arrow-link {
  padding: 8px;
  padding-left: 20px;
  text-decoration: none;
}

.back-arrow-image {
  padding: 8px;
  border-radius: 4px;
}

.back-arrow-image:hover {
  background-color: var(--gray-100);
}

.empty-state-video {
  width: 100%;
  max-height: 320px;
  margin-bottom: 12px;
}

.empty-projects__container {
  height: 100%;
  width: calc(100vw - 404px);
  padding-bottom: 40px;
}

.empty-projects__icon {
  height: 64px;
}

.empty-projects__subtitle {
  color: var(--gray-400);
  margin: 18px 0 30px;
}

.empty-projects__button {
  margin-top: 30px;
  height: 50px;
  width: 144px;
}

.right-side-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 28px 28px 0 28px;
  width: calc(42% - 147px);
  overflow-y: auto;
  background: #ecf0fb;
}

.empty-state-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.comment-list-container {
  flex: 1;
}

input[type='range'] {
  -webkit-appearance: none;
  height: 4px;
  width: 100%;
  background: linear-gradient(
    to right,
    #ff5678 0%,
    #ff5678 0%,
    var(--gray-200) 0%,
    var(--gray-200) 100%
  );
  transition: background 450ms ease-in;
  cursor: pointer;
  transition: height 0.1s ease-in-out;
}

input[type='range']:focus {
  border: none !important;
}

.player-controls-container {
  visibility: hidden;
  position: absolute;
  height: 44px;
  width: 100%;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
}

.player-controls.video {
  height: calc(100% - 4px);
  padding: 0 16px;
}

.player-controls__progress-bar {
  height: 4px;
  background: var(--white-100);
  display: flex;
  align-items: center;
  overflow: visible;
}

.player-controls__audio-progress-bar {
  margin-top: 4px;
  margin-bottom: 0px;
}

input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  height: 4px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ff5678;
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  width: 12px;
  border: 0;
  margin-top: -4px;
}

input[type='range']::-moz-range-thumb {
  background: #ff5678;
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  width: 12px;
  border: 0;
  margin-top: -4px;
}

input[type='range']::-moz-range-track {
  background-color: var(--white-100);
}

input[type='range']::-moz-range-progress {
  background-color: #ff5678;
  height: 12px;
}

input[type='range']::-ms-fill-upper {
  background-color: var(--white-100);
}

input[type='range']::-ms-fill-lower {
  background-color: #ff5678;
}

.audio-control-styles {
  background-color: #ecf0fb;
  height: 100%;
  padding: 15px;
}

.player-controls__btn {
  cursor: pointer;
  margin-right: 24px;
  border-radius: 4px;
}

img.video,
button.video {
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

img.video:hover,
button.video:hover {
  transform: scale(1.15);
}

.player-controls__btn:hover {
  background-color: var(--gray-100);
}

.player-controls__btn--play {
  border-radius: 50%;
}

.player-controls__btn--play.video {
  margin-right: 12px;
  cursor: pointer;
}

.player-controls__btn--pause {
  border-radius: 50%;
  display: none;
}

.player-controls__btn--pause.video {
  margin-right: 12px;
  cursor: pointer;
}

.player-controls__btn--back {
  padding: 5px;
  margin-right: 8px;
  cursor: pointer;
}

.player-controls__btn--back:hover {
  background-color: inherit;
}

.player-controls__btn--forward {
  padding: 5px;
  cursor: pointer;
}

.player-controls__btn--forward:hover {
  background-color: inherit;
}

#auto-scroll-enable {
  display: flex;
  align-items: center;
  width: 98px;
  font-size: 12px;
  color: #fb3569;
  border: 1px solid var(--gray-100);
  border-radius: 4px;
  padding: 3px 8px;
  cursor: pointer;
}

#auto-scroll-enable:hover {
  background-color: var(--gray-100);
}

#auto-scroll-enable.video {
  color: var(--white-100);
  border: none;
}

#auto-scroll-enable.video {
  background-color: transparent;
}

.player-controls__autoscroll-btn {
  height: 14px;
  width: auto;
  margin-right: 5px;
}

.player-controls__btn--speed {
  margin-right: 8px;
  font-size: 12px;
  color: #fb3569;
  border: 1px solid var(--gray-100);
}

.player-controls__btn--speed:hover {
  color: #fb3569;
}

.player-controls__btn--speed.video {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  color: var(--white-100);
  border: none;
}

.player-controls__btn--speed.video:hover {
  color: var(--white-100);
}

.player-controls__btn--speed.video:after {
  display: block;
  content: ' ';
  background-image: url('../img/down-chevron.svg');
  background-size: 8px 8px;
  height: 8px;
  width: 8px;
  background-repeat: no-repeat;
  margin-top: 2px;
  margin-left: 6px;
}

.dropdown-menu.speed-control-menu {
  min-width: 100px;
}

.dropdown-menu.speed-control-menu.video {
  background: var(--gray-600);
}

.dropdown-menu.language-control-menu {
  min-width: 100px;
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown-toggle.player-controls__btn--speed::after {
  margin-left: 12px;
}

.speed-control-menu > .dropdown-item {
  font-size: 12px;
  color: #fb3569;
}

.speed-control-menu.video > .dropdown-item {
  color: var(--white-100);
}

.speed-control-menu.video > .dropdown-item:hover,
.speed-control-menu.video > .dropdown-item:focus,
.speed-control-menu.video > .dropdown-item:active {
  background-color: #2b2b2b;
}

.language-control-menu > .dropdown-item {
  font-size: 12px;
  color: #fb3569;
}

li.dropdown-item.playback-speed {
  cursor: pointer;
  padding: 5px 18px 5px 18px;
}

.dropdown-item.playback-speed.active,
.dropdown-item.playback-speed:active {
  background-color: #ecf0fb;
}

li.dropdown-item.player-control-dropdown-item {
  cursor: pointer;
  padding: 5px 18px 5px 18px;
}

.dropdown-item.player-control-dropdown-item.active,
.dropdown-item.player-control-dropdown-item-item:active {
  background-color: #ecf0fb;
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55)
    both;
  animation: fade-in-bottom 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55) both;
}

.fade-out-bottom {
  -webkit-animation: fade-out-bottom 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045)
    both;
  animation: fade-out-bottom 0.6s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
}

.player-controls__btn--pip,
.player-controls__btn-full-screen-img {
  height: 16px;
  width: auto;
  margin-right: 16px;
  cursor: pointer;
}

.player-controls__btn-full-screen {
  background: none;
  border: none;
}

.player-controls__btn-full-screen:focus {
  outline: none;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-11-3 20:37:19
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */ /**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

/* ----------------------------------------------
 * animation fade-out-bottom
 * ----------------------------------------
 */
@-webkit-keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}
@keyframes fade-out-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
}

.player-and-transcription {
  width: 58%;
  max-height: 100%;
}

.playtime {
  font-size: 12px;
  color: var(--gray-500);
  margin-bottom: 0;
}

.waveform-container {
  height: 100%;
  padding-right: 6px;
  box-sizing: border-box;
  box-shadow: 5px 0 5px -5px #e2e7ed;
  border-right: 1px solid var(--gray-100);
  z-index: 2;
}

.waveform {
  width: 140px;
  max-width: 140px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.red-50 {
  color: var(--red-50);
}

.delete-interview {
  color: #f05454 !important;
}

.playback-speed {
  font-size: 12px;
  color: #fb3569;
}

.player-control-dropdown-item {
  font-size: 12px;
  color: #fb3569;
}

.swimlanes {
  width: 40%;
  height: 100%;
}

.tag-form--floating {
  position: absolute;
  width: calc(100% - 58px);
  z-index: 10;
}

.tag-form {
  margin-left: -15px;
  margin-right: -15px;
  max-width: 600px;
  height: auto;
  margin-bottom: 15px;
  border-radius: 8px;
  box-shadow: 1px -3px 16px 0 rgba(0, 0, 0, 0.1),
    0 4px 18px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white-100);
}

.reply-form {
  max-width: 600px;
  height: auto;
  padding: 20px;
  border-top: 1px solid var(--gray-100);
}

input.new-tag-name {
  width: 100%;
  height: 36px;
  padding: 8px 12px;
  border-radius: 4px;
  margin-left: 0;
  color: var(--gray-500);
  border: 1px solid var(--gray-100);
}

input.new-tag-name:focus {
  border: 1px solid var(--gray-100);
}

select.custom-select.category {
  height: 40px;
  padding: 8px 15px;
  font-size: 12px;
  color: var(--gray-300);
  border-radius: 0;
}

select.custom-select.category:focus {
  border: 1px solid var(--gray-500) !important;
  outline: none;
  box-shadow: none;
}

textarea.comment {
  border: 1px solid var(--gray-100);
  font-size: var(--fontSize-13);
  border-bottom: none;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  padding: 8px 12px;
  resize: none;
  margin-top: 8px;
  min-height: 79px;
}

textarea.comment:focus {
  border: 1px solid var(--gray-100);
  border-bottom: none !important;
  outline: none;
}

textarea.comment-reply {
  border-radius: 4px;
  border: solid 1px var(--gray-100);
  background-color: #fafafa;
  margin-bottom: 18px;
  height: 56px;
}

textarea.comment-reply:focus {
  background-color: var(--white-100);
  border: solid 1px var(--gray-100) !important;
}

.copy-transcript {
  padding: 4px 15px;
  margin-bottom: 0;
  border: 1px solid var(--gray-100);
  border-top: none;
  cursor: pointer;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  gap: 5px;
}

.copy-state,
.success-state {
  font-size: var(--fontSize-11);
  color: #ff5678;
  line-height: 1.7;
  font-weight: var(--fontSemiBold);
}

.success-state {
  color: #67beb7;
}

.copy-transcript-icon {
  filter: invert(43%) sepia(88%) saturate(1270%) hue-rotate(317deg)
    brightness(102%) contrast(101%);
  margin-right: 6px;
}

label.new-tag-category {
  margin-top: 25px;
}

label.note {
  margin-top: 25px;
}

.info {
  filter: invert(64%) sepia(1%) saturate(0%) hue-rotate(169deg) brightness(95%)
    contrast(92%);
  margin-left: 6px;
  margin-bottom: 2px;
}

.reply-form-buttons {
  width: 154px;
}

.reply-form-buttons > .btn.normal-grey {
  padding: 6px 14px;
  min-width: 72px;
}

.reply-form-buttons > .btn.save-reply-btn {
  padding: 6px 14px;
  min-width: 72px;
}

.tag-container {
  max-width: 600px;
  margin-bottom: 15px;
  cursor: pointer;
  background: var(--white-100);
  border-radius: 12px;
}

.tag-container:hover,
.selected-tag {
  box-shadow: 1px -3px 8px 0 rgba(0, 0, 0, 0.1), 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
}

.unselected-tag > .tag-name {
  color: var(--gray-500);
}

.tag-pill {
  height: 15px;
  min-width: 6px;
  border-radius: 8px;
  margin-top: 2px;
  margin-right: 6px;
}

.tag-name,
.tag-name * {
  font-weight: var(--fontBold);
  color: var(--gray-600);
  margin-bottom: 4px;
  font-size: 13px;
  line-height: 1.6;
  white-space: pre-wrap;
  word-break: break-word;
}

.tag-name:empty {
  margin-bottom: 0px;
}

.tag-description {
  height: auto;
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  font-size: 13px;
  line-height: 1.6;
  white-space: pre-wrap;
}

.reply-description {
  height: auto;
  margin-bottom: 0;
  font-size: 13px;
  line-height: 1.6;
  white-space: pre-wrap;
}

.hideContent {
  overflow: hidden;
  line-height: 1em;
  height: 2em;
}

.showContent {
  line-height: 1em;
  height: auto;
}

.show-more,
.delete-question,
.save-question,
.cancel-question {
  font-size: 12px;
  color: #fb3569;
  cursor: pointer;
}

.delete-question {
  display: inline-block;
  font-size: 14px;
  margin-left: 20px;
  color: var(--gray-300);
}

.save-question {
  font-size: 14px;
  margin-right: 20px;
}

.cancel-question {
  font-size: 14px;
  color: var(--gray-300);
}

.save-question:hover {
  color: #f9005e;
}

.cancel-question:hover,
.delete-question:hover {
  color: var(--gray-400);
}

.separator {
  color: inherit;
  margin: 0 4px;
}

.tag-duration {
  display: none;
}

.tag-info,
.reply-info {
  position: relative;
  height: 32px;
  margin-top: 6px;
}

.highlight-span {
  font-weight: var(--fontSemiBold);
  font-size: 12px;
  margin-bottom: 0;
}

.tag-buttons__button,
.reply-buttons__button {
  height: 32px;
  width: 32px;
  padding: 8px;
  border-radius: 8px;
}
.copied-success {
  display: inline;
  color: var(--gray-400);
  cursor: default;
}

.tag-buttons__button--link:hover,
.reply-buttons__button--link:hover {
  background: #dbf6e9;
  fill: red;
}

.tag-buttons__button--copy-transcript:hover {
  background-color: var(--blue-50);
}

.tag-buttons__button--delete:hover,
.reply-buttons__button--delete:hover {
  background-color: #ffeeee;
}

.tag-buttons__button--edit:hover,
.reply-buttons__button--edit:hover {
  background-color: #e4f9ff;
}

.tag-buttons__button--reply:hover,
.reply-buttons__button--reply:hover {
  background-color: #f8f9dd;
}

.new-tag-name::-webkit-input-placeholder,
.comment::-webkit-input-placeholder {
  font-size: 12px;
  color: var(--gray-300);
}
.new-tag-name::-moz-placeholder,
.comment::-moz-placeholder {
  font-size: 12px;
  color: var(--gray-300);
}
.new-tag-name:-ms-input-placeholder,
.comment:-ms-input-placeholder {
  font-size: 12px;
  color: var(--gray-300);
}
.new-tag-name::placeholder,
.comment::placeholder {
  font-size: 12px;
  color: var(--gray-300);
}

#waveform > wave > wave {
  margin: auto;
}

#v-container {
  position: fixed;
  top: 168px;
  left: 364px;
  height: 100%;
  width: 40%;
}

@media (min-width: 1440px) {
  #v-container {
    top: 168px;
    left: 289px;
  }
}

@media (min-width: 1920px) {
  #v-container {
    top: 168px;
    left: 310px;
    /* left: 364px; */
  }
}

@media (min-width: 2560px) {
  #v-container {
    top: 168px;
    left: 464px;
  }
}

/* ===== Tag Suggestions ===== */

.autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 12px;
}

.autocomplete-notepad {
  margin-bottom: 0;
}

.autocomplete-items,
.autocomplete-labels,
.autocomplete-questions,
.autocomplete-questions__tag-edit {
  position: absolute;
  z-index: 99;
  top: 100%;
  left: 1px;
  right: 1px;
  max-height: 230px;
  overflow-y: auto;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
}

.autocomplete-questions__tag-edit,
.autocomplete-labels {
  position: absolute;
  top: 10px;
  border-radius: 8px;
  margin-top: 16px;
}

.loading-spinner {
  animation: spin-animation 1s linear infinite;
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.autocomplete-questions {
  top: 60%;
  border-radius: 8px;
  margin-top: 8px;
}

.autocomplete-labels {
  width: 200px;
}

.autocomplete-items div,
.autocomplete-questions-text,
.autocomplete-labels-text {
  font-size: 12px;
  padding: 8px 10px;
  cursor: pointer;
  background-color: #fff;
}

.autocomplete-questions-text,
.autocomplete-labels-text {
  font-size: 14px;
  background-color: transparent;
  padding: 0;
}

.autocomplete-questions-div,
.autocomplete-labels-div {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--gray-100);
  padding: 12px 10px;
  cursor: pointer;
}

.autocomplete-questions-div {
  background-color: var(--white-100);
}

.autocomplete-items div:hover,
.autocomplete-questions-div:hover,
.autocomplete-labels-div:hover {
  /*when hovering an item:*/
  background-color: #f9f9f9;
}

.autocomplete-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: #f9f9f9 !important;
}

.is-question-div {
  border-radius: 0 0 4px 4px;
  height: 32px;
  background-color: #fbfbfb;
  border: 1px solid var(--gray-100);
  border-top: none;
  padding: 0 12px;
}

.match-color {
  display: inline;
  font-size: 12px;
  color: #ff5678;
}

.question-match,
.label-match {
  font-size: 14px;
  margin-bottom: 0px;
}

.slash-span::before {
  content: '/';
}

.slash-span,
.hash-span {
  background-color: #f4f4f4;
  color: #ff5678;
  border-radius: 4px;
  padding: 0px 8px;
  margin: -2px 12px -2px 0;
}

.hash-span::before {
  content: '#';
}

/** ===== Import Zoom Recording ===== */

.import-meeting,
.import-meeting:focus {
  font-weight: var(--fontSemiBold);
  color: #ff5678;
  background-color: transparent;
  border: none !important;
  padding: 0;
}

.bootstrap-select:not([class*='col-']):not([class*='form-control']):not(
    .input-group-btn
  ) {
  width: 160px !important;
}

.btn.dropdown-toggle.btn-white.bs-placeholder:focus,
.btn.dropdown-toggle.btn-white.bs-placeholder:active {
  outline: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--gray-500);
  background-color: #f8f9fa;
  outline: none;
}

.update-language-btn-container {
  margin-right: 8px;
  width: 40px;
}

.language-icon {
  padding: 12px;
}

.translate-btn-container {
  margin-right: 8px;
  width: 40px;
}

.download-transcript-btn-container {
  margin-right: 8px;
  width: 40px;
}

.metadata-btn-container {
  margin-right: 8px;
  width: 40px;
}

.pii-btn-container {
  margin-right: 8px;
  width: 40px;
}

.edit-demographics-btn-container {
  margin-right: 8px;
  width: 40px;
}

.share-video-btn-container {
  margin-right: 8px;
  width: 40px;
}

.copy-playlist-btn-container {
  margin-right: 8px;
  width: 40px;
}

.mark-annotated-btn-container {
  margin-right: 14px;
  width: 40px;
}

.copy-notes-btn-container {
  margin-right: 8px;
  width: 40px;
}

.language-selection-btn {
  width: auto;
  height: 40px;
  flex-grow: 0;
  padding: 6px 0px;
  border-radius: 12px;
  border: solid 1px var(--gray-100);
  background-color: var(--white-100);
  margin-right: 24px;
}

.language-selection-btn:hover {
  background-color: var(--gray-50);
}

.language-dropdown-btn {
  padding-top: 8px;
  width: auto;
  height: 24px;
  margin-right: 5px;
  font-size: 12px;
  text-align: center;
  color: var(--gray-500);
}

.language-dropdown-toggle::after {
  color: #c4c4c4;
  margin: 12px 12px 12px 0px;
}

.update-language-btn-container.disabled button,
.share-video-btn-container.disabled p,
.translate-btn-container.disabled p,
.copy-notes-btn-container.disabled p,
.copy-playlist-btn-container.disabled p {
  color: var(--gray-200);
}

.update-language-btn-container button,
.share-video-btn-container p,
.translate-btn-container p,
.copy-notes-btn-container p,
.metadata-btn-container p,
.copy-playlist-btn-container p,
.edit-demographics-btn-container p,
.pii-btn-container p {
  font-size: 10px;
  margin: 0;
  padding: 0;
  color: var(--gray-400);
  line-height: 2.4;
  margin-top: -4px;
  border: none;
  width: 100%;
  text-align: center;
}

.update-language-btn-container .dropdown {
  height: 20px;
}

.mark-annotated-btn {
  align-items: center;
  justify-content: center;
}

.update-language-btn,
.share-video-btn,
.translate-transcript-btn,
.metadata-create-btn,
.mark-annotated-btn,
.download-transcript-btn,
.copy-notes-btn,
.download-video-btn {
  border: 0;
  width: 36px;
  height: 36px;
  background-color: white;
  border-radius: 4px;
}

.update-language-btn:focus,
.share-video-btn:focus,
.translate-transcript-btn:focus,
.download-transcript-btn:focus,
.metadata-create-btn:focus,
.mark-annotated-btn:focus,
.copy-notes-btn:focus,
.download-video-btn:focus {
  outline: none;
}

.update-language-btn:hover,
.share-video-btn:hover,
.translate-transcript-btn:hover,
.metadata-create-btn:hover,
.mark-annotated-btn:hover,
.download-transcript-btn:hover,
.copy-notes-btn:hover,
.download-video-btn:hover {
  background-color: var(--gray-50);
}

.update-language-btn img {
  height: 16px;
  width: auto;
  margin-top: 4px;
}

.share-video-btn img,
.translate-transcript-btn img,
.metadata-btn-container img,
.mark-annotated-btn img,
.copy-notes-btn img {
  height: 18px;
  width: 18px;
}

.edit-demographics-btn-container img {
  height: 22px;
  width: 23px;
}

.download-video-btn img {
  height: 22px;
  width: 22px;
}

/** ===== Processing File Modal ===== */
.bg-primaryColor {
  background-color: #ff5678;
}

/** ===== Loading Spinner CSS ===== */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  top: -40%;
}

.lds-ellipsis div {
  position: absolute;
  /* top: -100%; */
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: var(--white-100);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis.lds-ellipsis-pink {
  position: absolute;
  left: calc(50vw + 83px);
  top: calc(50vh + 20px);
}

.lds-ellipsis.lds-ellipsis-pink.lds-ellipsis-full-screen {
  left: 50vw;
}

.lds-ellipsis.lds-ellipsis-pink > div {
  background: #fb3569;
}

.lds-ellipsis.lds-ellipsis-upload {
  top: 30vh;
  margin: 0 auto;
}

.lds-ellipsis.lds-ellipsis-upload > div {
  background: #fb3569;
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

/** ===== Delete Tag Alert ===== */
.slide-top {
  margin-bottom: 0px;
  -webkit-animation: slide-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) both;
  animation: slide-top 0.4s cubic-bezier(0.075, 0.82, 0.165, 1) both;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
  }
}

/** ===== About Page ===== */
.about-page-container {
  height: calc(100vh - 4.75rem);
  margin-left: 0;
}

.about-header {
  margin-bottom: 36px;
  margin-left: 9px;
}

h5.title.project-title {
  font-weight: var(--fontSemiBold);
  font-size: 16px;
  width: 55%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 32px;
  padding-top: 10px;
  line-height: 19px;
  color: var(--gray-600);
}

.subcontent-header {
  margin-bottom: 10px;
}

.subcontent-text {
  margin-left: 36px;
  margin-top: 0;
  white-space: pre-line;
}

.subcontent-text,
.about-form-textarea {
  font-size: 15px;
  line-height: 1.8;
}

.about-form-label {
  font-size: 14px;
  text-transform: capitalize;
  color: var(--gray-400);
  margin-left: 36px;
}

.about-form-textarea {
  display: block;
  width: calc(100% - 36px);
  margin-left: 36px;
  border: 0;
  resize: none;
  overflow: hidden;
  height: auto;
  min-height: 60px;
}

.about-form-textarea:focus {
  border: 0;
  outline: none;
}

.about-form-textarea::placeholder {
  color: #bbbbbb;
}

.delete-modal {
  height: 100vh;
}

#research-textarea {
  min-height: 82px;
}

#users-textarea {
  min-height: 122px;
}

#save-about-info {
  min-width: 132px;
}

.upload-page-container {
  height: auto;
  margin-top: 68px;
}

/** ===== Questions Page ===== */
.questions-page-container {
  height: 100%;
}

.questions-page-container .guides-container {
  padding-right: 0;
}

.questions-page-container .guides-container .row {
  margin-right: -36px;
}

.questions-notepad-container {
  margin-top: 38px;
  margin-left: 62px;
}

#questions-notepad-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

/** ===== End: Questions Page ===== */

/** ===== Start: Onboarding Page ===== */

.onboarding-page-container {
  margin-top: -80px;
  padding-top: 88px;
  background-color: #ecf0fb;
}

.onboarding-body {
  background-color: #ecf0fb;
}
/** ===== End: Onboarding Page ===== */

/** ===== Insights List Page ===== */
.insights-container {
  padding-left: 40px;
  padding-right: 40px;
}

.insights-list-container {
  height: 100%;
}
/** ===== End: Insights List Page ===== */

.notepad__questions-description-text {
  font-weight: var(--fontSemiBold);
  font-size: 13px;
  color: #453d81;
  margin-left: 8px;
  margin-bottom: 24px;
}

.modal-header {
  border-bottom: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 24px;
  margin-right: 12px;
  margin-bottom: 6px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--gray-200);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 2px 4px 4px 0 rgba(0, 0, 0, 0.15);
}

input:checked + .slider {
  background-color: #fb3569;
}

input:focus + .slider {
  box-shadow: none;
}

input:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

a.avatar-tooltip {
  text-decoration: none;
  cursor: pointer;
  position: relative;
}

a.avatar-tooltip span {
  display: none;
}

a.avatar-tooltip div {
  display: none;
}

a.avatar-tooltip:hover div {
  display: block;
}

a.avatar-tooltip:hover span {
  max-width: 200px;
  text-align: center;
  padding: 2px 6px;
  font-size: 12px;
  display: block;
  z-index: 100;
  left: -18px;
  margin: 2px;
  position: absolute;
  top: 36px;
  text-decoration: none;
  color: white;
  background: black;
  border-radius: 4px;
}

/** ===== Search ===== */
.home__and__search {
  margin-bottom: 36px;
}

.btn_and_search {
  height: 40px;
}

.has-search .form-control {
  font-size: 14px;
  padding-left: 2.075rem;
  height: 40px;
  width: 328px;
  background-color: #ecf0fb;
  border-radius: 24px;
  border: none;
}

.form-control::-moz-placeholder {
  color: var(--gray-400);
  font-size: 13px;
}
.form-control::placeholder {
  color: var(--gray-400);
  font-size: 13px;
}
.form-control::-webkit-input-placeholder {
  color: var(--gray-400);
  font-size: 13px;
}

.has-search.tag-form__search-labels-container > .form-control {
  width: 100%;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.45rem;
  text-align: center;
  pointer-events: none;
  color: var(--gray-300);
  -webkit-text-stroke: 1px var(--gray-background);
}

.search__small-input:focus,
.search__large-input:focus,
.tag-form__search-labels-input:focus {
  background-color: #ecf0fb;
}

.comment-container {
  padding: 16px;
}

.reply-selection {
  border-top: var(--gray-100) 1px solid;
  padding: 16px;
}

.low-confidence-subtitle {
  color: var(--gray-300);
  text-decoration-line: underline;
  text-decoration-style: dotted;
  text-decoration-color: #a7c5eb;
}

div.section-separator {
  font-weight: var(--fontNormal);
  font-size: 40px;
  letter-spacing: 0.2em;
  color: var(--gray-500);
  margin: 52px 0 52px 0;
  text-align: center;
}

[contentEditable='true']:empty::before {
  content: attr(data-placeholder);
  color: var(--gray-300);
}

[contenteditable]:focus {
  outline: none;
}

.alert-warning-message {
  color: white;
}

.uploaded-file-container {
  padding: 28px 32px 29px 28px;
  border-radius: 8px;
  border: solid 1px var(--gray-100);
  margin: 16px 0;
}

.uploaded-file-container .progress,
.uploaded-file-container .progress .bar {
  border-radius: 9999px;
}

.uploaded-file-container .file-link {
  font-size: 12px;
  color: var(--gray-500);
  margin-bottom: 7px;
}

.cancel-file-upload {
  margin-left: auto;
  margin-right: 16px;
}

.remove-file-btn {
  width: 12px;
  height: 12px;
  border: solid 1px var(--gray-300);
  background-color: var(--white-100);
  cursor: pointer;
  border-radius: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-file-btn img {
  height: 4px;
}

.verify-transcript {
  margin-left: auto;
  margin-right: 40px;
}

.projects-container .pagination {
  margin-left: auto;
}

.projects-container .page-item.active .page-link {
  background-color: #ffdde4;
  border-color: #ffdde4;
  color: unset;
}

.projects-container .page-link:focus {
  box-shadow: none;
}

.team-dropdown {
  max-height: 300px;
  overflow: auto;
}

.file-uploaded-title {
  font-weight: var(--fontSemiBold);
  font-size: 14px;
  color: var(--gray-500);
  margin-bottom: 5px;
  margin-top: 4px;
}

.file-uploaded-subtitle {
  font-weight: var(--fontNormal);
  font-size: 14px;
  color: var(--gray-500);
  margin-bottom: 12px;
}

.processing-container {
  background-color: #fafafa;
}

.transcript_status_verified {
  color: #21c59f;
}

.delete-warning-text {
  color: #dc545c;
}

.empty-value-item {
  background-color: #f0f0f0;
}

.close-modal-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  padding: 8px;
  position: absolute;
  right: 60px;
  top: 40px;
  cursor: pointer;
  z-index: 1;
}

.close-modal-button:hover {
  background-color: var(--gray-100);
}

.custom-alert-width {
  width: 420px;
}

.beta-icon {
  margin-left: 6px;
}

.tippy-content > div,
.tippy-content > div > div {
  color: var(--white-100);
  font-size: var(--fontSize-12);
}

strong {
  font-weight: var(--fontBold);
  font-size: inherit;
  line-height: inherit;
}

em {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

u {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}

.copy-state:focus-visible {
  outline: 2px solid var(--pink-300);
}

.modal-dialog {
  vertical-align: middle;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  min-height: calc(100% - 1rem);
}

@media (min-width: 576px) {
  .modal-dialog {
    margin: auto;
  }

  .modal-dialog:not(.fullscreen-modal-dialog):not(.modal-fullscreen)
    .modal-content {
    margin: 1.75rem auto;
  }
}

.gray-500 {
  color: var(--gray-500);
}

.p-relative {
  position: relative;
}

.new-tag-name::placeholder {
  font-weight: var(--fontNormal);
}

/* start: tippyjs styles */

.tippy-box[data-theme~='transparent'] {
  background-color: transparent !important;
  padding-bottom: 6px;
}

.tippy-box {
  background-color: var(--blue-900) !important;
  border-radius: 10px;
}

.tippy-box[data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--blue-900) !important;
}
.tippy-box[data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--blue-900) !important;
}
.tippy-box[data-placement^='left'] > .tippy-arrow::before {
  border-left-color: var(--blue-900) !important;
}
.tippy-box[data-placement^='right'] > .tippy-arrow::before {
  border-right-color: var(--blue-900) !important;
}

.tippy-box[data-theme~='light'] {
  background-color: var(--white-100) !important;
}

.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--white-100) !important;
}
.tippy-box[data-theme~='light'][data-placement^='bottom']
  > .tippy-arrow::before {
  border-bottom-color: var(--white-100) !important;
}
.tippy-box[data-theme~='light'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: var(--white-100) !important;
}
.tippy-box[data-theme~='light'][data-placement^='right']
  > .tippy-arrow::before {
  border-right-color: var(--white-100) !important;
}

.small-font .tippy-content > div,
.small-font .tippy-content > div > div {
  font-size: 12px;
}

.tippy-box[data-theme~='light'] > .tippy-content > div,
.tippy-box[data-theme~='light'] > .tippy-content > div > div {
  color: var(--black);
  font-size: var(--fontSize-12);
}
/* end: tippyjs styles */

/* TODO: Can be removed */

.modal-body.new-project-body {
  /* account for the footer height */
  height: calc(100vh - 88px);
  position: relative;
  scrollbar-gutter: stable;
}

.circle-progress {
  width: 28px;
  height: 28px;
}
.circle-progress-container {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle-progress-container__background {
  fill: none;
  stroke: var(--pink-500);
  stroke-width: 5px;
}

.circle-container__progress {
  fill: none;
  stroke-linecap: unset;
  stroke: var(--gray-100);
  stroke-dasharray: 100 101;
  stroke-width: 6px;
  transition: stroke-dashoffset 1s ease-in-out;
  will-change: transform;
}

.btn-gray {
  font-weight: var(--fontSemiBold);
  color: var(--gray-500);
}

.btn-gray:hover {
  border-color: var(--gray-500);
  background-color: var(--gray-50);
}

.move-interview-modal-body.modal-body {
  height: 100%;
}

.access-control-locked {
  box-sizing: content-box;
  position: relative;
}

.access-control-locked::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: not-allowed;
}

.access-control-disabled {
  position: relative;
}

.access-control-disabled::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: not-allowed;
}

.access-control-disabled > *,
.access-control-locked > * {
  opacity: 0.5 !important;
}

.disabled-locked-menu-item {
  cursor: not-allowed !important;
}

.disabled-locked-menu-item:hover {
  color: var(--gray-400) !important;
}

.disabled-locked-menu-item > * {
  opacity: 0.5 !important;
  pointer-events: none;
}

.menu-lock-icon,
.access-control-lock {
  opacity: 1 !important;
}

.access-control-tooltip {
  color: var(--white-100);
  font-size: var(--fontSize-12);
  line-height: 20px;
  text-align: left;
  margin-bottom: 0;
}

.labels-highlighted-words {
  font-size: var(--fontSize-13);
  font-weight: var(--fontSemiBold);
  color: var(--pink-500);
  padding: 0;
  background: none;
}

.labels-non-highlighted-words {
  font-size: var(--fontSize-13);
  font-weight: var(--fontSemiBold);
  color: var(--gray-600);
  padding: 0;
  background: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* add scrollbar styles only for firefox */
.firefox-sidenav-scrollbar {
  scrollbar-color: var(--gray-300) transparent;
  scrollbar-width: thin;
}

.sidenav-scrollbar::-webkit-scrollbar {
  background: transparent;
  width: 6px;
} 

.sidenav-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 9999px;
  background-color: var(--gray-300);
}

.hide-sidenav-scrollbar::-webkit-scrollbar {
  display: none;
}

.team-members-highlighted-name {
  font-size: var(--fontSize-14);
  font-weight: var(--fontSemiBold);
  color: var(--pink-500);
  padding: 0;
  background: none;
}

.team-members-non-highlighted-name {
  width: 100%;
  font-weight: var(--fontSemiBold);
  color: var(--gray-600);
  margin-bottom: -3px;
}

.team-members-highlighted-email {
  font-size: var(--fontSize-12);
  color: var(--pink-500);
  padding: 0;
  background: none;
}

.team-members-non-highlighted-email {
  width: 100%;
  font-size: var(--fontSize-12);
  color: var(--gray-500);
  margin-top: 2px;
  margin-bottom: 0;
}

.strength-container {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  color: var(--gray-600);
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 20px;
  max-width: 320px;
}

.each-strength {
  margin-right: 10px;
  font-size: 11px;
}

.each-strength.min-length {
  margin-left: 6px;
}

.each-strength img {
  margin-right: 5px;
}

.show-password-btn {
  position: absolute;
  background: transparent;
  border: none;
  outline: none;
  font-size: 12px;
  color: var(--pink-500);
  top: 12px;
  left: 270px;
  font-weight: var(--fontSemiBold);
}

.show-password-btn:focus {
  outline: none;
}

@-webkit-keyframes scale-from-bottom {
  from {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }

  to {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    opacity: 1;
  }  
}

@keyframes scale-from-bottom {
  from {
    transform: scale(0);
    transform-origin: 50% 100%;
    opacity: 1;
  }

  to {
    transform: scale(1);
    transform-origin: 50% 100%;
    opacity: 1;
  }  
}


.tippy-box[data-animation='scale-from-bottom'][data-state='visible'] {
  animation: scale-from-bottom 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.custom-scroll::-webkit-scrollbar {
  width: 6px; /* Width of the scrollbar */
}

.custom-scroll::-webkit-scrollbar-track {
  background: transparent;  /* Background of the scrollbar track #ecf0fb88 */
  border-radius: 5px; /* Rounded corners for track */
}

.custom-scroll::-webkit-scrollbar-thumb {
  background: var(--gray-300); /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for thumb */
}

.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--gray-400); /* Color on hover */
}

.rough-note-modal{
  height: 100%;
}

