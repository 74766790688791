.tag-modal {
  height: 100%;
  overflow: auto;
  background-color: var(--gray-background);
}

.tag-modal .lds-ellipsis.lds-ellipsis-pink {
  left: 50vw;
}

.insights-page-container {
  background-color: var(--gray-background);
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.insights-page-container .lds-ellipsis.lds-ellipsis-pink {
  left: calc(50vw - 40px);
}

.banner-image {
  width: 100%;
  height: 196px;
  background-color: var(--gray-background);
}
.banner-image img {
  width: 100%;
  height: 196px;
  object-fit: cover;
}

.insert-playlist-modal {
  min-height: unset;
  height: 100%;
}
