.modal-dark-bg {
  background-color: var(--gray-background);
}

.modal-light-bg {
  background-color: var(--white-100);
}

.modal-transparent {
  background-color: transparent;
}
